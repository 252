import React from 'react'
import { URL_CTRL_HIDRIC } from '../../router/Url';

export const GetLastDataPE = ({DATA}) => {
    // console.log(DATA)
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_HIDRIC+'our_lecturas_actual_pe_ariztia',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                DATA
            })
        })
        let responsito = await query.json();
        // console.log(responsito)

        if(responsito.success){
            resolve(responsito.body)
        }else{
            resolve([])
        }
    })
}
