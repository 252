import React from 'react'
import { URL_CTRL_HIDRIC } from '../../router/Url';
//obtain_surnorte_anteriorday
export const ObtenerSumaPS_PN_DA = () => {
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_HIDRIC+'obtain_surnorte_anteriorday',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        //   body: JSON.stringify({
        //     PLANTA, AREA
        //   })
        })
        let responsito = await query.json();
        // console.log(responsito)
        if(responsito.success){
          resolve(responsito)
        }else{
          resolve(null)
        }
    })
}
