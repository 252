import { URL_CTRL_HIDRIC } from '../router/Url';

export const QuitarSuscripcion = ({correo}) => {
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_HIDRIC+'quitsuscripcion',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            correo
          })
        })
        let responsito = await query.json();
        // console.log(responsito)
        if(responsito){
          resolve(responsito)
        }else{
          resolve([])
        }
      })
}
