import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const GetDataFActureToChart = ({EMPRESA}) => {
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_ARIZTIA+'getdatafacturatochart',{
      method: 'POST',
      headers: {
        'authorization': "paico2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        EMPRESA
      })
    })
    let responsito = await query.json();
    // console.log(responsito)
    if(responsito.success){
      resolve(responsito.body)
    }else{
      resolve([])
    }
  })
}
