import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import GaugeOne from '../../components/charts/GaugeOne.chart'
import { LineOne } from '../../components/charts/LineOne.chart'
import LoginContext from '../../context/login_context/LoginContext';
import { ListMedidores } from '../../API/ListMedidores.api';
import './index.css'

//icons
import { BsArrowsFullscreen } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import { AddChartGauge } from '../../components/AddChartGauge';
import { AddChartLine } from '../../components/AddChartLine';
import { Navigate, useNavigate } from 'react-router-dom';
import { SsGgMonitorFijo } from './SsGgMonitorFijo.page';
import { DatosCaldera } from './DatosCaldera.page';
import { RilesView } from './RilesView.page';

function Main() {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  // console.log(LoginState)
  const [MediList, setMediList] = useState([]);
  const [MediList_bysec, setMediList_bysec] = useState([]);
  const [colF1, setColF1] = useState(3);
  const [cantColF1, setCantColF1] = useState([]);
  const [colF2, setColF2] = useState(3);
  const [cantColF2, setCantColF2] = useState([]);

  const [IsMonitorFijo, setIsMonitorFijo] = useState(false);

  const [SelectMed, setSelectMed] = useState({});
  const [SettingsMenu, setSettingsMenu] = useState(true);

  const navigate = useNavigate();

  const launchFullScreen = async (id) => {
    let container = document.getElementById(id)
    // console.log("full screen")
    if(container.requestFullScreen) {
      container.requestFullScreen();
    } else if(container.mozRequestFullScreen) {
      container.mozRequestFullScreen();
    } else if(container.webkitRequestFullScreen) {
      container.webkitRequestFullScreen();
    }
  }

  const GetListOfMedidoresNespra = async () => {
    let mediList = await ListMedidores({empresa:LoginState.planta})
    setMediList(mediList);
  }

  const ObtainDataOfSessionStorage = async () => {
    let med_u = ["UD_1","UD_2","UD_3","UD_4","UD_5","UD_6"]
    let med_d = ["DD_1","DD_2","DD_3"]
    let arr = [];
    let arr_ = [];
    for (let mm = 0; mm < med_u.length; mm++) {
      const mm_ = med_u[mm];
      let a = localStorage.getItem(mm_)
      let itm = JSON.parse(a)
      if(itm){
        // console.log(itm)
        arr.push({
          "ID": itm.DATA._id,
          "CHART": itm.CHART,
          "DATA": itm.DATA
        })
      }
    }

    for (let mm = 0; mm < med_d.length; mm++) {
      const mm_ = med_d[mm];
      let a = localStorage.getItem(mm_)
      let itm = JSON.parse(a)
      if(itm){
        // console.log(itm)
        arr_.push({
          "ID": itm.DATA._id,
          "CHART": itm.CHART,
          "DATA": itm.DATA
        })
      }
    }
    setCantColF1(arr)
    setCantColF2(arr_)
  }

  const CloseMenuSettings = async (close) => {
    // console.log("closedmenu")
    setSettingsMenu(close)
    ObtainDataOfSessionStorage()
  }
  
  const isMonitorFijoFunction = async () => {
    
    let FAENA_1 = [
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO":"E-SMART",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "PANTALON NORTE",
          "FUENTE": "PANTALON NORTE",
          "MAX": 720,
          "MEDIO": 576,
          "MODULO": "-",
          "N_MED": 0,
          "SENSOR": "m1",
          "TIPO": "FLUJO INSTANTANEO",
          "UNIDAD": "M3/H",
          "URL": "https://apimedidores.apidev.info/ctrl-hidrico/getdatamedidor",
          "_id": "64d6859ded5d95fbb280dd0f"},
        "N_ID_U":1
      },
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO": "NESPRA",
          "EMPRESA": "AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "BANCOS DE AGUA",
          "ELEMENTO":"HIDRICO",
          "FUENTE": "f8-dc-7a-57-e1-94",
          "MAX": 1000,
          "MEDIO": 800,
          "MODULO": "RTU",
          "N_MED": 32,
          "SENSOR": "m1",
          "TIPO": "FLUJO INSTANTANEO",
          "UNIDAD": "L/M",
          "URL": "https://data.nescloud.net/sensordata",
          "_id": "64d4e558ed5d95fbb280d323"},
        "N_ID_U":2
      },
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO":"NESPRA",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "MENUDENCIAS",
          "ELEMENTO":"HIDRICO",
          "FUENTE": "f8-dc-7a-57-e1-94",
          "MAX": 25,
          "MEDIO": 20,
          "MODULO": "RTU",
          "N_MED": 31,
          "SENSOR": "m1",
          "TIPO":"FLUJO INSTANTANEO",
          "UNIDAD":"M3/H",
          "URL":"https://data.nescloud.net/sensordata",
          "_id":"64d4e558ed5d95fbb280d31f"},
        "N_ID_U":3
      },
    ]
    let FAENA_2 = [
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO":"NESPRA",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR":"EVISCERADO PAVO",
          "ELEMENTO":"HIDRICO",
          "FUENTE": "f8-dc-7a-57-e1-94",
          "MAX": 50,
          "MEDIO": 40,
          "MODULO": "RTU",
          "N_MED": 34,
          "SENSOR": "m1",
          "TIPO":"FLUJO INSTANTANEO",
          "UNIDAD":"M3/H",
          "URL":"https://data.nescloud.net/sensordata",
          "_id":"64d4e558ed5d95fbb280d322"},
        "N_ID_U":4
      },
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO": "NESPRA",
          "EMPRESA": "AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "MATADERO PAVO",
          "ELEMENTO":"HIDRICO",
          "FUENTE": "f8-dc-7a-57-e1-94",
          "MAX": 60,
          "MEDIO": 48,
          "MODULO": "RTU",
          "N_MED": 30,
          "SENSOR": "m1",
          "TIPO":"FLUJO INSTANTANEO",
          "UNIDAD":"M3/H",
          "URL":"https://data.nescloud.net/sensordata",
          "_id":"64d4e558ed5d95fbb280d31d"},
        "N_ID_U":5
      },
    ]
    

    let SSGG_1 = [
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO":"E-SMART",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "PANTALON NORTE",
          "FUENTE": "PANTALON NORTE",
          "MAX": 720,
          "MEDIO": 576,
          "MODULO": "-",
          "N_MED": 0,
          "SENSOR": "m1",
          "TIPO": "FLUJO INSTANTANEO",
          "UNIDAD": "M3/H",
          "URL": "https://apimedidores.apidev.info/ctrl-hidrico/getdatamedidor",
          "_id": "64d6859ded5d95fbb280dd0f"},
        "N_ID_U":1
      },
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO":"E-SMART",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "PANTALON SUR",
          "FUENTE": "PANTALON SUR",
          "MAX": 216,
          "MEDIO": 172,
          "MODULO": "-",
          "N_MED": 0,
          "SENSOR": "m1",
          "TIPO": "FLUJO INSTANTANEO",
          "UNIDAD": "M3/H",
          "URL": "https://apimedidores.apidev.info/ctrl-hidrico/getdatamedidor",
          "_id": "64d6859ded5d95fbb280dd10"},
        "N_ID_U":2
      },
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO":"NESPRA",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "ANILLO PRESION",
          "FUENTE": "f8-dc-7a-57-e1-94",
          "ELEMENTO":"HIDRICO",
          "MAX": 80,
          "MEDIO": 64,
          "MODULO": "RTU",
          "N_MED": 36,
          "SENSOR": "m1",
          "TIPO": "FLUJO INSTANTANEO",
          "UNIDAD": "M3/H",
          "URL": "https://data.nescloud.net/sensordata",
          "_id": "64d4e558ed5d95fbb280d320"},
        "N_ID_U":3
      },
      
    ]
    let SSGG_2 = [
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO": "NESPRA",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "APV",
          "ELEMENTO":"HIDRICO",
          "FUENTE": "f8-dc-7a-57-e1-94",
          "MAX": 60,
          "MEDIO": 48,
          "MODULO": "RTU",
          "N_MED": 35,
          "SENSOR": "m1",
          "TIPO": "FLUJO INSTANTANEO",
          "UNIDAD": "M3/H",
          "URL": "https://data.nescloud.net/sensordata",
          "_id": "64d4e558ed5d95fbb280d31e"},
        "N_ID_U":4
      },
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO": "NESPRA",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "BANDEJAS",
          "ELEMENTO":"HIDRICO",
          "FUENTE": "f8-dc-7a-57-e1-94",
          "MAX": 50,
          "MEDIO": 40,
          "MODULO": "RTU",
          "N_MED": 39,
          "SENSOR": "m1",
          "TIPO": "FLUJO INSTANTANEO",
          "UNIDAD": "M3/H",
          "URL": "https://data.nescloud.net/sensordata",
          "_id": "64d4e558ed5d95fbb280d321"},
        "N_ID_U":5
      },
      {
        CHART:"gauge",
        DATA: {
          "CONTRATO": "NESPRA",
          "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
          "MEDIDOR": "BANCOS DE AGUA",
          "ELEMENTO":"HIDRICO",
          "FUENTE": "f8-dc-7a-57-e1-94",
          "MAX": 1000,
          "MEDIO": 800,
          "MODULO": "RTU",
          "N_MED": 32,
          "SENSOR": "m1",
          "TIPO": "FLUJO INSTANTANEO",
          "UNIDAD": "L/M",
          "URL": "https://data.nescloud.net/sensordata",
          "_id": "64d4e558ed5d95fbb280d323"},
        "N_ID_U":6
      },
      
    ]

    if(LoginState.area === "SSGG-MONIT"){
      // console.log("SSGG-MONIT",LoginState.area);
      await setCantColF1(SSGG_1)
      // console.log()
      await setCantColF2(SSGG_2)
      await setIsMonitorFijo(true)
    }
    if(LoginState.area === "FAENA-MONIT"){
      console.log("FAENA-MONIT",LoginState.area);
      await setCantColF1(FAENA_1)
      await setCantColF2(FAENA_2)
      await setIsMonitorFijo(true)
    }
  }
  
  useEffect(() => {
    GetListOfMedidoresNespra()
    ObtainDataOfSessionStorage()
    isMonitorFijoFunction()
    if(LoginState.area.includes('IQF')){
      // console.log('Viaja a control temperatura');
      navigate('/ctrl_temp')
    }
    return () => {
      isMonitorFijoFunction()
      GetListOfMedidoresNespra()
      ObtainDataOfSessionStorage()
    }
  }, [])
 
  // if(LoginState.rol_ === "kiosko"){
  //   return <SsGgMonitorFijo />
  // }else{
  //   return (
  //     <>
  //       {
  //         LoginState.rol_ === "CALDERA"? <DatosCaldera /> :<HeaderMenu Pag_Sel={'dashboard'} IsMonitorFijo={IsMonitorFijo} />
  //       }
  //     </>
  //   )
  // }

  // switch (LoginState.rol_) {
  switch (LoginState.rol_) {
    case "kiosko":
      return <SsGgMonitorFijo />

    case "CALDERA":
      return <DatosCaldera />

    case "kiosko_RILES":
      return <RilesView />

    default:
      return <HeaderMenu Pag_Sel={'dashboard'} IsMonitorFijo={IsMonitorFijo} />
  }

}

export default Main