import React from 'react'
import { URL_CTRL_HIDRIC } from '../router/Url';

export const AddSuscripcion = ({planta, intervalo, elemento, medidores, correo}) => {//addsuscripcion
    // console.log(planta, intervalo, elemento, medidores)

    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_HIDRIC+'addsuscripcion',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            planta, intervalo, elemento, medidores, correo
          })
        })
        let responsito = await query.json();
        // console.log(responsito.ELEMENTOS)
        if(responsito){
          resolve(responsito)
        }else{
          resolve([])
        }
      })
}
