import React from "react";
import { URL_CTRL_ACCESS } from "../../router/Url";

export const GetControlsHere = ({UBICACIONES, FECHA}) => {
	return new Promise(async (resolve, reject) => {
		let query = await fetch(URL_CTRL_ACCESS + "getcontrolhere", {
			method: "POST",
			headers: {
				authorization: "paico2021",
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				UBICACIONES, FECHA
			}),
		});
		let responsito = await query.json();
		// console.log(responsito.body)
		if (responsito.success) {
			resolve(responsito.body);
		} else {
			resolve([]);
		}
	});
};
