import { URL_SRV_ARIZTIA } from "../../router/Url";

export const GetListProducts = () => {
  console.log("getProductsList");
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'getproductos',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        //   body: JSON.stringify({
        //     EMPRESA,CECO
        //   })
        })
        let responsito = await query.json();
        // console.log("ITEMS",responsito.body)
        if(responsito.success){
           resolve(responsito.body)
        }else{
            // Alert.alert('Medidores',responsito.msg)
            resolve([])
            return
          // resolve(responsito.data.body)
        }
      })
}