import React, { useEffect, useLayoutEffect, useState } from 'react'

import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { REST_HOUSR } from '../../router/Url'
import { GetDataFActureToChart } from '../../API/IngresoFactura/GetDataFActureToChart.api'

Highcharts.setOptions({
  lang: {
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 
                  'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    months: [
      'Enero', 'Febrero', 'Marzo', 'Abril',
      'Mayo', 'Junio', 'Julio', 'Agosto',
      'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    weekdays: [
        'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
        'Jeudi', 'Vendredi', 'Samedi'
    ],
    resetZoom: "Restablecer Zoom"
  }
})

export const LineFacture = ({EMPRESA}) => {
  const [state, setstate] = useState()
  const [IsREnder, setIsREnder] = useState(false)

  useLayoutEffect( ( ) => {
    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.',
        months: [
            'Enero', 'Febrero', 'Marzo', 'Abril',
            'Mayo', 'Junio', 'Julio', 'Agosto',
            'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
        ],
        weekdays: [
            'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
            'Jeudi', 'Vendredi', 'Samedi'
        ],
        resetZoom: "Restablecer Zoom"
      }
    })
    setstate(options)
    setIsREnder(true)
  },[])

  let UpdateChartNow = async () => {
    // console.log(state.series[0].data)
    let datas = await GetDataFActureToChart({EMPRESA})//{SENSOR:DATA?.SENSOR, N_MED:DATA?.N_MED,DATA})
    setstate({...state,["series"]:datas})
    
  }

  useEffect(() => {
    UpdateChartNow()
    setInterval(() => {
      // UpdateChartNow()
    }, 1000*60*30);
  
    return () => {
    //  UpdateChartNow() 
    }
  }, [])
  // console.log('=>>>>>3',Date.UTC(2024,4,1));
  // console.log('=>>>>>4',new Date('2024-04-01').getTime());
  // console.log('=>>>>>5',new Date('2024-05-01').getTime());
  // console.log('=>>>>>6',new Date('2024-06-01').getTime());
  // console.log('=>>>>>7',new Date('2024-07-01').getTime());
  // console.log('=>>>>>8',new Date('2024-08-01').getTime());

  const options = {
    chart: {
      type: 'spline',
      zoomType: 'x',
      height: '400px',
      resetZoomButton: {
        position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -30,
            text: "zooom"
        }
      }
    },
    title: {
        text: "Facturas",
        style: {
          // fontSize:'small',
          fontWeight: 'bold',
          fontFamily: 'monospace',
          color: 'black'
        }
    },
    // subtitle: {
    //     text: "SUBTITULO",
    //     style: {
    //       // fontSize:'small',
    //       fontWeight: 'lighter',
    //       fontFamily: 'monospace',
    //       color: 'black'
    //     }
    // },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the dummy year
         month: '%b. %Y',
        //  year: '%b'
      },
        title: {
            text: ''
        }
    },
    legend: {
      align: 'left',
        verticalAlign: 'top',
        borderWidth: 0
    },
    yAxis: {
        title: {
          text: `Consumos`
        },
        min: 0
    },
    tooltip: {
        formatter: function() {
          // let d = new Date()
          let d_ = new Date(this.x)
          // console.log(this.series)
          let UNIDADMEDIDA = this.series.name==="ELECTRICO"?"KW":this.series.name==="HIDRICO"||this.series.name==="GASNATURAL"?"M³":"LTS"
          return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0].split('-',2).join('-') + '<span> </span></b><br/>Consumo: <b>' + this.y + " " + UNIDADMEDIDA + '</b>';
        },
        // headerFormat: '<b>{series.name}</b><br>',
        // pointFormat: '{point.x}: {point.y:.2f} m³',
        // crosshairs: true,
        // shared: true
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 2.5
        }
      }
    },

    series: [
      {
        name: 'HIDRICO',
        data:
            [
              [1722470400000, 0.39],
              [1714521600000, 0.99],
              [1709251200000, 0.39],
              [1711929600000, 0.39],
              [1717200000000, 0.99],
              [1719792000000, 0.39],
            ]
      },
      {
        name: 'ELECTRICO',
        data:
            [
              [1709251200000, 0.59],
              [1711929600000, 0.49],
              [1714521600000, 0.89],
              [1717200000000, 0.79],
              [1719792000000, 0.49],
              [1722470400000, 0.49]
            ]
      }
    ]
    // series: [
    //   {
    //     name: 'HIDRICO',
    //     data:
    //         [
    //           [Date.UTC(2024,1,1), 0.39],
    //           [Date.UTC(2024,2,1), 0.39],
    //           [Date.UTC(2024,3,1), 0.99],
    //           [Date.UTC(2024,4,1), 0.99],
    //           [Date.UTC(2024,5,1), 0.39],
    //           [Date.UTC(2024,6,1), 0.39]
    //         ]
    //   },
    //   {
    //     name: 'ELECTRICO',
    //     data:
    //         [
    //           [Date.UTC(2024,1,1), 0.59],
    //           [Date.UTC(2024,2,1), 0.49],
    //           [Date.UTC(2024,3,1), 0.89],
    //           [Date.UTC(2024,4,1), 0.79],
    //           [Date.UTC(2024,5,1), 0.49],
    //           [Date.UTC(2024,6,1), 0.49]
    //         ]
    //   }
    // ]
  }

  if(IsREnder){
    return (
      <HighchartsReact
        // key={DATA._id}
        highcharts={Highcharts}
        options={state||[]}
      />
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>Cargando ..</h5>
          </div>
        </div>
      </div>
    )
  }
}
