import React, { useEffect, useLayoutEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { REST_HOUSR } from '../../router/Url'
import { LecturaNespraOurRangeTotalizador } from '../../API/LecturaNespraOurRangeTotalizador.api'
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation'

import RotateLeftTwoToneIcon from '@mui/icons-material/RotateLeftTwoTone';
import { GetLecturas } from '../../API/OUR_Lecturas/GetLecturas.api'
import { GetLecturaActual } from '../../API/OUR_Lecturas/GetLecturaActual.api'
import { GetLastDataPE } from '../../API/OUR_Lecturas/GetLastDataPE.api'
import { OUR_LineOneTemp } from './OUR_LineOneTemp.chart'

// ICONS
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

//contantes
let hora = ((1*60*60*24*1000)-(1*60*60*1000))+(1*60*10*1000)//(1*60*60*24*1000)-(1*60*60*1000)//1*60*60*1000
let cambioHora_1 = new Date('2024-04-07').getTime();
let CambioHora_2 = new Date('2024-09-07').getTime();

export const OUR_BarCharTwo = ({DATA}) => {

  // console.log(DATA)
  const [state, setstate] = useState({
    chart: {
        type: 'column',
    },
    title: {
        text: 'CONTADOR POLLOS ENFERMOS',
        align: 'center'
    },
    subtitle: {
        text: 'CONTADOR DE UNIDADES DE POLLOS POR ENFERMEDAD',
        align: 'center'
    },
    // xAxis: {
    //     // categories: ['USA', 'China', 'Brazil', 'EU', 'India', 'Russia'],
    //     crosshair: true,
    //     accessibility: {
    //         description: 'Countries'
    //     }
    // },
    yAxis: {
        min: 0,
        title: {
            text: 'UNIDADES DE POLLOS DETECTADAS'
        }
    },
    tooltip: {
        // valueSuffix: ' UNIDS',
        formatter: function() {
          // let d = new Date()
          let d_ = new Date(this.x)
          // console.log(d_)
          return this.series.name + '</br>'+ FormatNumberCL(this.y) + '</b> Unidades. <b>' ;
        },
        
    },
    plotOptions: {
        column: {
            pointPadding: 0.05,
            borderWidth: 0,
            dataLabels: {
                enabled: true,
                format: '{y}'
            },
          events: {
          click: function() {
            // ShowModalData(DATA)// levanta el modal con los datos historicos
            // console.log(this._i,this.name)
            // console.log(this.name)
            BarCharClick(this.name)//this.chart.axes[0].series[this._i].name
          }
        },  
        }
    },
    series: [
        {
            name: 'POLLO_1',
            data: [406292]
        },
        {
            name: 'POLLO_2',
            data: [51086]
        },
        {
            name: 'POLLO_3',
            data: [51086]
        },
        {
            name: 'POLLO_4',
            data: [51086]
        },
        {
            name: 'POLLO_5',
            data: [51086]
        },
        {
            name: 'POLLO_6',
            data: [51086]
        },
        {
            name: 'POLLO_7',
            data: [51086]
        },
        {
            name: 'POLLO_8',
            data: [51086]
        },
        {
            name: 'POLLO_9',
            data: [51086]
        },
        {
            name: 'POLLO_10',
            data: [51086]
        },
        {
            name: 'POLLO_11',
            data: [51086]
        },
        {
            name: 'POLLO_12',
            data: [51086]
        },
        {
            name: 'POLLO_13',
            data: [51086]
        },
        {
            name: 'POLLO_14',
            data: [51086]
        }
    ]
  });

  const [IsREnder, setIsREnder] = useState(false);
  const [LastUpdateChart, setLastUpdateChart] = useState('');
  const [IsModalChart, setIsModalChart] = useState(false);
  const [BarLineSelected, setBarLineSelected] = useState(null);

  const BarCharClick = async (med) => {
    UpdateChartNow()
    await setBarLineSelected(null)
    let medidor = await DATA.find(dt => dt.FUENTE === med)
    // console.log(med, medidor)
    await setBarLineSelected(medidor)
    await setIsModalChart(true)
  }

  let UpdateChartNow = async () => {
    let BulkData = []
    // for (let mt09 = 0; mt09 < DATA.length; mt09++) {
    //   const mt09_ = DATA[mt09];
    //   let datas = await GetLecturaActual({DATA:mt09_})
    //   await console.log(datas)
    //   await BulkData.push({name:mt09_.FUENTE,data:[datas[0].valor]})
    // }
    let datas = await GetLastDataPE({DATA})
    // console.log(datas.data)
    let arr = datas.data
    let orden = await arr.sort(function (a, b) {
      if (a.data[0] > b.data[0]) {
        return 1;
      }
      if (a.data[0] < b.data[0]) {
        return -1;
      }
      // a must be equal to b
      return 0;
    })//itm.data[0]
    // console.log("orden", orden)
    await setstate({...state,["series"]:orden})
    // await setCategorias(datas.categorias)
    await setIsREnder(true)

  }

  useEffect(() => {
    UpdateChartNow()
    let fecha = new Date().toLocaleString()
    setLastUpdateChart(fecha)
    setInterval(() => {
      let fecha_ = new Date().toLocaleString()
      setLastUpdateChart(fecha_)
      UpdateChartNow()
    }, 1000*60*1);
  
    return () => {
     UpdateChartNow() 
    }
  }, [])
  
  if(IsREnder){
    return (
      <>
      {/* <span onClick={()=>UpdateChartNow()}>recargar datos</span> */}
      <small>Ultima actualizacion <b>{LastUpdateChart}</b></small>
        <HighchartsReact
          // key={DATA._id}
          highcharts={Highcharts}
          options={state||[]}
        />
        {/* <span onClick={()=>UpdateChartNow()}>aaaa</span>  */}
        <div className={IsModalChart?'modal-chart-bar justify-content-center':'modal-chart-bar-off'}>
          <div className='container-load-01'>
            <div className='inner-load-01 '>
              <span onClick={()=>setIsModalChart(false)} style={{cursor: 'pointer'}}><HighlightOffIcon color='error'/></span>
              {
                BarLineSelected?<OUR_LineOneTemp DATA={BarLineSelected}/>:null
              }
            </div>
          </div>
        </div>
      </>
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>Cargando ..</h5>
          </div>
        </div>
      </div>
    )
  }
}

let a = [
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 1","MEDIDOR":"T_TUNEL_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 2 3","MEDIDOR":"T_TUNEL_2_3","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 4","MEDIDOR":"T_TUNEL_4","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 5","MEDIDOR":"T_TUNEL_5","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 6","MEDIDOR":"T_TUNEL_6","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 9","MEDIDOR":"T_TUNEL_9","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 10","MEDIDOR":"T_TUNEL_10","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 11","MEDIDOR":"T_TUNEL_11","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 12","MEDIDOR":"T_TUNEL_12","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 208","MEDIDOR":"T_CAMARA_208","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SKAGINN","MEDIDOR":"T_SKAGINN","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"STARFROST 1","MEDIDOR":"T_STARFROST_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"STARFROST 2","MEDIDOR":"T_STARFROST_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO A B","MEDIDOR":"T_PASILLO_A_B","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO C D","MEDIDOR":"T_PASILLO_C_D","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 207","MEDIDOR":"T_CAMARA_207","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 202","MEDIDOR":"T_CAMARA_202","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 201 PONIENTE","MEDIDOR":"T_CAMARA_201_PONIENTE","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 201 ORIENTE","MEDIDOR":"T_CAMARA_201_ORIENTE","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SALA CALIBRADO","MEDIDOR":"T_SALA_CALIBRADO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA BIVALENTE","MEDIDOR":"T_CAMARA_BIVALENTE","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SALA DROT POIN EMBOLSADO","MEDIDOR":"T_SALA_DROT_POIN_EMBOLSADO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SALA TROZADO DE PAVOS","MEDIDOR":"T_SALA_TROZADO_DE_PAVOS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO 1 CAMARA 304","MEDIDOR":"T_PASILLO_1_CAMARA_304","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO 2 CAMARA 304","MEDIDOR":"T_PASILLO_2_CAMARA_304","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO 3 CAMARA 304","MEDIDOR":"T_PASILLO_3_CAMARA_304","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"AGUA FRIA OSMOSIS","MEDIDOR":"T_AGUA_FRIA_OSMOSIS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"AGUA PLACA BANCO A","MEDIDOR":"T_AGUA_PLACA_BANCO_A","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"AGUA PLACA BANCO B","MEDIDOR":"T_AGUA_PLACA_BANCO_B","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"AGUA PLACA BANCO C","MEDIDOR":"T_AGUA_PLACA_BANCO_C","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BAJA 1","MEDIDOR":"N_RECIRCULADOR_BAJA_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR ALTA 1","MEDIDOR":"N_RECIRCULADOR_ALTA_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BAJA 2","MEDIDOR":"N_RECIRCULADOR_BAJA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR  ALTA 2","MEDIDOR":"N_RECIRCULADOR__ALTA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BAJA 3","MEDIDOR":"N_RECIRCULADOR_BAJA_3","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR IQF","MEDIDOR":"N_RECIRCULADOR_IQF","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR SECUNDARIO","MEDIDOR":"N_RECIRCULADOR_SECUNDARIO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR TRAMPA","MEDIDOR":"N_RECIRCULADOR_TRAMPA","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BOOSTER SADEMA 1","MEDIDOR":"N_RECIRCULADOR_BOOSTER_SADEMA_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BOOSTER SADEMA 2","MEDIDOR":"N_RECIRCULADOR_BOOSTER_SADEMA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR IQF SADEMA 2","MEDIDOR":"N_RECIRCULADOR_IQF_SADEMA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE DE AGUA DE POZO","MEDIDOR":"N_ESTANQUE_DE_AGUA_DE_POZO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE ECUALIZADOR 1","MEDIDOR":"N_ESTANQUE_ECUALIZADOR_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE ECUALIZADOR 2","MEDIDOR":"N_ESTANQUE_ECUALIZADOR_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE RIL CRUDO","MEDIDOR":"N_ESTANQUE_RIL_CRUDO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE RIL FILTRADO","MEDIDOR":"N_ESTANQUE_RIL_FILTRADO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CALDERA","MEDIDOR":"F_CALDERA","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CALDERA","MEDIDOR":"F_CALDERA","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE 25M","MEDIDOR":"F_ESTANQUE_25M","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE 25M","MEDIDOR":"F_ESTANQUE_25M","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO KUCMAQ","MEDIDOR":"F_PERACETICO_KUCMAQ","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO KUCMAQ","MEDIDOR":"F_PERACETICO_KUCMAQ","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO PROCESOS","MEDIDOR":"F_PERACETICO_PROCESOS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO PROCESOS","MEDIDOR":"F_PERACETICO_PROCESOS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO GARRAS","MEDIDOR":"F_PERACETICO_GARRAS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO GARRAS","MEDIDOR":"F_PERACETICO_GARRAS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PLANTA DE TRATAMIENTO DAF","MEDIDOR":"F_PLANTA_DE_TRATAMIENTO_DAF","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PLANTA DE TRATAMIENTO DAF","MEDIDOR":"F_PLANTA_DE_TRATAMIENTO_DAF","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"MATADERO DE PAVO","MEDIDOR":"F_MATADERO_DE_PAVO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"MATADERO DE PAVO","MEDIDOR":"F_MATADERO_DE_PAVO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"MENUDENCIAS","MEDIDOR":"F_MENUDENCIAS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"MENUDENCIAS","MEDIDOR":"F_MENUDENCIAS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"BANCO DE AGUA","MEDIDOR":"F_BANCO_DE_AGUA","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"BANCO DE AGUA","MEDIDOR":"F_BANCO_DE_AGUA","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"EVISCERADO DE PAVOS","MEDIDOR":"F_EVISCERADO_DE_PAVOS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"EVISCERADO DE PAVOS","MEDIDOR":"F_EVISCERADO_DE_PAVOS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"APV","MEDIDOR":"F_APV","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"APV","MEDIDOR":"F_APV","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ANILLO PRESION","MEDIDOR":"F_ANILLO_PRESION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ANILLO PRESION","MEDIDOR":"F_ANILLO_PRESION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PANTALON POLLO","MEDIDOR":"F_PANTALON_POLLO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PANTALON POLLO","MEDIDOR":"F_PANTALON_POLLO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PANTALON PAVO","MEDIDOR":"F_PANTALON_PAVO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PANTALON PAVO","MEDIDOR":"F_PANTALON_PAVO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"BANDEJAS","MEDIDOR":"F_BANDEJAS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"BANDEJAS","MEDIDOR":"F_BANDEJAS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"GENERADORES","MEDIDOR":"E_GENERADORES","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"GENERADORES","MEDIDOR":"E_GENERADORES","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CONDENSADORES","MEDIDOR":"E_CONDENSADORES","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CONDENSADORES","MEDIDOR":"E_CONDENSADORES","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 1 SUBESTACION","MEDIDOR":"E_TRAFO_1_SUBESTACION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 1 SUBESTACION","MEDIDOR":"E_TRAFO_1_SUBESTACION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 2 SUBESTACION","MEDIDOR":"E_TRAFO_2_SUBESTACION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 2 SUBESTACION","MEDIDOR":"E_TRAFO_2_SUBESTACION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 3 SUBESTACION","MEDIDOR":"E_TRAFO_3_SUBESTACION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 3 SUBESTACION","MEDIDOR":"E_TRAFO_3_SUBESTACION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 4 SUBESTACION","MEDIDOR":"E_TRAFO_4_SUBESTACION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 4 SUBESTACION","MEDIDOR":"E_TRAFO_4_SUBESTACION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 5 SUBESTACION MATADERO","MEDIDOR":"E_TRAFO_5_SUBESTACION_MATADERO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 5 SUBESTACION MATADERO","MEDIDOR":"E_TRAFO_5_SUBESTACION_MATADERO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 6 PLANTA DE TRATAMIENTO","MEDIDOR":"E_TRAFO_6_PLANTA_DE_TRATAMIENTO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 6 PLANTA DE TRATAMIENTO","MEDIDOR":"E_TRAFO_6_PLANTA_DE_TRATAMIENTO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 7 SUBESTACION SADEMA 2","MEDIDOR":"E_TRAFO_7_SUBESTACION_SADEMA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 7 SUBESTACION SADEMA 2","MEDIDOR":"E_TRAFO_7_SUBESTACION_SADEMA_2","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CLORO SALA DE BOMBAS","MEDIDOR":"A_CLORO_SALA_DE_BOMBAS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 1","MEDIDOR":"A_SENSOR_DE_AMONIACO_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 2","MEDIDOR":"A_SENSOR_DE_AMONIACO_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 3","MEDIDOR":"A_SENSOR_DE_AMONIACO_3","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 4","MEDIDOR":"A_SENSOR_DE_AMONIACO_4","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 5","MEDIDOR":"A_SENSOR_DE_AMONIACO_5","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 6","MEDIDOR":"A_SENSOR_DE_AMONIACO_6","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 7","MEDIDOR":"A_SENSOR_DE_AMONIACO_7","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 8","MEDIDOR":"A_SENSOR_DE_AMONIACO_8","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 9","MEDIDOR":"A_SENSOR_DE_AMONIACO_9","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 10","MEDIDOR":"A_SENSOR_DE_AMONIACO_10","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 11","MEDIDOR":"A_SENSOR_DE_AMONIACO_11","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 12","MEDIDOR":"A_SENSOR_DE_AMONIACO_12","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"DESCARGA SADEMA 1","MEDIDOR":"P_DESCARGA_SADEMA_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"DESCARGA SADEMA 2","MEDIDOR":"P_DESCARGA_SADEMA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"LINEA DE ALIMENTACION DE AGUA POZO","MEDIDOR":"P_LINEA_DE_ALIMENTACION_DE_AGUA_POZO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"LINEA DE ALIMENTACION DE AGUA POTABLE","MEDIDOR":"P_LINEA_DE_ALIMENTACION_DE_AGUA_POTABLE","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"LINEA ANILLO PRESION","MEDIDOR":"P_LINEA_ANILLO_PRESION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"LINEA AIRE COMPRIMIDO","MEDIDOR":"P_LINEA_AIRE_COMPRIMIDO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120}
]
