import React from 'react'
import { URL_SRV_ARIZTIA, URL_SRV_RAIZ } from '../../router/Url';

//makefile_electric
export const MakeAFileELectric = ({PLANTA, MEDIDOR, FECHAINICIO, FECHAFIN}) => {
    // console.log(PLANTA, MEDIDOR, FECHAINICIO, FECHAFIN)
    
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'makefile_electric',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            EMPRESA: PLANTA,
            MEDIDOR: MEDIDOR,
            RANGO_TIEMPO: {
                INICIO: Math.floor(FECHAINICIO/1000),
                FIN: Math.floor(FECHAFIN/1000)
            }   
          })
        })
        let responsito = await query.json();
        // console.log(responsito.body)
        if(responsito.success){
          resolve(responsito.success)
          let url = responsito.body;
                let a = document.createElement("a");
                a.href = URL_SRV_RAIZ+url+"?marco="+new Date().getTime();//to web add url_server+build/
                // console.log(a.href)
                // a.href = url;
                a.download = "Reporte_lecturas_electricas.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
                alert('En instantes se descargara su Archivo!!')
        }else{
          resolve(false)
        }
    })
}
