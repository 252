import React, { useEffect, useState } from 'react'
import { GetLecturaActual } from '../API/OUR_Lecturas/GetLecturaActual.api';
import { set } from 'date-fns';
import { OURLineFour } from './charts/OURLineFour.chart';

export const CubiData = ({DATA}) => {
    // console.log(DATA)
    const [Valor, setValor] = useState(null);
    const [Fecha, setFecha] = useState(null);
    const [Modal, setModal] = useState(false);

    const ObtenerLecturaActual = async () => {
        let data = await GetLecturaActual({DATA});
        let fecha_ = new Date(data[0].timestamp).toISOString().split('T')
        setValor(data[0].valor)
        setFecha(fecha_[1].split('.')[0])
        // console.log(data[0].valor,fecha_[0], fecha_[1].split('.')[0])
    }

    useEffect(() => {
        ObtenerLecturaActual()
        setInterval(() => {
            ObtenerLecturaActual()
        }, 1000*60);
        return () => {
            ObtenerLecturaActual()
        }
    }, [])
    

    return (
        <>
            <div className='col-12 p-0 my-1 text-center' style={{cursor: 'pointer'}} onClick={()=>setModal(true)}>
                <div className='row cubi-data-card'>
                    <div className='col-12 p-0 m-0'>
                        <small>{DATA.FUENTE}</small><br/>
                    </div>
                    <div className='col-12 p-0 m-0 cubi-data-card-value'>
                        <b>{Valor?Valor:"0.0"} {DATA.UNIDAD}</b>
                    </div>
                    {/* <div className='col-12 p-0 m-0'>
                        <span style={{fontSize: 10}}>{Fecha?Fecha:"-- --"} HRS</span>
                    </div> */}
                </div>
            </div>

            {/* modal grafico historial */}
            {
                Modal?<div className='modal-cubidata'>
                <div className="row" style={{backgroundColor: 'white'}}>
                    <div className="col-12 text-right p-3">
                        <button className='btn btn-danger' onClick={()=>setModal(false)}>X</button>
                    </div>
                    <div className='col-12 text-center'>
                        <OURLineFour DATA={DATA} />
                    </div>
                </div>
            </div>:null
            }
            
        </>
    )
}
