import React, { useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import { FormControl, InputLabel, MenuItem, Select, Switch, TextField, Tooltip, tooltipClasses } from '@mui/material'
import { MedidoresUniverse } from '../../API/edicionMedidores/MedidoresUniverse.api'
import './editMedidores.css'
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { SaveGlobalMedidores } from '../../API/edicionMedidores/SaveGlobalMedidores.api'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

export const EditMedidores = () => {
    const [medidores, setMedidores] = useState([]);
    const [empresa, setEmpresa] = useState([]);
    const [selEmpresa, setSelEmpresa] = useState('');
    const [contrato, setContrato] = useState([]);
    const [selContrato, setSelContrato] = useState('');
    const [elemento, setElemento] = useState([]);
    const [selElemento, setSelElemento] = useState('');
    const [filterMed, setFilterMed] = useState([]);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const [medidorChanged,setMedidorChanged] = useState([]);
    const [medidorChanged_,setMedidorChanged_] = useState([]);

    useEffect(() => {
        const getMedidores = async () => {
            let med = await MedidoresUniverse()
            setMedidores(med)
            var hash = {};
            let plt = await med.filter(function(current) 
            {
                let go = String(current.EMPRESA)
                let exists = !hash[go] || false;
                hash[go] = true;
                let emp = JSON.parse(exists)
                return exists;
            }).map(plt_ => plt_.EMPRESA)
            setEmpresa(plt)
            // console.log(plt)
        }
        getMedidores()
        return () => {
            
        }
    }, [])

    useEffect(() => {
        const getContratos = async () => {
            setSelContrato([])
            let contratos = medidores.filter((med) => med.EMPRESA === selEmpresa)
            var hash = {};
            let plt = await contratos.filter(function(current) 
            {
                let go = String(current.CONTRATO )
                let exists = !hash[go] || false;
                hash[go] = true;
                let emp = JSON.parse(exists)
                return exists;
            }).map(plt_ => plt_.CONTRATO )
            let sorted_a = plt.sort((a, b) => a.localeCompare(b));
            setContrato(sorted_a)
            setMedidorChanged([])
        }
        getContratos()
    }, [selEmpresa])

    useEffect(() => {
        const elemento = async () => {
            setSelElemento([]);
            let contratos = medidores.filter((med) => med.EMPRESA === selEmpresa && med.CONTRATO === selContrato)
            var hash = {};
            let plt = await contratos.filter(function(current) 
            {
                let go = String(current.ELEMENTO)
                let exists = !hash[go] || false;
                hash[go] = true;
                let emp = JSON.parse(exists)
                return exists;
            }).map(plt_ => plt_.ELEMENTO)
            let sorted_a = plt.sort((a, b) => a.localeCompare(b));
            setElemento(plt)
            setMedidorChanged([])
            // console.log(sorted_a)
        }
        elemento()
    }, [selContrato])
    
    useEffect(() => {
        let fltMed = medidores.filter((med) => med.EMPRESA === selEmpresa && med.CONTRATO === selContrato && med.ELEMENTO === selElemento && med.SENSOR != 'm2');
        // ordenar alfabeticamente los medidores
        let sorted_a = fltMed.sort((a, b) => a.MEDIDOR.localeCompare(b.MEDIDOR));
        setFilterMed(sorted_a);
        setMedidorChanged([])
        // console.log(fltMed)
        return () => {
            
        }
    }, [selElemento])
    
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: 'transparent',
          color: 'rgba(0, 0, 0, 0.1)',
          border: '1px solid transparent',
          width:windowDimensions.width-20
        },
      }));

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const handleChange = async (e,data,type) => {
        // console.log(e,type,data)
        if(type === "MEDIO"){//******** */

            let filChange = await filterMed.filter((med) => {
                if(med.MEDIDOR === data.MEDIDOR){
                    med["MEDIO"] = e
                    return med
                }
            })
            if(medidorChanged.length === 0){
                // console.log("0",filChange)
                setMedidorChanged(filChange)
            }else{
                let flt_ = medidorChanged.filter((med) => {
                    if(med.MEDIDOR === data.MEDIDOR){
                        // console.log("1",filChange)
                        med["MEDIO"] = e
                        return med
                    }
                })
                if(flt_.length === 0){
                    // console.log("2",filChange)
                    filChange[0]["MEDIO"] = e
                    setMedidorChanged([...medidorChanged, filChange[0]])
                }

            }

            setFilterMed(filterMed.map((med) => {
                    if(med.MEDIDOR === data.MEDIDOR){
                        return {...med, MEDIO: e}
                    }
                    return med
                })
            )
        }
        else if(type === "OPERACIONAL"){

            let filChange = await filterMed.filter((med) => {
                if(med.MEDIDOR === data.MEDIDOR){
                    med["OPERACIONAL"] = e
                    return med
                }
            })
            if(medidorChanged.length === 0){
                // console.log("0",filChange)
                setMedidorChanged(filChange)
            }else{
                let flt_ = medidorChanged.filter((med) => {
                    if(med.MEDIDOR === data.MEDIDOR){
                        // console.log("1",filChange)
                        med["OPERACIONAL"] = e
                        return med
                    }
                })
                if(flt_.length === 0){
                    // console.log("2",filChange)
                    filChange[0]["OPERACIONAL"] = e
                    setMedidorChanged([...medidorChanged, filChange[0]])
                }

            }
            setFilterMed(filterMed.map((med) => {
                    if(med.MEDIDOR === data.MEDIDOR){
                        return {...med, OPERACIONAL: e}
                    }
                    return med
                })
            )
        }
        else if(type === "MAX"){
            
            let filChange = await filterMed.filter((med) => {
                if(med.MEDIDOR === data.MEDIDOR){
                    med["MAX"] = e
                    return med
                }
            })
            if(medidorChanged.length === 0){
                // console.log("0",filChange)
                setMedidorChanged(filChange)
            }else{
                let flt_ = medidorChanged.filter((med) => {
                    if(med.MEDIDOR === data.MEDIDOR){
                        // console.log("1",filChange)
                        med["MAX"] = e
                        return med
                    }
                })
                if(flt_.length === 0){
                    // console.log("2",filChange)
                    filChange[0]["MAX"] = e
                    setMedidorChanged([...medidorChanged, filChange[0]])
                }

            }

            setFilterMed(filterMed.map((med) => {
                    if(med.MEDIDOR === data.MEDIDOR){
                        return {...med, MAX: e}
                    }
                    return med
                })
            )
        }
        else if(type === "STATE"){
            
            let filChange = await filterMed.filter((med) => {
                if(med.MEDIDOR === data.MEDIDOR){
                    med["STATE"] = e
                    return med
                }
            })
            if(medidorChanged.length === 0){
                // console.log("0",filChange)
                setMedidorChanged(filChange)
            }else{
                let flt_ = medidorChanged.filter((med) => {
                    if(med.MEDIDOR === data.MEDIDOR){
                        // console.log("1",filChange)
                        med["STATE"] = e
                        return med
                    }
                })
                if(flt_.length === 0){
                    // console.log("2",filChange)
                    filChange[0]["STATE"] = e
                    setMedidorChanged([...medidorChanged, filChange[0]])
                }

            }

            setFilterMed(filterMed.map((med) => {
                    if(med.MEDIDOR === data.MEDIDOR){
                        return {...med, STATE: Boolean(e)}
                    }
                    return med
                })
            )
        }
    }

    const handleSaveChamgeMedidors = async () => {
        await SaveGlobalMedidores({medidorChanged});
    }

    return (
        <>
            <HeaderMenu Pag_Sel={'/v2/editormedidores'} />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <span className=''>
                            <HtmlTooltip
                                title={
                                    <img src={require("../../assets/GRAFICOMEDIDA.png")}
                                        alt="Help"
                                        width={windowDimensions.width-20}
                                        style={{borderRadius:20}}
                                    />
                                }
                            >
                                <InfoIcon htmlColor='#6495ED' />
                            </HtmlTooltip>
                        </span>
                        <h1>Editar Medidores</h1>
                    </div>
                    <div className='col-12 col-md-4 mb-2'>
                        <FormControl fullWidth>
                            <InputLabel size='small' id="demo-simple-select-label">Empresa</InputLabel>
                            <Select
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selEmpresa}
                                onChange={(e)=>setSelEmpresa(e.target.value)}
                                label="Empresa"
                            >
                            {
                                empresa?.map((emp, index) => <MenuItem key={index} value={emp}>{emp}</MenuItem>)
                            }
                            
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-12 col-md-4 mb-2'>
                        <FormControl fullWidth>
                            <InputLabel size='small' id="demo-simple-select-label">Contrato</InputLabel>
                            <Select
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selContrato}
                                onChange={(e)=>setSelContrato(e.target.value)}
                                label="Contrato"
                            >
                                {
                                    contrato?.map((emp, index) => <MenuItem key={index} value={emp}>{emp}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-12 col-md-4 mb-2'>
                        <FormControl fullWidth>
                            <InputLabel size='small' id="demo-simple-select-label">Elemento</InputLabel>
                            <Select
                                size='small'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selElemento}
                                onChange={(e)=>setSelElemento(e.target.value)}
                                label="Elemento"
                            >
                            {
                                elemento?.map((emp, index) => <MenuItem key={index} value={emp}>{emp}</MenuItem>)
                            }
                            </Select>
                        </FormControl>
                    </div>
                </div>    
            </div>

            <div className='container-fluid mt-2 mb-5'>
                <div className='row'>
                    {
                        filterMed?.map((emp, index) => (
                            <div key={index} className='col-12 col-md-4 py-2'>
                                <div className={emp.STATE?'row bg-card-modal-edit-t mx-0 py-2':'row bg-card-modal-edit-f mx-0 py-2'}>
                                <div className='col-10 text-truncate'>
                                    <span className='small'><b>{emp.MEDIDOR}</b></span>
                                </div>
                                <div className='col-1'>
                                    <small className='float-right--'><b>{emp.SENSOR}</b></small>
                                </div>
                                <div className='col-6 py-1'>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Valor Operacional"
                                        variant="outlined"
                                        type='number'
                                        value={emp.OPERACIONAL}
                                        onChange={(e)=>handleChange(e.target.value,emp,"OPERACIONAL")}
                                    />
                                </div>
                                
                                <div className='col-6 py-1'>
                                    <TextField
                                        error
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Valor Medio"
                                        variant="outlined"
                                        type='number'
                                        value={emp.MEDIO}
                                        onChange={(e)=>handleChange(e.target.value,emp,"MEDIO")}
                                    />
                                </div>
                                <div className='col-6 py-1'>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Valor Maximo"
                                        variant="outlined"
                                        type='number'
                                        value={emp.MAX}
                                        onChange={(e)=>handleChange(e.target.value,emp,"MAX")}
                                    />
                                </div>
                                <div className='col-6 text-center pt-1'>
                                    <Switch
                                        checked={emp.STATE}
                                        // onChange={(e)=>console.log(e.target.checked)}
                                        onChange={(e)=>handleChange(e.target.checked,emp,"STATE")}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        color='success'
                                    />
                                </div>
                                {/* descripcion  medidor */}
                                {/* <div className='col-12 py-1'>
                                    <TextField
                                        style={{fontSize:8}}
                                        fullWidth
                                        size='small'
                                        id="outlined-basic"
                                        label="Descripcion Medidor"
                                        variant="outlined"
                                        multiline
                                        type='text'
                                        // value={emp.MAX}
                                        // onChange={(e)=>handleChange(e.target.value,emp,"MAX")}
                                    />
                                </div> */}
                                </div>
                            </div>
                        ))
                    }
                    <div className='col-12 py-2 text-right'>
                        <button
                            className='btn btn-sm btn-success'
                            disabled={medidorChanged.length>0?false:true}
                            onClick={()=>handleSaveChamgeMedidors()}
                        >Guardar Cambios</button>
                    </div>
                </div>
            </div>
            
            {/* MODAL EJEMPLO GRAFICOS */}

        </>
    )
}

