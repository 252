import React from 'react'
import { URL_CTRL_HIDRIC } from '../../router/Url';

export const GetLecturas = ({medidor, sensor, planta, ahora, pasado}) => {
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_HIDRIC+'our_lecturas_ariztia',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            medidor, sensor, planta, ahora, pasado
          })
        })
        let responsito = await query.json();
        // console.log(responsito)
        
        if(responsito.success){
          if(sensor === "m1"){
            let clearData = await (responsito.body).map(ulm =>{
              let {caudal, timestamp} = ulm
              return {valor: caudal, timestamp: timestamp}
            })
            resolve(clearData)
          }else{
            let clearData = await (responsito.body).map(ulm =>{
              let {totalizador, timestamp} = ulm
              return {valor: totalizador, timestamp: timestamp}
            })
            resolve(clearData)
          }
        }else{
          resolve([])
        }
      })
}

//let medidores = ['TEMPERATURA','RECTIFICACION_S2','RECTIFICACION_S1','PRESION','POLLOENFERMO','OXIGENO','NIVEL','AMONIACO']