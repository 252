import { TULSA_KING } from "../router/Url"
import { LecturaEsmartOur } from "./LecturaEsmartOur.api"
import { LecturaNespraOur } from "./LecturaNespraOur.api"

export const UpdateDataLectura = async ({ DATA }) => {
  // console.log('DATOS TO UPDATE',DATA)
  let contrato = DATA.CONTRATO

  // console.log("Contrato",DATA)
  // ******|NESPRA\*******************************************
  if(contrato === "NESPRA"){
    let medidor = DATA.MEDIDOR
    let fuente = DATA.FUENTE
    let url = DATA.URL
    let sensor = DATA.SENSOR
    let n_med = DATA.N_MED
    // console.log("WE ARE IN NESPRA")
    let now = Math.floor((new Date).getTime() / 1000)
    let antes = now - 600
    let antes_2H = now - (60 * 60 * 32)
    let antes_15M = now - (60 * 15)
    // console.log("************************>",antes_15M,now)
    
    //esta logica esta creada para crear un grafico de gauge solo para mostrar la suma total de los medidores por hora de los empalmes
    // if(medidor === "SUMA TRAFOS"){
      
      const lecNes = await LecturaNespraOur({planta:DATA.EMPRESA, medidor:DATA.MEDIDOR, sensor: DATA.SENSOR!=="m1"?"m1":"m1"})
      // console.log(lecNes);
      return lecNes
  
  }

  // ******|NESPRA\*******************************************
  // ******|E-SMART/*******************************************
  if(contrato === "E-SMART"){
    let fuente = DATA.FUENTE//NOMBRE MEDIDORE
    let url = DATA.URL
    let sensor = DATA.SENSOR//TIPO DE LECTURA(INSTANTANEA=M1, ACUMULADOR=M2)
    let n_med = DATA.N_MED//PARA E-SMART NO SE UTILIZA
    

    // console.log("WE ARE IN E-SMART")
    let now = new Date().getTime()
    let antes = now - 600
    let antes_15M = now - (1000 * 60 * 50)

    const lecNes = await LecturaEsmartOur({planta:DATA.EMPRESA, medidor:DATA.MEDIDOR})
    // console.log("///////",lecNes);
    return lecNes

  }

}