import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { UpdateDataLectura } from '../../API/UpdateDataLectura.api'
import { GetTotalizadorFromDB } from '../../API/POST/GetTotalizadorFromDB.api'
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation'

function GaugeOne({DATA}) {
  // console.log(DATA)
  let max = DATA?.MAX
  let a = max / 5
  let b = (a*3).toFixed(0)
  let c = (a*2).toFixed(0)
  let d = c/2
  let v1 = 0
  let v2 = b
  let m1 = b
  let m2 = DATA?.MEDIO
  let r1 = DATA?.MEDIO
  let r2 = max
  // console.log(DATA)
  const options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      // height: '230px'//'100%'
    },

    title: {
      text: DATA.MEDIDOR,
      style: {
        fontWeight: 'lighter',
        fontFamily: 'monospace',
        color: 'red'
      }
    },

    pane: {
        startAngle: -125,
        endAngle: 125,
        background: null,
        center: ['50%', '60%'],//['50%', '75%'],
        size: '90%'
    },

    // the value axis
    yAxis: {
        min: 0,
        max: DATA?.MAX,//valor maximo del grafico
        tickPixelInterval: 50,//separador de cuadros en angulo
        tickPosition: 'inside',
        tickColor: 'white',//color separador de cuadros en angulo
        tickLength: 15,//tamaño linea separadora de cuadros en angulo
        tickWidth: 1,//ancho de linea separadora de cuadros en angulo
        minorTickInterval: null,
        // minorTickColor: 'white',
        // minorTickLength:5,
        labels: {
            distance: 11,
            style: {
                fontSize: '12px',
            }
        },
        title: {
          text: ""
        },
        lineWidth: 0,// grosor linea de borde angulo
        plotBands: [{
            from: v1,
            to: v2,
            color: '#55BF3B', // green
            thickness: 30,
          }, {
              from: m1,
              to: m2,
              color: '#DDDF0D', // yellow
              thickness: 30
          }, {
              from: r1,
              to: r2,
              color: 'red', // red
              thickness: 30
          }
        ]
    },

    series: [{
        name: 'Consumo',
        data: [155],
        tooltip: {
            valueSuffix: ' --'//unidad de medica precargada
        },
        dataLabels: {
            format: '{y} --',//unidad de medica precargada
            borderWidth: 0,//borde del numero indicador
            color: (
                Highcharts.defaultOptions.title &&
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
            ) || '#000000',
            style: {
                fontSize: '16px'
            }
        },
        dial: {
            radius: '90%',//largo del indicacor central
            backgroundColor: 'black',//color del indicador
            baseWidth: 10,
            baseLength: "0%",
            rearLength: '0%'
        },
        pivot: {
            backgroundColor: 'black',
            radius: 5
        }

    }],
    responsive: {
      rules: [{
          condition: {
              // maxWidth: 500
              minHeight: 238
          },
          chartOptions: {
              legend: {
                  align: 'center',
                  verticalAlign: 'bottom',
                  layout: 'horizontal'
              },
              yAxis: {
                  labels: {
                      align: 'left',
                      x: 0,
                      y: -5
                  },
                  title: {
                      text: null
                  }
              },
              subtitle: {
                  text: null
              },
              credits: {
                  enabled: false
              }
          }
      }]
  }
  
  }
  const [state, setstate] = useState(options);
  const [MaxValue, setMaxValue] = useState(0);
  const [IsREnder, setIsREnder] = useState(false);
  const [LoadText, setLoadText] = useState("Cargando ...");
  const [ValorAcumulador, setValorAcumulador] = useState("");
  const [BA_REST_R3, setBA_REST_R3] = useState(0);


 useLayoutEffect( ( ) => {
  // console.log('===========>',Highcharts)
    highchartsMore(Highcharts);
    // solidGauge(Highcharts);
    // console.log("este es el maximo",DATA.MAX)
    // setstate(options)
    return () => {
      highchartsMore(Highcharts);
    };
  },[])

  const UpgradeDataChart = async () => {//actualiza el grafico
    if(DATA){
      // console.log("este es el maximo",DATA.MAX)
      // setstate(options)
      // await setMaxValue(DATA.MAX)
      let ba_rest_m3 = 0
      let ba_rest_l = 0
      let control = new Date()
      // console.log(control.toUTCString())
      let datas = await UpdateDataLectura({SENSOR:DATA.SENSOR, N_MED:DATA.N_MED,DATA})
      // console.log(DATA,datas);
      if(DATA.MEDIDOR==="BANCOS DE AGUA"){
        let menudencias = await UpdateDataLectura({
          DATA:{
            "CONTRATO":"NESPRA",
            "EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.",
            "MEDIDOR":"MENUDENCIAS",
            "SENSOR":"m1"
          }
        })
        let valueN = Number(Number((datas[0][DATA.SENSOR]/1000)*60).toFixed(2))
        let M_m3 = menudencias[0]['m1']
        let M_l = Number(Number(menudencias[0]['m1']*16.667).toFixed(2))
        let B_m3 = valueN
        let B_L = datas[0][DATA.SENSOR]
        ba_rest_m3 = Number((B_m3-M_m3).toFixed(2))
        ba_rest_l = Number((B_L-M_l).toFixed(2))
        setBA_REST_R3(Number((B_m3-M_m3).toFixed(2)))
        // console.log("BANCOS DE AGUA",(B_m3-M_m3).toFixed(2),"M3/H")
        // console.log("BANCOS DE AGUA",(B_L-M_l).toFixed(2),"L/M")
      }
      
      // console.log(datas[0]['m1'])
      if(datas?.error){
        setLoadText(datas.msg)
      }else{
        let FormatFecha = new Date(control.setUTCSeconds(Math.floor(datas[0].TimeStamp/1000000)))
        // console.log(FormatFecha.toISOString())
        const options = {
        chart: {
          type: 'gauge',
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: '100%',//'100%'
          // width: '25%'
        },
    
        title: {
          text: DATA.MEDIDOR==="MATADERO PAVO"?"SERV. POLLO PAVO":DATA.MEDIDOR==="BANCOS DE AGUA"?"AGUA PLACA":DATA.MEDIDOR,
          style: {
            fontSize:'25px',//fuente del texto del titulo
            fontWeight: 'bolder',
            fontFamily: 'monospace',
            color: 'black'
          }
        },
        subtitle:{
          text: DATA.TIPO,
          style: {
            fontSize:'15px',
            fontWeight: 'lighter',
            fontFamily: 'monospace',
            color: 'gray'
          }
        },
    
        pane: {
            startAngle: -130,
            endAngle: 130,
            background: null,
            center: ['50%', '50%'],//['50%', '75%'],
            size: '85%'
        },
    
        // the value axis
        yAxis: {
          min: 0,
          max: DATA.MAX,//valor maximo del grafico
          tickPixelInterval: 100,//separador de cuadros en angulo
          tickPosition: 'inside',
          tickColor: 'white',//color separador de cuadros en angulo
          tickLength: 70,//tamaño linea separadora de cuadros en angulo
          tickWidth: 2,//ancho de linea separadora de cuadros en angulo
          minorTickInterval: null,
          // minorTickColor: 'white',
          // minorTickLength:5,
          labels: {
              distance: 20,
              style: {
                  fontSize: '19px',
              }
          },
          title: {
            text: DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧",//💦💨texto dentro del grafico gauge
            y: 100,
            // x: 120,
            style: {
              // marginTop: '25px',
              fontSize: '50px',
              paddingTop:'20px',
              position: 'relative'
            }
          },
          lineWidth: 0,// grosor linea de borde angulo
          plotBands: [{
            from: v1,
            to: v2,
            color: '#55BF3B', // green
            thickness: 75,
            }, {
              from: m1,
              to: m2,
              color: '#DDDF0D', // yellow
              thickness: 75
            }, {
              from: r1,
              to: r2,
              color: 'red', // red
              thickness: 75
            }
          ]
        },
        plotBackgroundColor: {
          radialGradient: { cx: 0.8, cy: 0.8, r: 0.8 },
          stops: [
             [0, '#003399'],
             [1, '#3366AA']
          ]
      },
    
        series: [{
          name: 'Consumo',
          // data: [datas[0][DATA.SENSOR]],//,//[Math.floor(Math.random() * 180)],-1*(datas[0][DATA.SENSOR])
          data: DATA.MEDIDOR==="BANCOS DE AGUA"?[ba_rest_l]:[datas[0][DATA.SENSOR]],//,//[Math.floor(Math.random() * 180)],-1*(datas[0][DATA.SENSOR])
          tooltip: {
              valueSuffix: ` ${DATA.UNIDAD}`
          },
          dataLabels: {
              format: `{y} ${DATA.UNIDAD}`,//' m³/h',
              borderWidth: 0,//borde del numero indicador
              x: 0,
              y: 75,
              color: (
                  Highcharts.defaultOptions.title &&
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || '#333333',
              style: {
                  fontSize: '25px',
                  paddingTop: '15px',
                  position: 'relative'
              }
          },
          dial: {
              radius: '99%',//largo del indicacor central
              backgroundColor: 'black',//color del indicador
              baseWidth: 17,
              baseLength: "0%",
              rearLength: '0%'
          },
          pivot: {
              backgroundColor: 'black',
              radius: 10
          }
  
        }],
        responsive: {
          rules: [{
              condition: {
                  // maxWidth: 500
                  minHeight: 238
              },
              chartOptions: {
                  legend: {
                      align: 'center',
                      verticalAlign: 'bottom',
                      layout: 'horizontal'
                  },
                  yAxis: {
                      labels: {
                          align: 'center',
                          x: 0,
                          y: 5
                      },
                      title: {
                          text: null
                      }
                  },
                  subtitle: {
                      text: null
                  },
                  credits: {
                      enabled: false
                  }
              }
          }]
      }
      
      }
     
      await setstate(options)
      // await setstate({...state,["series"]:the_series,["yAxis"]:yAxis})
      await setIsREnder(true)
      }
      
    }
  }

  const GetTotalizador = async () => {
    //{SENSOR:DATA.SENSOR, N_MED:DATA.N_MED,DATA}
    // generar logica para ir a buscar al servidor y luego en la base de datos el valor del acumulador correspondiente al medidor
    if(DATA.MEDIDOR!=="EMPALME 1" && DATA.MEDIDOR!=="EMPALME 2" && DATA.MEDIDOR!=="SUMA TRAFOS"){
      let totalizador = await GetTotalizadorFromDB({MEDIDOR: DATA.MEDIDOR, SENSOR: 'm2', EMPRESA:  DATA.EMPRESA})
      // console.log(totalizador);
      if(totalizador===null)setValorAcumulador('-- -- KW/H')
      else setValorAcumulador(FormatNumberCL(totalizador.totalizador)+' KW/H');
    }

  }

  //setInterval
  useEffect(() => {
    UpgradeDataChart()
    // GetTotalizador()
    let intervalo = setInterval(() => {
      UpgradeDataChart()
      GetTotalizador()      
    }, 1000*60*15);
    return () => {
      clearInterval(intervalo)
      // UpgradeDataChart()
    };
  }, [])

    // MODIFICADOR DE RANGOS EN GRAFICO DEPENDIENDO DE LA HORA SOLO PARA PANTALON NORTE
  useEffect(() => {
    const EveryMinits = async () => {
      let fecha = new Date()
      // console.log(fecha.toISOString());
      let hora = fecha.getHours()
      // console.log("hora", fecha.getHours());//****************************************************************
      if(DATA.MEDIDOR === "PANTALON NORTE"){
        if(hora > 4 && hora < 19){
          v1 = 0
          v2 = 94
          m1 = 94
          m2 = 110
          r1 = 110
          r2 = 120
          UpgradeDataChart()
        }else{
          // DE 20:01HRS HASTA 03:59AM
          v1 = 0
          v2 = 60
          m1 = 60
          m2 = 90
          r1 = 90
          r2 = 110
          UpgradeDataChart()
        }
      }
    }
    EveryMinits()
    setInterval(() => {
      EveryMinits()
    }, 1000*60);
  }, [])
  

  if(IsREnder){
    return (
      <div className="text-center p-0 m-0">
        <HighchartsReact
          key={DATA._id}
          highcharts={Highcharts}
          options={state||[]}
        />
        {
          DATA.MEDIDOR==="BANCOS DE AGUA"?<span style={{fontSize: 24, fontWeight: 'bolder', position: 'relative', bottom: '120px'}}>{BA_REST_R3} M3/H</span>:null
        }
      </div>      
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '230px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>{LoadText}</h5>
          </div>
        </div>
      </div>
    )
  }
    

  
}

export default GaugeOne