import React, { useEffect, useLayoutEffect, useState } from 'react'

import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { REST_HOUSR } from '../../../router/Url'
import { FormatNumberCL } from '../../../mutations/FormatNumeral.Mutation'
import { LecturaEsmartOurRangeTotalizador } from '../../../API/LecturaEsmartOurRangeTotalizador.api'
Highcharts.setOptions({
  lang: {
    shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 
                  'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    months: [
      'Enero', 'Febrero', 'Marzo', 'Abril',
      'Mayo', 'Junio', 'Julio', 'Agosto',
      'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ],
    weekdays: [
        'Dimanche', 'Lundi', 'Mardi', 'Mercredi',
        'Jeudi', 'Vendredi', 'Samedi'
    ]
  }
})


export const LineOneDay = ({DATA}) => {
  // console.log('LineFour')
  // console.log(DATA)
  let title = DATA.MEDIDOR==="MATADERO PAVO"?"SERV. POLLO PAVO":DATA.MEDIDOR==="BANCOS DE AGUA"?"AGUA PLACA":DATA.MEDIDOR
  let subTitle = DATA.TIPO
  let unidad_med = DATA.UNIDAD
  const [state, setstate] = useState()
  const [IsREnder, setIsREnder] = useState(false)
  const [LimiteOperacional, setLimiteOperacional] = useState(0)
  

  useLayoutEffect( ( ) => {
    setstate(options)
    setIsREnder(true)
  },[])

  let UpdateChartNow = async () => {
    // console.log(state.series[0].data)
    let OneWeek = 604800;
    let OneDay = 86400;
    let OneHour = 3600;
    let OneMinit = 60

    let now = Math.floor((new Date).getTime())//segundos now
    let pasado = (now/1000) - OneDay;
    // console.log("this",Number((pasado).toFixed(0)))
    
    let datas = await LecturaEsmartOurRangeTotalizador({medidor: DATA.MEDIDOR,sensor:"m1", contrato: DATA.CONTRATO, planta: DATA.EMPRESA, pasado: Number((pasado).toFixed(0))})
    let g = []
    // LOGICA PARA GENERAR DATOS EN CASO DE SER MEDIDOR DE NESPRA
    let LimiteOperacional_ = 0
    let arr_consumo = []
    let arr_consumo_2 = []
    let arr_consumo_final = []
    // console.log("maximo decimo meridio", DATA.MEDIO);
    if(DATA.CONTRATO === "E-SMART"){
      arr_consumo = await datas.map(sss => {
        let {timestamp,valor} = sss
        // return [timestamp,valor]
        return [(timestamp-(OneHour*REST_HOUSR)*1000),valor]
      })
    } else {
      // MODIFICACION LIMITE PARA MEDIDOR PANTALON NORTE
      if(DATA.MEDIDOR === "PANTALON NORTE"){
        let max = DATA?.MAX
        let a = max / 5
        let b = (a*3).toFixed(0)
        // console.log("limite operacional c",b);
        LimiteOperacional_ = 94
      }else{
        // console.log("limite operacional o",DATA.MAX);
        LimiteOperacional_ = DATA.MAX
      }

      arr_consumo = await datas.map(sss => {
        let {timestamp,valor} = sss
        // return [timestamp,valor]
        return [(timestamp-(OneHour*REST_HOUSR)*1000),valor]
      })
      if(DATA.MEDIDOR==="BANCOS DE AGUA"){
        //{medidor: DATA.MEDIDOR,sensor:"m1", contrato: DATA.CONTRATO, planta: DATA.EMPRESA, pasado: Number((pasado).toFixed(0))}
        let menudencias = await LecturaEsmartOurRangeTotalizador({
          medidor: "MENUDENCIAS",sensor:"m1", contrato: "NESPRA", planta: "AGROINDUSTRIAL EL PAICO S.A.", pasado: Number((pasado).toFixed(0))
        })
        arr_consumo_2 = await menudencias.map(sss => {
          let {timestamp,valor} = sss
          // return [timestamp,valor]
          return [(timestamp-(OneHour*REST_HOUSR)*1000),Number(Number(valor*16.667).toFixed(2))]
        })
        // arr_consumo_2.shift()
        // console.log("largos=>",arr_consumo.length,arr_consumo_2.length);
        arr_consumo_final = []
        for (let rest_val = 0; rest_val < arr_consumo.length; rest_val++) {
          const rest_val_b = arr_consumo[rest_val][1];
          const rest_val_m = arr_consumo_2[rest_val][1]??0
          let resta = 0
          if(rest_val_m){
            resta = await Number(Number(rest_val_b-rest_val_m).toFixed(2))<0?0:Number(Number(rest_val_b-rest_val_m).toFixed(2))
          }
          // console.log("Resta=>",resta);
          arr_consumo_final.push([arr_consumo[rest_val][0],resta])
        }
        // console.log(arr_consumo_final);
        // console.log("BANCOS DE AGUA",arr_consumo[0],arr_consumo.length);
        // console.log("MENUDENCIAS",arr_consumo_2[0],arr_consumo_2.length);
        // let valueN = Number(Number((datas[0][DATA.SENSOR]/1000)*60).toFixed(2))
        // let M_m3 = menudencias[0]['m1']
        // let M_l = Number(Number(menudencias[0]['m1']*16.667).toFixed(2))
        // let B_m3 = valueN
        // let B_L = datas[0][DATA.SENSOR]
        // ba_rest_m3 = Number((B_m3-M_m3).toFixed(2))
        // ba_rest_l = Number((B_L-M_l).toFixed(2))
        // setBA_REST_R3(Number((B_m3-M_m3).toFixed(2)))
        // console.log("BANCOS DE AGUA",(B_m3-M_m3).toFixed(2),"M3/H")
        // console.log("BANCOS DE AGUA",(B_L-M_l).toFixed(2),"L/M")

      }
    }


    let MedioLimite = await datas.map(iss=>{
      let {timestamp} = iss
      let valor = DATA.MEDIO
      return [(timestamp-(OneHour*REST_HOUSR)*1000), valor]
    })
    // console.log("MedioLimite",MedioLimite);
    g = await [{
        name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
        data: DATA.MEDIDOR==="BANCOS DE AGUA"?arr_consumo_final:arr_consumo
      },
    ]

    let optional = {
      chart: {
        type: 'line',
        zoomType: 'x',
        height: '400px',
        resetZoomButton: {
          position: {
              // align: 'right', // by default
              // verticalAlign: 'top', // by default
              x: 0,
              y: -30,
              text: "zooom"
          }
        }
      },
      lang: {
        date: [
            'Enero', 'Février', 'Mars', 'Avril',
            'Mai', 'Juin', 'Juillet', 'Août',
            'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ]
    },
      title: {
          text: title,
          style: {
            // fontSize:'small',
            fontWeight: 'bold',
            fontFamily: 'monospace',
            color: 'black'
          }
      },
      subtitle: {
          text: 'Consumo',
          style: {
            // fontSize:'small',
            fontWeight: 'lighter',
            fontFamily: 'monospace',
            color: 'gray'
          }
      },
      xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: { // don't display the year
              month: '%e. %b',
              // year: '%b'
          },
          title: {
              text: ''
          }
      },
      legend: {
        align: 'left',
          verticalAlign: 'top',
          borderWidth: 0
      },
      yAxis: {
          title: {
            text: `Consumos ${title} por ${unidad_med}`
          },
          plotLines: [{
            color: 'red',
            width: 2,
            value: LimiteOperacional_, //*********** */
            animation: {
                duration: 1000,
                defer: 4000
            },label: {
              text: 'Limite Operacional',
              align: 'right',
              x: -130
            },
          }],
          // min: 0
      },
      tooltip: {
          formatter: function() {
            // let d = new Date()
            let d_ = new Date(this.x)
            // console.log(d_)
            return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Consumo: <b>' + FormatNumberCL(this.y) + " " + unidad_med + '</b>';
          },
          // headerFormat: '<b>{series.name}</b><br>',
          // pointFormat: '{point.x}: {point.y:.2f} m³',
          // crosshairs: true,
          // shared: true
      },
      plotOptions: {
        series: {
          lineColor: '#4100C3',
          lineWidth: 3,
          marker: {
            enabled: false,
            radius: 2
          }
        },
        
      },
  
      series: [{
        name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
        data: DATA.MEDIDOR==="BANCOS DE AGUA"?arr_consumo_final:arr_consumo
      }]
    }

    await setstate(optional)
    // await setstate({...state,["series"]:g})
  }
  const ChangeLimiteRedLine = async () => {
      
    if(DATA.MEDIDOR === "PANTALON NORTE"){
      let max = DATA?.MAX
      let a = max / 5
      let b = (a*3).toFixed(0)
      // console.log("limite operacional c",b);
      setLimiteOperacional(b)
    }else{
      // console.log("limite operacional o",DATA.MAX);
      setLimiteOperacional(DATA.MAX)
    }
  }

  useEffect(() => {
    ChangeLimiteRedLine()
    UpdateChartNow()
    setInterval(() => {
      UpdateChartNow()
    }, 1000*60*15);
  
    return () => {
     UpdateChartNow() 
    }
  }, [LimiteOperacional])
  

  const options = {
    chart: {
      type: 'line',
      zoomType: 'x',
      height: '400px',
      resetZoomButton: {
        position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -30,
            text: "zooom"
        }
      }
    },
    lang: {
      date: [
          'Enero', 'Février', 'Mars', 'Avril',
          'Mai', 'Juin', 'Juillet', 'Août',
          'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ]
  },
    title: {
        text: title,
        style: {
          // fontSize:'small',
          fontWeight: 'bold',
          fontFamily: 'monospace',
          color: 'black'
        }
    },
    subtitle: {
        text: 'Consumo',
        style: {
          // fontSize:'small',
          fontWeight: 'lighter',
          fontFamily: 'monospace',
          color: 'gray'
        }
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the year
            month: '%e. %b',
            // year: '%b'
        },
        title: {
            text: ''
        }
    },
    legend: {
      align: 'left',
        verticalAlign: 'top',
        borderWidth: 0
    },
    yAxis: {
        title: {
          text: `Consumos ${title} por ${unidad_med}`
        },
        plotLines: [{
          color: 'red',
          width: 2,
          value: DATA.MAX, //*********** */
          animation: {
              duration: 1000,
              defer: 4000
          },label: {
            text: 'Limite',
            align: 'right',
            x: -130
          },
        }],
        // min: 0
    },
    tooltip: {
        formatter: function() {
          // let d = new Date()
          let d_ = new Date(this.x)
          // console.log(d_)
          return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Consumo: <b>' + FormatNumberCL(this.y) + " " + unidad_med + '</b>';
        },
        // headerFormat: '<b>{series.name}</b><br>',
        // pointFormat: '{point.x}: {point.y:.2f} m³',
        // crosshairs: true,
        // shared: true
    },
    plotOptions: {
      series: {
        lineColor: '#4100C3',
        lineWidth: 3,
        marker: {
          enabled: false,
          radius: 2
        }
      },
      
    },

    series: [{
      name: 'Flujometro',
      data:
          [
            [Date.UTC(2023, 0,  3), 0.39],
            [Date.UTC(2023, 0,  6), 0.39],
            [Date.UTC(2023, 0,  9), 0.99],
            [Date.UTC(2023, 0, 12), 0.99],
            [Date.UTC(2023, 0, 15), 0.39],
            [Date.UTC(2023, 0, 18), 0.39]
          ]
    }]
  }

  if(IsREnder){
    return (
      <>
        <HighchartsReact
          key={DATA._id}
          highcharts={Highcharts}
          options={state||[]}
        />

      </>
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>Cargando ..</h5>
          </div>
        </div>
      </div>
    )
  }
}
