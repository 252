import React, { useContext, useEffect, useState } from "react";
import { HeaderMenu } from "../../components/HeaderMenu.comp";
import { GetListMEdidores } from "../../API/OUR_Lecturas/GetListMEdidores.api";
import LoginContext from "../../context/login_context/LoginContext";
import { OUR_LineOneTemp } from "../../components/charts/OUR_LineOneTemp.chart";
import { CubiData } from "../../components/CubiData.comp";

//CONSTANTES
let medCut = [[1001,1007],[1008,1010],[1011,1019],[1020,1022],[1023,1028],[1029,1032]]

export const Temperaturas = () => {

    const { LoginState, SetLogin, CloseLogin, GetLoginSession } = useContext(LoginContext);

    const [ListMedidores_, setListMedidores_] = useState([]);

    
    const GetListMedidores = async () => {
        let list_ = await GetListMEdidores({ empresa: LoginState.planta });

        let listFil = await list_.filter((fl) => fl.TIPO === "TEMPERATURA");
        
        // console.log(list_)
        let bulk_Data = [];
        for (let cut_M = 0; cut_M < medCut.length; cut_M++) {
            const cut_M_ = medCut[cut_M];
            // console.log(cut_M_)
            let medidor = (listFil[0].MEDIDOR).filter(flt_it => flt_it.N_MED >= cut_M_[0] && flt_it.N_MED<=cut_M_[1]);
            bulk_Data.push(medidor)
        }
        // await console.log(listFil[0].MEDIDOR);
        setListMedidores_(bulk_Data);
        // setListMedidores_oneday(fill_col_OneDay)
    };

    useEffect(() => {
        GetListMedidores();
        return () => {
          GetListMedidores();
        };
    }, []);

    return (<>
        <HeaderMenu Pag_Sel={"/v2/temperaturas"} />
        <div className="py-3"><h4>Temperaturas</h4></div>
        <div className="container-fluid mb-5">
            <div className="row">
                {ListMedidores_.map((ulx, index) => (
                    <div key={index} className="col-2">
                    {ulx.map((med, index) => (
                        <div className="col-12 p-0 m-0" key={index}>
                        {/* <p key={index}>-{med.MEDIDOR}</p> */}
                            {/* <OUR_LineOneTemp DATA={med} /> */}
                            <CubiData DATA={med} />
                        </div>
                    ))}
                    </div>
                ))}
            </div>
        </div>
    </>);
};
