import { URL_CTRL_HIDRIC } from "../../router/Url";

//saveglobalmedidores
export const SaveGlobalMedidores = ({medidorChanged}) => {
    // console.log(medidorChanged)

    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_HIDRIC+'saveglobalmedidores',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                medidores:medidorChanged
            })
        })
        let responsito = await query.json();
        console.log(responsito)

        if(responsito.success){
            resolve(responsito.success)
            alert("los medidores han sido actualizados");
        }else{
            resolve([])
        }
    })
}
