import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LoginContext from '../../context/login_context/LoginContext';
import { SaveNowDatosCaldera } from '../../API/DatosCaldera/SaveNowDatosCaldera.api';

export const DatosCaldera = () => {

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

    const [ConGasN, setConGasN] = useState('');
    const [ConGasN_bkp, setConGasN_bkp] = useState('');
    const [ConAguaCald, setConAguaCald] = useState('');
    const [ConAguaCald_bkp, setConAguaCald_bkp] = useState('');
    const [tAguaALim, settAguaALim] = useState('');
    const [tAguaALim_bkp, settAguaALim_bkp] = useState('');
    const [DurAgua, setDurAgua] = useState('');
    const [DurAgua_bkp, setDurAgua_bkp] = useState('');
    const [CondAgua, setCondAgua] = useState('');
    const [CondAgua_bkp, setCondAgua_bkp] = useState('');
    const [PresCaldera, setPresCaldera] = useState('');
    const [PresCaldera_bkp, setPresCaldera_bkp] = useState('');
    const [ConsVapor, setConsVapor] = useState('');
    const [ConsVapor_bkp, setConsVapor_bkp] = useState('');
    const [ButonState, setButonState] = useState(0);// 0=datos incompletos 1=validar 2=guardar datos
    // ConGasN, ConAguaCald, tAguaALim, DurAgua, CondAgua, PresCaldera, ConsVapor
    
    const SaveTheData = async () => {
        let timestamp = await new Date().getTime()
        // console.log(LoginState.user,timestamp, ConGasN, ConAguaCald, tAguaALim, DurAgua, CondAgua, PresCaldera, ConsVapor)
        let isValidado = confirm('Esta seguro de Guardar estos Datos?')
        if(isValidado){
            let respns = await SaveNowDatosCaldera({
                USER: LoginState.user,
                TIMESTAMP:Number(timestamp),
                CONS_GAS_NAT: Number(ConGasN),
                CON_AGUA_CALD: Number(ConAguaCald),
                TAGUALIM: Number(tAguaALim),
                DUREZA_AGUA: Number(DurAgua),
                CONDUCT_AGUA_CALD: Number(CondAgua),
                PRESION_CALDERA: Number(PresCaldera),
                CONSUMO_VAPOR: Number(ConsVapor)
            })
            if(respns){
                setConGasN('')
                setConAguaCald('')
                settAguaALim('')
                setDurAgua('')
                setCondAgua('')
                setPresCaldera('')
                setConsVapor('')
                /** bkp datos para mostrar en los helper */
                setConGasN_bkp('')
                setConAguaCald_bkp('')
                settAguaALim_bkp('')
                setDurAgua_bkp('')
                setCondAgua_bkp('')
                setPresCaldera_bkp('')
                setConsVapor_bkp('')
                alert('Los Datos se Guardaron con Exito!!')
                setButonState(0)
            }
        }else{
            alert('Los Datos NO se Guardaron!!')
        } 
    }

    const ValidacionDeDatos = async () => {
        console.log("ValidacionDeDatos")
        setConGasN_bkp(ConGasN)
        setConAguaCald_bkp(ConAguaCald)
        settAguaALim_bkp(tAguaALim)
        setDurAgua_bkp(DurAgua)
        setCondAgua_bkp(CondAgua)
        setPresCaldera_bkp(PresCaldera)
        setConsVapor_bkp(ConsVapor)

        setButonState(2)
    }

    useEffect(() => {
        if(ConGasN ==='' || ConAguaCald ==='' || tAguaALim ==='' || DurAgua ==='' || CondAgua ==='' || PresCaldera ==='' || ConsVapor ===''){
          setButonState(0)
        }else{
          setButonState(1)
        }
    }, [ConGasN,ConAguaCald,tAguaALim,DurAgua,CondAgua,PresCaldera,ConsVapor])
    

    return (
        <>
            <HeaderMenu Pag_Sel={'icaldera'} />
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 mt-3'>
                        <h5>Datos de Caldera</h5>
                    </div>
                    <div className='col-12 col-md-4 pb-3'>
                        <TextField
                            {
                                ...ConGasN_bkp!==''?{error:true}:{}
                            }
                            label="Consumo Gas Natural"
                            id="outlined-start-adornment"
                            size='small'
                            type='number'
                            fullWidth
                            value={ConGasN}
                            onChange={(e)=>setConGasN(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">m³/h</InputAdornment>,
                            }}
                            helperText={ConGasN_bkp===''?null:"Dato por Validar: "+ConGasN_bkp+"m³/h"}
                        />
                    </div>
                    <div className='col-12 col-md-4 pb-3'>
                        <TextField
                            {
                                ...ConAguaCald_bkp!==''?{error:true}:{}
                            }
                            label="Consumo Agua Caldera"
                            id="outlined-start-adornment"
                            size='small'
                            type='number'
                            fullWidth
                            value={ConAguaCald}
                            onChange={(e)=>setConAguaCald(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">m³/h</InputAdornment>,
                            }}
                            helperText={ConAguaCald_bkp===''?null:"Dato por Validar: "+ConAguaCald_bkp+"m³/h"}
                        />
                    </div>
                    <div className='col-12 col-md-4 pb-3'>
                        <TextField
                            {
                                ...tAguaALim_bkp!==''?{error:true}:{}
                            }
                            label="t° Agua de alimentacion"
                            id="outlined-start-adornment"
                            size='small'
                            type='number'
                            fullWidth
                            value={tAguaALim}
                            onChange={(e)=>settAguaALim(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">C°</InputAdornment>,
                            }}
                            helperText={tAguaALim_bkp===''?null:"Dato por Validar: "+tAguaALim_bkp+"C°"}
                        />
                    </div>
                    <div className='col-12 col-md-4 pb-3'>
                        <TextField
                            {
                                ...DurAgua_bkp!==''?{error:true}:{}
                            }
                            label="Dureza Agua Alimentacion"
                            id="outlined-start-adornment"
                            size='small'
                            type='number'
                            fullWidth
                            value={DurAgua}
                            onChange={(e)=>setDurAgua(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">ppm</InputAdornment>,
                            }}
                            helperText={DurAgua_bkp===''?null:"Dato por Validar: "+DurAgua_bkp+"ppm"}
                        />
                    </div>
                    <div className='col-12 col-md-4 pb-3'>
                        <TextField
                            {
                                ...CondAgua_bkp!==''?{error:true}:{}
                            }
                            label="Conductividad Agua Caldera"
                            id="outlined-start-adornment"
                            size='small'
                            type='number'
                            fullWidth
                            value={CondAgua}
                            onChange={(e)=>setCondAgua(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">ppm</InputAdornment>,
                            }}
                            helperText={CondAgua_bkp===''?null:"Dato por Validar: "+CondAgua_bkp+"ppm"}
                        />
                    </div>
                    <div className='col-12 col-md-4 pb-3'>
                        <TextField
                            {
                                ...PresCaldera_bkp!==''?{error:true}:{}
                            }
                            label="Presion Caldera"
                            id="outlined-start-adornment"
                            size='small'
                            type='number'
                            fullWidth
                            value={PresCaldera}
                            onChange={(e)=>setPresCaldera(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">psi</InputAdornment>,
                            }}
                            helperText={PresCaldera_bkp===''?null:"Dato por Validar: "+PresCaldera_bkp+"psi"}
                        />
                    </div>
                    <div className='col-12 col-md-4 pb-3'>
                        <TextField
                            {
                                ...ConsVapor_bkp!==''?{error:true}:{}
                            }
                            label="Consumo Vapor"
                            id="outlined-start-adornment"
                            size='small'
                            type='number'
                            fullWidth
                            value={ConsVapor}
                            onChange={(e)=>setConsVapor(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">m³/h</InputAdornment>,
                            }}
                            helperText={ConsVapor_bkp===''?null:"Dato por Validar: "+ConsVapor_bkp+"m³/h"}
                        />
                    </div>
                    <div className='col-12 p-3 text-right'>
                        {
                            ButonState===0?<button
                                className='btn btn-success'
                                disabled
                            >Complete los Datos</button>:
                                ButonState===1?<button
                                className='btn btn-success'
                                onClick={()=>ValidacionDeDatos()}
                            >Validar Datos</button>:
                            ButonState===2?
                            <button
                                className='btn btn-success'
                                onClick={()=>SaveTheData()}
                            >Guardar Datos</button>:null
                        }
                    </div>
                </div>
            </div>
        </>
  )
}
