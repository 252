import { URL_CTRL_HIDRIC } from "../router/Url";

export const UpdateDataColumnCalculated = ({CHART, ELEMENTO, N_MED}) => {
	// console.log("calculado",CHART, ELEMENTO, N_MED)
	return new Promise(async (resolve, reject) => {
		let query = await fetch(URL_CTRL_HIDRIC+'/getdatamedidorcalculated', {
			method: 'POST',
			headers: {
				'authorization': "paico2021",
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
      body: JSON.stringify({
        CHART, ELEMENTO, N_MED
      })
		})
		let responsito = await query.json();
		// console.log("===>se ejecuto ",responsito)//,responsito)
		if (responsito.length) {
			resolve(responsito)
		}else{
			resolve(responsito)
		}
	})
}
