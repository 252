import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const SaveDataFactura = ({user, correo, RutPDS, RasSoc, TipeConcume, File_, Valor, Fecha, ValorConsumo, KGFaenados, KGTerminados, NAves, planta}) => {
    // console.log({user, correo, RasSoc, TipeConcume, File_, Valor, Fecha});
    const formData = new FormData();
    formData.append('EMPRESA',planta);
    formData.append('USER',user);
    formData.append('MAIL',correo);
    formData.append('RACSOC',RasSoc);
    formData.append('RUT',RutPDS);
    formData.append('TIPECONSUME',TipeConcume);
    formData.append('IMAGENES',File_);
    formData.append('FECHA',Fecha);
    formData.append('VALOR',Valor);
    formData.append('CONSUMO',ValorConsumo);
    formData.append('KGFAENADOS',KGFaenados);
    formData.append('KGTERMINADOS',KGTerminados);
    formData.append('N_AVES',NAves);
    
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'savedatafactura',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json'
          },
          body: formData
        })
        let responsito = await query.json();
        // console.log(responsito.success)
        if(responsito.success){
          resolve(responsito.success)
        }else{
          resolve([])
        }
    })
}
