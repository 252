import React from 'react'
import { URL_SRV_ARIZTIA } from '../router/Url';

export const CheckForUpdates = async ({APP}) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = await fetch(URL_SRV_ARIZTIA+'ssggmonitor_actualiza',{
        method: 'POST',
        headers: {
          'authorization': "paico2021",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          APLICACION:APP
        })
      })
      let responsito = await query.json();
      // console.log(responsito.body)
      if(responsito){
        resolve(responsito.body[0])
      }else{
        resolve([])
      }
    } catch (error) {
      return null
    }
   
  }).catch(alert)
}
