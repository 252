import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import PropaneIcon from '@mui/icons-material/Propane';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { styled } from '@mui/material/styles';
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation';
import LoginContext from '../../context/login_context/LoginContext';
import { SaveDataFactura } from '../../API/IngresoFactura/SaveDataFactura.pai';
import { ModalLoad } from '../../components/ModalLoad.comp';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const FacturerConsume = () => {

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // console.log(LoginState);
    const [RutPDS, setRutPDS] = useState('');
    const [RasSoc, setRasSoc] = useState('');
    const [TipeConcume, setTipeConcume] = useState('');
    const [File_, setFile_] = useState([]);
    const [Valor, setValor] = useState('');
    const [Fecha, setFecha] = useState('');
    const [ValorConsumo, setValorConsumo] = useState('');
    const [KGFaenados, setKGFaenados] = useState('');
    const [KGTerminados, setKGTerminados] = useState('');
    const [NAves, setNAves] = useState('');

    const [IsCompleteF, setIsCompleteF] = useState(true);
    const [IsLoaded, setIsLoaded] = useState(false);
    const [Texto, setTexto] = useState('Cargando');

    const [IsShowHiddenFaena, setIsShowHiddenFaena] = useState(false);

    const AddFile = async (e) => {
        // await setFile_([]);
        // console.log(e.target.files)
        // setArchivo(e.target.files[0]);
        // setFile_(e.target.value.split('\\').pop());
        await setFile_(e.target.files[0]);
    }

    const SaveTheDataNow = async () => {
        setTexto('Cargando datos y documento. Espere por favor.')
        setIsLoaded(true);

        let user = LoginState.user;
        let correo = LoginState.rol;
        let planta = LoginState.planta;
        let rspon = await SaveDataFactura({user, correo, RutPDS, RasSoc, TipeConcume, File_, Valor, Fecha, ValorConsumo, KGFaenados, KGTerminados, NAves, planta})
        if(rspon) {
            setRutPDS('')
            setRasSoc('')
            setTipeConcume('')
            setFile_([])
            setValor('')
            setFecha('')
            setValorConsumo('')
            setKGFaenados('')
            setKGTerminados('')
            setNAves('')
            // alert('Los datos fueron cargados con exito!')
        }
        setIsLoaded(false)
    }

    const RutFormater = async (rut) => {
        let ru_ = ''
        ru_ = rut.split('-').join('')
        if(ru_.length < 10){
            if(ru_.length > 7){
                let p1 = ru_.substring(0,ru_.length-1)
                let p2 = ru_.substring(ru_.length-1,ru_.length)
                setRutPDS(p1+'-'+p2.toUpperCase())
            }else{
                setRutPDS(rut)
            }
        }
    }

    const ShowAndHiddenFaenaData = async () => {
        await setIsShowHiddenFaena(!IsShowHiddenFaena)
    }

    useEffect(() => {
        if(RutPDS === '' || RasSoc === '' || TipeConcume === '' || File_ === undefined || File_?.length === 0 || Valor === '' ||
         ValorConsumo === '' || Fecha === ''){
            setIsCompleteF(true)
        }else{
            setIsCompleteF(false)
        }
        return () => {
        }
    }, [RutPDS,RasSoc,TipeConcume,File_,Valor,ValorConsumo,KGFaenados,KGTerminados,NAves,Fecha])
    
    return (
        <>
            <HeaderMenu Pag_Sel={'ifactura'}/>
            {/* <div>FacturerConsume</div> */}
            <div className='container mb-3'>
                <div className='row'>
                    <div className='col-12 mt-3'>
                        <h3 className='display-4'>Facturas</h3>
                    </div>
                    <div className='col-12 col-md-4 pt-3'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            size='small'
                            label="Rut Prestador Servicio"
                            variant="outlined"
                            value={RutPDS}
                            // onChange={(e)=>setRutPDS(e.target.value)}RutFormater
                            onChange={(e)=>RutFormater(e.target.value)}
                            helperText={""}
                        />
                    </div>
                    <div className='col-12 col-md-4 pt-3'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            size='small'
                            label="Razon Social"
                            variant="outlined"
                            value={RasSoc}
                            onChange={(e)=>setRasSoc(e.target.value)}
                            helperText={""}
                        />
                    </div>
                    <div className='col-12 col-md-4 py-3'>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label">Tipo Energetico</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Tipo Energetico"
                                value={TipeConcume}
                                onChange={(e)=>setTipeConcume(e.target.value)}
                            >
                                <MenuItem value={"ELECTRICO"}><ElectricBoltIcon htmlColor='orange' /> Eléctrico</MenuItem>
                                <MenuItem value={"HIDRICO"}><WaterDropIcon htmlColor='skyblue' /> Hídrico</MenuItem>
                                <MenuItem value={"GASNATURAL"}><PropaneTankIcon htmlColor='green'/> Gas Natural</MenuItem>
                                <MenuItem value={"GASLICUADO"}><PropaneIcon htmlColor='orange'/> Gas Licuado</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-12 col-md-4 py-3'>
                        <Button
                            color='secondary'
                            fullWidth
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                            onChange={(e)=>AddFile(e)}
                            >
                            Cargar Factura
                            <VisuallyHiddenInput type="file"/>
                        </Button>
                    </div>
                    <div className='col-12 col-md-4 py-3'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            type='number'
                            size='small'
                            label="Valor Factura sin IVA"
                            value={Valor}
                            onChange={(e)=>setValor(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            variant="outlined" />
                    </div>
                    <div className='col-12 col-md-4 py-3'>
                        <TextField
                            fullWidth
                            id="outlined-basic"
                            type='number'
                            size='small'
                            label="Valor Consumido"
                            value={ValorConsumo}
                            onChange={(e)=>setValorConsumo(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">
                                    {
                                        TipeConcume==="ELECTRICO"?"KW":TipeConcume==="HIDRICO"||TipeConcume==="GASNATURAL"?"M³":"LTS"
                                    }
                                </InputAdornment>,
                            }}
                            variant="outlined" />
                    </div>
                    <div className='col-12 col-md-4 py-3'>
                        <input type='month' value={Fecha} onChange={(e)=>setFecha(e.target.value)} className='input-fecha'/>
                        <span style={{position: 'absolute', left: 25, top: 4, paddingInline: 4, fontSize: "small", backgroundColor: 'white'}}>Seleccione mes</span>
                    </div>
                    
                    <div className='col-12 text-center'>
                        <span onClick={()=>ShowAndHiddenFaenaData()}>Agregar Datos De Faena {
                            IsShowHiddenFaena?
                            <ExpandLessIcon fontSize='large' color='error'/>
                            :<ExpandMoreIcon fontSize='large' color='success'/>
                            }</span>
                    </div>
                    <div className={IsShowHiddenFaena?'col-12 show___':'hidden___'}>
                        <div className='row '>
                            <div className='col-12 col-md-4 py-3'>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    type='number'
                                    size='small'
                                    label="KG Faenados"
                                    value={KGFaenados}
                                    onChange={(e)=>setKGFaenados(e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">KG</InputAdornment>,
                                    }}
                                    variant="outlined" />
                            </div>
                            <div className='col-12 col-md-4 py-3'>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    type='number'
                                    size='small'
                                    label="KG Terminados"
                                    value={KGTerminados}
                                    onChange={(e)=>setKGTerminados(e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">KG</InputAdornment>,
                                    }}
                                    variant="outlined" />
                            </div>
                            <div className='col-12 col-md-4 py-3'>
                                <TextField
                                    fullWidth
                                    id="outlined-basic"
                                    type='number'
                                    size='small'
                                    label="N° Aves"
                                    value={NAves}
                                    onChange={(e)=>setNAves(e.target.value)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">UNIDADES</InputAdornment>,
                                    }}
                                    variant="outlined" />
                            </div>
                        </div>
                    </div>

                    

                    <div className='col-12 pt-3 py-2 text-right'>
                        <Button
                            variant="contained"
                            disabled={IsCompleteF}
                            onClick={()=>SaveTheDataNow()}
                        >Cargar Datos</Button>
                    </div>

                </div>
            </div>
            {
                IsLoaded?<ModalLoad texto={Texto} />:null
            }
        </>
    )
}
