import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GetDataUser } from '../../API/ALERTA/GetDataUser.api';
import { Alert, Checkbox,} from '@mui/material';
import { GetListMEdidores } from '../../API/OUR_Lecturas/GetListMEdidores.api';
import "./alerta.css"

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SaveSuscripcion } from '../../API/ALERTA/SaveSuscripcion.api';

export const Alertas = () => {

    const [user, setUser] = useState([]);
    
    const [loader, setLoader] = useState(1);//1:cargando,2:usurio encontrado,3:usuario no encontrado

    let params = useParams();
    // console.log(params.token)
    // console.log("alertaaaaaaaaa")

    useEffect(() => {
        const getUser = async () => {
            let user = await GetDataUser({token:params.token})
            if(user.success){
                // console.log(user.body[0])
                setUser(user.body[0])
                setLoader(2)
            }else{
                setUser([])
                setLoader(3)
            }
        }
        getUser()
        return () => {
            getUser()
        }
    }, [])

    switch (loader) {
        case 1:
            return <Cargando/>
        case 2:
            return <UserFind user={user}/>
        case 3:
            return <UsuarioNotFound />
    }
}

// muestra un mensaje de usuario no encontrado
const UsuarioNotFound = () => {
    return (
        <div className='container pt-5'>
            <div className='row mt-5'>
                <div className='col text-center'>
                    <h1>No encontramos tus datos 🙄</h1>
                </div>
            </div>
        </div>
    )
}

// muestra un mensaje de cargando
const Cargando = () => {
    return (
        <div className='container mt-5'>
            <div className='row pt-5'>
                <div className='col text-center'>
                    <h1>Cargando ...</h1>
                </div>
            </div>
        </div>
    )
}

// si el token concide con el almacenado en la base de datos, se le permite al usuario seleccionar los medidores
const UserFind = ({user}) => {
    // console.log(user)
    const [listMedidores, setListMedidores] = useState([]);//almacena los medidores de la planta
    const [selectedMed, setSelectedMed] = useState([]);//almacena los medidores seleccionados por el usuario
    const [selectedMedBKP, setSelectedMedBKP] = useState(user.MEDIDORES);//carga los medidores seleccionados por el usuario
    const [isOk, setIsOk] = useState(false);//carga los medidores seleccionados por el usuario

    useEffect(() => {
        // console.log("UserFind",user)
        const getMedidores = async () => {
          let medidores = await GetListMEdidores({empresa: user.PLANTA})
        //   console.log(medidores)
          setListMedidores(medidores)
        }
        getMedidores()
        return () => {
            getMedidores()
        }
    }, [])

    const desplegar = (id) => {
        let element = document.getElementById(id)
        
        if(element.style.display === 'none'){
            element.style.display = 'block'
        }else{
            element.style.display = 'none'
        }
    }

    const addMedidor = (medidor) => {
        console.log(medidor.ELEMENTO, medidor.MEDIDOR)
        if(selectedMed.length === 0){
            setSelectedMed([medidor])
        }else{
            let index = selectedMed.findIndex(x => x.MEDIDOR === medidor.MEDIDOR)
            if(index === -1){
                setSelectedMed([...selectedMed, medidor])
            }else{
                let newMedidores = selectedMed.filter(x => x.MEDIDOR !== medidor.MEDIDOR)
                setSelectedMed(newMedidores)
            }
        }
    }

    const saveMedidorsInUser = async () => {
        // console.log(selectedMed)
        const savelo = await SaveSuscripcion({medidores:selectedMed, token: user.TKN})
        if(savelo.success){
            // console.log("ok")
            setIsOk(true)
        }
    }

    useEffect(() => {
      setSelectedMed(selectedMedBKP)
    //   console.log(selectedMedBKP)
    }, [selectedMedBKP])
    
    
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 text-center mb-3' style={{backgroundColor: "#d00404", height:50, color: "#ffff00"}}>
                    <div className="ocean_2">
                        <div className="wave_2"></div>
                        <div className="wave_2"></div>
                        <div className="wave_2"></div>
                    </div>
                    <div className="col-12">
                        <p className='float-right'>{user?.NOMBRE}</p>
                        <h4 className='h5'>Seleccion de Medidores</h4>
                    </div>
                </div>

                <div className='col'>
                    <div className='row'>
                        {
                            listMedidores.map((medidor, index) => (
                                <div key={index} className='col-12 col-md-4 mb-2'>
                                    <div className='row m-1 card-alerta'>
                                        <div className='col-12 text-center' style={{cursor: 'pointer'}} onClick={()=>desplegar(medidor.TIPO)} >
                                            <h5 className='h6'>{medidor.TIPO}</h5>
                                            <span className='flotante-r'><ExpandMoreIcon color='secondary'/></span>
                                            <span key={index+2} className='flotante-l'>
                                                {
                                                    selectedMed.length != 0 ?selectedMed.filter(x => x.ELEMENTO === medidor.TIPO).length : selectedMed.length
                                                }
                                            </span>
                                        </div>
                                        <div className='col-12 fadeOut text-truncate' style={{display:"none"}} id={medidor.TIPO}>
                                            {
                                                medidor.MEDIDOR.map((med, index) => (<>
                                                    <Checkbox
                                                        checked={selectedMed.length != 0 ?selectedMed.findIndex(x => x.MEDIDOR === med.MEDIDOR) !== -1:false}
                                                        size='small'
                                                        onChange={()=>addMedidor(med)}
                                                        key={med._id}/>
                                                            <small >{med.MEDIDOR}</small>
                                                            <br/>
                                                </>
                                                ))
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='col-12 text-center'>
                    <button
                        className='btn btn-success btn-sm'
                        disabled={selectedMed.length != 0 ?false:true}
                        onClick={
                            ()=>saveMedidorsInUser()
                        }>
                        {
                            selectedMed.length != 0 ? "Guardar Medidores":"Seleccione Medidores"
                        }
                        </button>
                </div>
                {
                    isOk ? <div className='alertafloat'>
                        <Alert variant="filled" severity="success" onClose={() => setIsOk(false)}>
                            <span className=''>Los medidores fueron fueron guardados correctamente!</span>
                        </Alert>
                    </div>:null
                }
            </div>
        </div>
    )
}