import React from 'react'
import { URL_CTRL_HIDRIC } from '../../router/Url'

export const GetLecturaActual = ({DATA}) => {
// console.log(DATA.EMPRESA, DATA.SENSOR, DATA.MEDIDOR)
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_HIDRIC+'our_lecturas_actual_ariztia',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                medidor: DATA.MEDIDOR,
                sensor: DATA.SENSOR,
                planta: DATA.EMPRESA,
            })
        })
        let responsito = await query.json();
        // console.log(DATA.MEDIDOR,responsito)

        if(responsito.success){
            resolve([{valor: responsito.body.caudal, timestamp: responsito.body.timestamp}])
        }else{
            resolve([])
        }
    })
}
