import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import { ListMedidores } from '../../API/ListMedidores.api'
import LoginContext from '../../context/login_context/LoginContext'
import { LineFiveTemp } from '../../components/charts/LineFiveTemp.chart'
import { LineOneDay } from '../../components/charts/MonitorFijo/LineOneDay.chart'
import { LineOneDayTemp } from '../../components/charts/LineOneDayTemp.chart'
import { GetListMEdidores } from '../../API/OUR_Lecturas/GetListMEdidores.api'
import { OUR_LineOneTemp } from '../../components/charts/OUR_LineOneTemp.chart'

let ELEMENTO = "TEMPERATURA"
let STATE = true

export const ChartTemperatura = () => {

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

    const [ListMedidores_, setListMedidores_] = useState([])

    const GetTemperaturas = async () => {
        let list_ = await GetListMEdidores({ empresa: LoginState.planta });
        let listFil = await list_.filter(fl => fl.TIPO === "TEMPERATURA")
        // console.log(listFil[0].MEDIDOR)
        // let list_ = await ListMedidores({empresa:LoginState.planta})
        // let listFil = await list_["ai"]?.filter(fl => fl.ELEMENTO === ELEMENTO)
        // console.log(listFil)
        setListMedidores_(listFil[0].MEDIDOR);
    }

    useEffect(() => {
        GetTemperaturas()
        // setInterval(() => {
        //     GetTemperaturas()
        // }, 1000*5);    
        return () => {
            
        }
    }, [])
    
    return (
        <>
            <HeaderMenu Pag_Sel={'temperaturas'} />
            <div className='container-fluid' >
                <div className='row'>
                    {
                        ListMedidores_.map(grtmp=><div key={grtmp._id} className='col-12'><OUR_LineOneTemp DATA={grtmp} /></div>)
                    }
                </div>
            </div>
        </>
    )
}
