import React from 'react'
import { URL_CTRL_HIDRIC } from '../../router/Url'

export const GetLecturaActual_M2 = ({DATA}) => {
// console.log(DATA.EMPRESA, DATA.SENSOR, new RegExp(String(DATA.MEDIDOR).substring(2)))
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_HIDRIC+'our_lecturas_actual_ariztia',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                medidor: String(DATA.MEDIDOR).substring(2).toLocaleUpperCase(),
                sensor: "m2",
                planta: DATA.EMPRESA,
            })
        })
        let responsito = await query.json();
        // console.log(responsito)

        if(responsito.success){
            resolve(responsito.body)
        }else{
            resolve([])
        }
    })
}
