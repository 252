import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const SaveSuscripcion = ({medidores, token}) => {
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'savemedidorestouser',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                medidores, token
            })
        })
        let responsito = await query.json();
        // console.log(responsito)

        if(responsito.success){
            resolve(responsito)
        }else{
            resolve(responsito)
        }
    })
}
