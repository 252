import React, { useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import { MenuItem, TextField } from '@mui/material'
import AsyncSelect from 'react-select/async';
import { GetListProducts } from '../../API/GET/GetListProducts.api';
import './index.css'

const currencies = [
  {
    value: 'IQF 1',
    label: 'IQF 1',
  },
  {
    value: 'IQF 2',
    label: 'IQF 2',
  },
  {
    value: 'IQF 4',
    label: 'IQF 4',
  },
];


export const IngresoTemp = () => {
	//stados y mutadores de estado
	const [ModifiData, setModifiData] = useState(0);

	const [Maquina, setMaquina] = useState('')
	const [CodProdList, setCodProdList] = useState([])
	const [CodProdList_full, setCodProdList_full] = useState([])
	const [Producto, setProducto] = useState('')

  	const [NOF, setNOF] = useState('');
	const [TSuccion, setTSuccion] = useState('');
	const [TAire, setTAire] = useState('');
	const [TAntes, setTAntes] = useState('');
	const [TDespues, setTDespues] = useState('');
	const [Residencia, setResidencia] = useState('');
	const [Marinado, setMarinado] = useState('');
	const [Kghora, setKghora] = useState('');
	const [TGlaceado, setTGlaceado] = useState('');
	const [TProducto, setTProducto] = useState('');
	const [TCamara, setTCamara] = useState('');

	const [IsCompleteALL, setIsCompleteALL] = useState(false);

	// metodos y funciones
	const GetListaDeProductos = async () => {
		let ListProduct = await GetListProducts()
		let listPro_1 = await ListProduct.map(mp=>{
			let {COD_PRODUCTO} = mp
			return COD_PRODUCTO
		})
		setCodProdList(listPro_1);
		setCodProdList_full(ListProduct);
		// console.log(ListProduct);
	}

	const VerifiIsAll = async () => {
    if(Maquina === "" || Producto === "" || NOF === ""){
      setIsCompleteALL(true);
    }else{
      setIsCompleteALL(false);
    }
    // console.log( 'Cantidad ded datos en BD local',DataMedidor.length,' v/s ',acum)
  }


	const FindCodProd = async (inpv) => {
		// console.log(inpv);
		return new Promise(async(resolve, reject) => {
			let fltr = await CodProdList.filter(itm => String(itm).match(inpv))
			// console.log(fltr);
			let resultado = await fltr.map(mpp => {
				return { value: mpp, label: mpp}
			})
			resolve(resultado)
		})
	}

	const HandleChangeOfValueProducto = async (data) => {
		console.log(data);
		if(data){
			let fintItem = await CodProdList_full.filter(itm=>itm.COD_PRODUCTO===data.label)
			setProducto(fintItem[0].PRODUCTO);
		}
	}

	 /** envia los datos a guardar en el servidor */
	 const HandleSaveData = async () => {
    let resp = await SaveItemInLDB({
      ModifiData,
      setModifiData,
      data:{
        codproducto: Producto,
        producto: NombreProducto,
        maquina: Maquina,
        t_aire: TAire,
        t_succion: TSuccion,
        empresa: empresa,
        operario: DataUserStart?.nombre,
        fecha: Date.now().toString(),
        numOF: NOF.trim(),
        residencia: Residencia.trim(),
        tmarinado: Marinado.trim(),
        kghora: Kghora.trim(),
        tglaseado: TGlaceado.trim(),
        tproducto: TProducto.trim(),
        tcamara: TCamara.trim(),
        tentrada: TAntes.trim(),
        tsalida: TDespues.trim(),
      }
    })
  }

	// lanzadores
	useEffect(() => {
		GetListaDeProductos()
		return () => {
			GetListaDeProductos()
		}
	}, [])

	useEffect(() => {
		VerifiIsAll()
	
		return () => {
			VerifiIsAll()
		}
	}, [Maquina, Producto, NOF])
	

	useEffect(() => {
    console.log('los datos fueron modificados');

    setMaquina('')
    setProducto('')
    setTAire('')
    setTSuccion('')
    setNOF('')
    setResidencia('')
    setMarinado('')
    setKghora('')
    setTGlaceado('')
    setTProducto('')
    setTCamara('')
    setTAntes('')
    setTDespues('')
    return () => {
      
    }
  }, [ModifiData])
	
	
  return (
    <>
      <HeaderMenu Pag_Sel={'ingreso_temp'} />
			<div className='container mt-3'>
				<div className='row'>
					<div className='col-12 col-md-3 my-2'>
						<TextField
							fullWidth
							size='small'
							id="outlined-select-currency"
							select
							label="Seleccione Maquina"
							value={Maquina}
							onChange={(e)=>setMaquina(e.target.value)}
						>
							{currencies.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</div>
					<div className='col-12 col-md-3 my-2'>
						<TextField id="outlined-basic-1" fullWidth label="Numero OF" size='small' value={NOF} onChange={(e)=>setNOF(e.target.value)} variant="outlined" />
					</div>
					<div className='col-12 col-md-3 my-2'>
						<AsyncSelect
							classNamePrefix='select-mum'
							isClearable
							isSearchable
							placeholder={'Codigo Producto'}
							onChange={(e)=>HandleChangeOfValueProducto(e)}
							loadOptions={FindCodProd}
						/>
					</div>
					<div className='col-12 col-md-3 my-2'>
						<TextField id="outlined-basic-2" disabled focused fullWidth label="Producto" size='small' value={Producto} variant="outlined" />
					</div>
					
					{
						Maquina==="IQF 4"?<>
							<div className='col-6 col-md-3 my-2'>
								<TextField id="outlined-basic-3" type='number' fullWidth label="Antes" size='small' value={TAntes} onChange={(e)=>setTAntes(e.target.value)} variant="outlined" />
							</div>
							<div className='col-6 col-md-3 my-2'>
								<TextField id="outlined-basic-4" type='number' fullWidth label="Despues" size='small' value={TDespues} onChange={(e)=>setTDespues(e.target.value)} variant="outlined" />
							</div>
						</>:<>
							<div className='col-6 col-md-3 my-2'>
								<TextField id="outlined-basic-5" type='number' fullWidth label="Succion (°C)(NH3)" size='small' value={TSuccion} onChange={(e)=>setTSuccion(e.target.value)} variant="outlined" />
							</div>
							<div className='col-6 col-md-3 my-2'>
								<TextField id="outlined-basic-6" type='number' fullWidth label="Aire" size='small' value={TAire} onChange={(e)=>setTAire(e.target.value)} variant="outlined" />
							</div>
						</>
						
					}
					
					<div className='col-6 col-md-3 my-2'>
						<TextField id="outlined-basic-7" type='number' fullWidth label="Residencia" size='small' value={Residencia} onChange={(e)=>setResidencia(e.target.value)} variant="outlined" />
					</div>
					<div className='col-6 col-md-3 my-2'>
						<TextField id="outlined-basic-8" type='number' fullWidth label="Marinado(%)" size='small' value={Marinado} onChange={(e)=>setMarinado(e.target.value)} variant="outlined" />
					</div>
					<div className='col-6 col-md-3 my-2'>
						<TextField id="outlined-basic-9" type='number' fullWidth label="Kilo/Hora(Kg/H)" size='small' value={Kghora} onChange={(e)=>setKghora(e.target.value)} variant="outlined" />
					</div>
					<div className='col-6 col-md-3 my-2'>
						<TextField id="outlined-basic-10" type='number' fullWidth label="Agua(Glaseado)(°C)" size='small' value={TGlaceado} onChange={(e)=>setTGlaceado(e.target.value)} variant="outlined" />
					</div>
					<div className='col-6 col-md-3 my-2'>
						<TextField id="outlined-basic-11" type='number' fullWidth label="Producto(°C)" size='small' value={TProducto} onChange={(e)=>setTProducto(e.target.value)} variant="outlined" />
					</div>
					<div className='col-6 col-md-3 my-2'>
						<TextField id="outlined-basic-12" type='number' fullWidth label="Camara(°C)" size='small' value={TCamara} onChange={(e)=>setTCamara(e.target.value)} variant="outlined" />
					</div>
					<div className='col-12 my-2'>
						<button disabled={IsCompleteALL} className='btn btn-sm btn-info' onClick={()=>HandleSaveData()}>Guardar los Datos</button>
					</div>
				</div>
			</div>
    </>
  )
}
