import React, { Fragment, useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, useTheme } from '@mui/material'
import { ListMedidores } from '../../API/ListMedidores.api';
import { LineTwo } from '../../components/charts/LineTwo.chart';
import LoginContext from '../../context/login_context/LoginContext';

import DeleteIcon from '@mui/icons-material/Delete';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { AiOutlineSetting } from "react-icons/ai";
import { BsArrowsFullscreen } from "react-icons/bs";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const ChartGrgid = () => {
  var mousePosition;
  var offset = [0,0];
  var isDown = false;

  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  // console.log(LoginState.planta)
  const theme = useTheme();

  const [type_shart, settype_shart] = useState("");
  const [type_elemento, setType_elemento] = useState("");
  const [isSelectedMedList, setisSelectedMedList] = useState([]);
  const [isModalGrid, setisModalGrid] = useState(false);

  const [MedidorSelect, setMedidorSelect] = useState([]);
  const [List_Medidores, setSelect_med_list] = useState([])
  const [List_MedidoresFilter, setSelect_med_listFilter] = useState([])

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const launchFullScreen = async (id) => {
    let container = document.getElementById(id)
    // console.log("full screen")
    if(container.requestFullScreen) {
      container.requestFullScreen();
    } else if(container.mozRequestFullScreen) {
      container.mozRequestFullScreen();
    } else if(container.webkitRequestFullScreen) {
      container.webkitRequestFullScreen();
    }
  }

  const AddMeddidorToList = async () => {
    setMedidorSelect([...MedidorSelect,{CHART:type_shart,ELEMENTO:type_elemento,MEDIDOR:isSelectedMedList}])
    // console.log("Contador de medidores", MedidorSelect.length)
    
    setisSelectedMedList([])
    setType_elemento("")
    settype_shart("")
  }

  const RmMeddidorToList = async (index) => {
    let inxBulk = []
    for (let inx = 0; inx < MedidorSelect.length; inx++) {
      const inx_ = MedidorSelect[inx];
      if(index !== inx){
        inxBulk.push(inx_)        
      }
    }
    // console.log(inxBulk)
    await setMedidorSelect(inxBulk)
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setisSelectedMedList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const GetListOfMedidoresNespra = async () => {
    let mediList = await ListMedidores({empresa:LoginState.planta})
    // console.log(mediList)
    let parList = await mediList["m2"].map(adf =>{
      let {_id,MEDIDOR,TIPO,ELEMENTO} = adf
      return {_id,MEDIDOR,TIPO,ELEMENTO}
    })
    // console.log(parList)
    setSelect_med_list(parList)
  }

  const CargarMedidoresCalculados = async () => {
    console.log("Cargar los graficos")
    
  }

  // const MoveDiv = async (div_) => {
  //   // , position: 'absolute'
  //   let row = document.getElementById('row')
  //   console.log("row=>",row.offsetTop)

  //   var div = document.getElementById(div_);
  //   div.addEventListener('mousedown',function(e) {			
  //     div.style.left = e.pageX+"px";
  //     div.style.top = e.pageY+"px";
  //     div.style.position = 'absolute'
  //     isDown = true

  //     div.addEventListener('mousemove', function(event) {
  //       console.log(event.clientX,event.clientY,div.clientHeight, div.clientWidth)
  //       event.preventDefault();
  //       if (isDown) {
  //         mousePosition = {
  //           x : event.clientX-(div.clientWidth/2),
  //           y : event.clientY-(div.clientHeight/2)
  //         };
  //         div.style.left = (mousePosition.x + offset[0]) + 'px';
  //         div.style.top  = (mousePosition.y + offset[1]) + 'px';
  //       }
  //     }, true);
  //   });
    
  //   div.addEventListener('mouseup', function() {
  //     isDown = false;
  //   }, true);

  // }

  const watchPorte = async (id) => {
    let div = document.getElementById(id)
    let row = document.getElementById('row')
    console.log(row.clientX,row.clientY,div.clientHeight, div.clientWidth)
  }

  const Filtrar_medidores = async (med) => {
    if(med === "ALL"){
      setType_elemento("TODO")
      setSelect_med_listFilter(List_Medidores)
    }else{
      setType_elemento(med)
      let filtro = await List_Medidores.filter(fl => fl.ELEMENTO === med)
      await setSelect_med_listFilter(filtro)
    }
    // console.log(med, filtro)
  }

  // console.log(Math.floor(new Date().getTime()/1000.0)) //El método getTime devuelve el tiempo en milisegundos

  const GetMedCalcualtedFromStorage = async () => {
    let a = await localStorage.getItem("CalculeMed")
    let b = await JSON.parse(a)
    // console.log(b)
    if(b) setMedidorSelect(b)
    else setMedidorSelect([])
  }
  
  const CloseMdalAndDesencadenar = async () => {
    setisModalGrid(!isModalGrid)
    // CargarMedidoresCalculados()
    // GetListOfMedidoresNespra()
  }

  useEffect(() => {
    GetListOfMedidoresNespra()
    setSelect_med_listFilter(List_Medidores)
    GetMedCalcualtedFromStorage()
    // if(MedidorSelect.length <= 1){
    //   console.log("numero:",MedidorSelect.length)
    //   CargarMedidoresCalculados()
    // }
    return () => {
      GetListOfMedidoresNespra()
    }
  }, [])

  useEffect(() => {
    // console.log(MedidorSelect)
    localStorage.setItem("CalculeMed",JSON.stringify(MedidorSelect))
    // if(MedidorSelect.length >= 1){
    //   // console.log("numero:",MedidorSelect.length)
    //   CargarMedidoresCalculados()
    // }
    return () => {
    }
  }, [MedidorSelect])
  
  return (
    <>
      <HeaderMenu Pag_Sel={'calchart'} />
      <div className=''>
        <div className=''>
          <span className='btn-activo-2' onClick={()=>setisModalGrid(!isModalGrid)}><AiOutlineSetting /></span>
        </div>
      </div>
      <div className='container-fluid my-5'>
        <div className='row' id='row'>
          {
            MedidorSelect?.map((mp, index) => (
              <div key={index} className='col-12 col-md-6' style={{height: '430px'}} id={mp.ELEMENTO+index} >
                {/* <small>GRAFICO {mp.ELEMENTO+" "+mp.CHART}</small> */}
                <span className='btn-activo' onClick={()=>launchFullScreen(mp.ELEMENTO+index)}><BsArrowsFullscreen /></span>
                <LineTwo  DATA={mp} />
              </div>
            ))
          }
          
        </div>
      </div>

      {/* modal grid */}
      {
        isModalGrid?<div className='menu_grid'>
        <div className='row row-grid' style={{width:'98%', overflowY: 'scroll'}}>
          <div className='col-12 col-md-3 py-2'>
            <legend style={{color: 'transparent'}}><small>s</small></legend>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-simple-select-label" size='small' style={{fontSize: "smaller"}} >Tipo de Grafico</InputLabel>
              <Select
                style={{fontSize: "smaller"}} 
                size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type_shart}
                // name='Medidor'
                label="Tipo de Grafico"
                onChange={(e)=>settype_shart(e.target.value)}
              >
                {/* <MenuItem style={{fontSize: "smaller"}} value={"SIMPLE"}>GRAFICO SIMPLE</MenuItem> */}
                <MenuItem style={{fontSize: "smaller"}} value={"SUMADO"}>GRAFICO SUMADO</MenuItem>
                <MenuItem style={{fontSize: "smaller"}} value={"AGRUPADO"}>GRAFICO AGRUPADO</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className='col-12 col-md-2 py-2 text-center'>
            <legend><small>Tipo de Medidor</small></legend>
            <button className='btn btn-warning btn-sm mx-1 w-25' onClick={()=>Filtrar_medidores("ELECTRICO")}>⚡</button>
            <button className='btn btn-info btn-sm mx-1 w-25' onClick={()=>Filtrar_medidores("HIDRICO")}>💧</button>
            {/* <button className='btn btn-success btn-sm mx-1 w-25' onClick={()=>Filtrar_medidores("ALL")}>💯</button> */}
          </div>
          <div className='col-12 col-md-5 py-2'>
            <legend style={{color: 'transparent'}}><small>s</small></legend>
              <FormControl fullWidth size='small'>
                <InputLabel id="demo-multiple-checkbox-label" style={{fontSize: "smaller"}} >Seleccione Medidor(es)</InputLabel>
                <Select
                  style={{fontSize: "smaller"}} 
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple={type_shart==="SIMPLE"?false:true}
                  value={isSelectedMedList}
                  onChange={handleChange}
                  input={<OutlinedInput label="Seleccione Medidor(es)" />}
                  // renderValue={(selected) => selected.join(', ')}
                  // renderValue={(selected) => (
                  //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  //     {selected.map((value) => (
                  //       <Chip key={value} label={value} />
                  //     ))}
                  //   </Box>
                  // )}
                  MenuProps={MenuProps}
                >
                  {List_MedidoresFilter.map((name) => (
                    <MenuItem key={name.MEDIDOR} value={name.MEDIDOR} color='#000000' style={{fontSize: "smaller"}} >
                      {/* <Checkbox checked={isSelectedMedList.indexOf(name.MEDIDOR) > -1} />
                      <ListItemText primary={name.MEDIDOR} /> */}
                      {name.ELEMENTO==="ELECTRICO"? " ⚡" : " 💧"} {name.MEDIDOR}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </div>
          <div className='col-12 col-md-2 py-2 text-center'>
            <legend style={{color: 'transparent'}}><small>s</small></legend>
            <button disabled={MedidorSelect?.length>=6?true:false} className='btn btn-success btn-sm w-50' onClick={()=>AddMeddidorToList()}><AddToPhotosIcon /></button>
          </div>
          
          {/* ciclo que muestra los graficos creados */}
          <div className='col-12 grid-container pt-2'>
            <div className='row'>

              {
                MedidorSelect?.map((mp, index) => (
                  <Fragment key={index+new Date().getTime()}>
                    <div className='col-9 my-2 col-md-11'>
                      <TextField disabled id="outlined-basic" value={mp.MEDIDOR} fullWidth label={"GRAFICO "+mp.CHART+" "+mp.ELEMENTO} variant="outlined" size='small' />
                    </div>
                    <div key={index+new Date()} className='col-3 my-2 col-md-1'>
                      <button className='btn btn-danger' onClick={()=>RmMeddidorToList(index)}><DeleteIcon /></button>
                    </div>
                  </Fragment>
                ))
              }
           
            </div>
          </div>

          <div className='grid-btn-footer'>
            <button className="btn btn-info btn-sm" onClick={()=>CloseMdalAndDesencadenar()}>Cerrar ❌</button>
            {/* <span className='ml-4'></span>
            <button className="btn btn-info btn-sm" onClick={()=>console.log(MedidorSelect)}>Guardar</button> */}
          </div>
        </div>
      </div>:null
      }
      
    </>
  )
}
