import React, { useContext, useEffect, useState } from 'react'
import LoginContext from '../../context/login_context/LoginContext'
import { LineFour } from '../../components/charts/LineFour.chart'

import * as htmlToImage from 'html-to-image';
import { LineFive } from '../../components/charts/LineFive.chart'
import { GetListMEdidores } from '../../API/OUR_Lecturas/GetListMEdidores.api'
import OUR_GaugeOneTest from '../../components/charts/OUR_GaugeOneTest.chart'
import { OURLineFour } from '../../components/charts/OURLineFour.chart';

export const RilesView = () => {
    history.forward()
    const [ListMedidores_, setListMedidores_] = useState([]);
    const [ShowmetheModal_, setShowmetheModal_] = useState(false);
    const [ShowmetheModal_ACUM, setShowmetheModal_ACUM] = useState(false);
    const [MedidorToModal, setMedidorToModal] = useState('');

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // console.log(LoginState.planta)

    const getIMG = async (id) => {
        var node = document.getElementById(id);
        
    
        htmlToImage.toSvg(node)
        .then(function (dataUrl) {
          
            // console.log(dataUrl);
            let anchor = document.createElement("a")
            anchor.href = dataUrl
            anchor.download = "MyQRIsHere.png"
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        
            // bringMeTheCode()
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
      }

    const GetListMedidores = async () => {
        let list_ = await GetListMEdidores({empresa:LoginState.planta})
        let listFil = await list_.filter(fl => fl.TIPO === "PTA_TRATMNTO")
        // let listFil_ = await list_["ai"].filter(fl => fl.ELEMENTO === ""NIVEL"")
        // let concatenar_ = await listFil.concat(listFil_)
        // console.log(listFil[0].MEDIDOR);
        setListMedidores_(listFil[0].MEDIDOR);
        return (<div>menu flotante</div>)
    }

    const ShowModalData = async (data) => {
        // console.log(data);
        data["SENSOR"] = "m1"
        setMedidorToModal(data);
        setShowmetheModal_(true)
    }

    useEffect(() => {
        GetListMedidores()
        return () => {
            GetListMedidores()
        }
    }, [])

    return (
        <>
            <div className='container-fluid pt-5'>
                <div className='row'>
                    {ListMedidores_.map((ulx, index) => (
                        <div key={index} className="col-4 text-center">
                            <div className="col-12" key={index}>
                                {
                                    <OUR_GaugeOneTest ShowModalData={ShowModalData} DATA={ulx} />
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>

                {
                    ShowmetheModal_?
                        <div className='modal_chdata'>
                            <button
                                onClick={()=>setShowmetheModal_(false)}
                                className='btn btn-danger btn-sm'
                                style={{position: 'absolute', top: '10px',left: '50%'}}
                                >Cerrar</button>
                            <div className='w-100 text-center'>
                                <OURLineFour DATA={MedidorToModal} />
                                {/* <LineFive DATA={MedidorToModal} /> */}
                            </div>
                        </div>
                    :null
                }
        </>
    )
}
