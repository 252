import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const GetDatosCaldera = ({inicio, fin}) => {
    // console.log(inicio, fin)
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'datasfromcaldera',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            inicio,
            fin: fin===null?new Date().getTime():fin
          })
        })
        let responsito = await query.json();
        // console.log("ITEMS",responsito.body)
        if(responsito.success){
           resolve(responsito.body)
        }else{
            // Alert.alert('Medidores',responsito.msg)
            resolve([])
            return
          // resolve(responsito.data.body)
        }
      })
}
