import React, { useContext, useEffect, useState } from 'react'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { HeaderMenu } from '../../components/HeaderMenu.comp';
import { SaveDataTAbleFaena } from '../../API/MonitorFijo/SaveDataTAbleFaena.api';
import LoginContext from '../../context/login_context/LoginContext';
import { GetDataTAbleFaena } from '../../API/MonitorFijo/GetDataTAbleFaena.api';
let titulos = [
    "% CUMPLIMIENTO\nFAENA",
    "% RENDIMIENTO\nHIGADO/CORAZON",
    "% RENDIMIENTO\nCONTRES",
    "RINDE POLLO S/M\nS/ MARINAR",
    "% ABSORCION",
    "% DECOMISO\nGRANJA",
    "% DECOMISO\nPLANTA",
    "% EXPURGO",
    "% GARRA A \nPESO VIVO",
    "ACCIDENTES",
    "CONSUMO DE\nAGUA (M3)",
]

export const IndicadorTablaFaenaCrud = () => {
    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // console.log(LoginState);
    /** ********************************************************* tabla editada ********************************************************* */
    const [TableSRVData, setTableSRVData] = useState({})
    const [IndicadoresFaenaTabla, setIndicadoresFaenaTabla] = useState({
        OBJ_CPF: "", RSA_CPF: "", LUN_CPF: "", MAR_CPF: "", MIE_CPF: "", JUE_CPF: "", VIE_CPF: "", SAB_CPF: "", DOM_CPF: "",
        OBJ_RHC: "", RSA_RHC: "", LUN_RHC: "", MAR_RHC: "", MIE_RHC: "", JUE_RHC: "", VIE_RHC: "", SAB_RHC: "", DOM_RHC: "",
        OBJ_RC: "", RSA_RC: "", LUN_RC: "", MAR_RC: "", MIE_RC: "", JUE_RC: "", VIE_RC: "", SAB_RC: "", DOM_RC: "",
        OBJ_RPM: "", RSA_RPM: "", LUN_RPM: "", MAR_RPM: "", MIE_RPM: "", JUE_RPM: "", VIE_RPM: "", SAB_RPM: "", DOM_RPM: "",
        OBJ_ABS: "", RSA_ABS: "", LUN_ABS: "", MAR_ABS: "", MIE_ABS: "", JUE_ABS: "", VIE_ABS: "", SAB_ABS: "", DOM_ABS: "",
        OBJ_DG: "", RSA_DG: "", LUN_DG: "", MAR_DG: "", MIE_DG: "", JUE_DG: "", VIE_DG: "", SAB_DG: "", DOM_DG: "",
        OBJ_DP: "", RSA_DP: "", LUN_DP: "", MAR_DP: "", MIE_DP: "", JUE_DP: "", VIE_DP: "", SAB_DP: "", DOM_DP: "",
        OBJ_EXP: "", RSA_EXP: "", LUN_EXP: "", MAR_EXP: "", MIE_EXP: "", JUE_EXP: "", VIE_EXP: "", SAB_EXP: "", DOM_EXP: "",
        OBJ_GPV: "", RSA_GPV: "", LUN_GPV: "", MAR_GPV: "", MIE_GPV: "", JUE_GPV: "", VIE_GPV: "", SAB_GPV: "", DOM_GPV: "",
        OBJ_AC: "", RSA_AC: "", LUN_AC: "", MAR_AC: "", MIE_AC: "", JUE_AC: "", VIE_AC: "", SAB_AC: "", DOM_AC: "",
        OBJ_CA: "", RSA_CA: "", LUN_CA: "", MAR_CA: "", MIE_CA: "", JUE_CA: "", VIE_CA: "", SAB_CA: "", DOM_CA: "",
    })
    const [IndicadoresFaenaCAlculo, setIndicadoresFaenaCAlculo] = useState({
        RSEM_CPF: 0,
        RSEM_RHC: 0,
        RSEM_RC: 0,
        RSEM_RPM: 0,
        RSEM_ABS: 0,
        RSEM_DG: 0,
        RSEM_DP: 0,
        RSEM_EXP: 0,
        RSEM_GPV: 0,
        RSEM_AC: 0,
        RSEM_CA: 0
    })

    const IndicadoresFaenaTablaClear = {
        OBJ_CPF: "", RSA_CPF: "", LUN_CPF: "", MAR_CPF: "", MIE_CPF: "", JUE_CPF: "", VIE_CPF: "", SAB_CPF: "", DOM_CPF: "",
        OBJ_RHC: "", RSA_RHC: "", LUN_RHC: "", MAR_RHC: "", MIE_RHC: "", JUE_RHC: "", VIE_RHC: "", SAB_RHC: "", DOM_RHC: "",
        OBJ_RC: "", RSA_RC: "", LUN_RC: "", MAR_RC: "", MIE_RC: "", JUE_RC: "", VIE_RC: "", SAB_RC: "", DOM_RC: "",
        OBJ_RPM: "", RSA_RPM: "", LUN_RPM: "", MAR_RPM: "", MIE_RPM: "", JUE_RPM: "", VIE_RPM: "", SAB_RPM: "", DOM_RPM: "",
        OBJ_ABS: "", RSA_ABS: "", LUN_ABS: "", MAR_ABS: "", MIE_ABS: "", JUE_ABS: "", VIE_ABS: "", SAB_ABS: "", DOM_ABS: "",
        OBJ_DG: "", RSA_DG: "", LUN_DG: "", MAR_DG: "", MIE_DG: "", JUE_DG: "", VIE_DG: "", SAB_DG: "", DOM_DG: "",
        OBJ_DP: "", RSA_DP: "", LUN_DP: "", MAR_DP: "", MIE_DP: "", JUE_DP: "", VIE_DP: "", SAB_DP: "", DOM_DP: "",
        OBJ_EXP: "", RSA_EXP: "", LUN_EXP: "", MAR_EXP: "", MIE_EXP: "", JUE_EXP: "", VIE_EXP: "", SAB_EXP: "", DOM_EXP: "",
        OBJ_GPV: "", RSA_GPV: "", LUN_GPV: "", MAR_GPV: "", MIE_GPV: "", JUE_GPV: "", VIE_GPV: "", SAB_GPV: "", DOM_GPV: "",
        OBJ_AC: "", RSA_AC: "", LUN_AC: "", MAR_AC: "", MIE_AC: "", JUE_AC: "", VIE_AC: "", SAB_AC: "", DOM_AC: "",
        OBJ_CA: "", RSA_CA: "", LUN_CA: "", MAR_CA: "", MIE_CA: "", JUE_CA: "", VIE_CA: "", SAB_CA: "", DOM_CA: "",
    }
    
    const ClearTablaOPC = async () => {
        let seguro = confirm('Esta seguro de borrar todo el PANEL, esta accion no es reversible.')
        if(seguro) setIndicadoresFaenaTabla(IndicadoresFaenaTablaClear)
    }

    const SaveDataTable = async () => {
        let items = IndicadoresFaenaTabla//await Object.keys(IndicadoresFaenaTabla)
        let items_ = IndicadoresFaenaCAlculo//await Object.keys(IndicadoresFaenaCAlculo)
        // console.log(items);
        let bulkData = [Object.assign(items,items_)]
        // for (let ind = 0; ind < 11; ind++) {
        //     let lmo = []
        //     lmo.push({indicador:titulos[ind]})
        //     for (let ite = 0; ite+(ind*9) < 9*(ind+1); ite++) {
        //         // console.log(ite+(ind*9),9*(ind+1));
        //         lmo.push({[items[ite+(ind*9)]]:IndicadoresFaenaTabla[items[ite+(ind*9)]]})
        //     }
        //     lmo.push({[items_[ind]]:IndicadoresFaenaCAlculo[items_[ind]]})
        //     bulkData.push(lmo);
        // }
        await SaveDataTAbleFaena({bulkData,PLANTA:LoginState.planta,USUARIO:LoginState.user,AREA:"FAENA"})
        ObtenerTAblaActualizada()
        alert("Los datos fueron cargados.")
        // console.log(bulkData);
    }

    // const ObtenerTAblaActualizada = async () => {
    //     // console.log("carga la tabla", new Date().toLocaleString())
    //     let tablaSrv = await GetDataTAbleFaena({PLANTA:LoginState.planta, AREA: "FAENA"})
    //     if(tablaSrv){
    //         let clonObject = {}
    //         for (let i = 0; i < tablaSrv.length; i++) {
    //             const i_ = tablaSrv[i];
    //             // console.log(i_)
    //             for (let ii = 1; ii < 9; ii++) {
    //                 const ii_ = i_[ii];
    //                 clonObject[Object.keys(ii_)] = ii_[Object.keys(ii_)]
    //                 // console.log(ii_,Object.keys(ii_))
    //             }
    //         }
    //         setIndicadoresFaenaTabla(clonObject);
    //         console.log(clonObject);
    //         setTableSRVData(clonObject);
    //     }
        
    // }

    const ObtenerTAblaActualizada = async () => {
        // console.log("carga la tabla", new Date().toLocaleString())
        let tablaSrv = await GetDataTAbleFaena({PLANTA:LoginState.planta, AREA: "FAENA"})
        if(tablaSrv){
            // console.log(tablaSrv);
            let items_ = Object.keys(IndicadoresFaenaTabla)
            let items_2 = Object.keys(IndicadoresFaenaCAlculo)
            // console.log(items_,items_2);
            let Bulk_tabla = {}
            let Bulk_calculo = {}
            for (let i = 0; i < items_.length; i++) {
                const i_i = items_[i];
                Bulk_tabla[i_i] = tablaSrv[0][i_i]
                // console.log({[i_i]:tablaSrv[0][i_i]});
            }
            for (let i = 0; i < items_2.length; i++) {
                const i_i = items_2[i];
                Bulk_calculo[i_i] = tablaSrv[0][i_i]
                // console.log({[i_i]:tablaSrv[0][i_i]});
            }
            // console.log(Bulk_tabla);
            // console.log(Bulk_calculo);
            setIndicadoresFaenaTabla(Bulk_tabla);
            setIndicadoresFaenaCAlculo(Bulk_calculo);
            setTableSRVData(Bulk_tabla);
        }
        
    }

    // ciclo que une los datos para enviarlos
    useEffect(() => {
        const Calculalo = async () => {
            let objTitle = Object.keys(IndicadoresFaenaTabla)
        // console.log(objTitle);
        let ind = IndicadoresFaenaTabla
        let semPor = [
            ["LUN_CPF","MAR_CPF","MIE_CPF","JUE_CPF","VIE_CPF","SAB_CPF","DOM_CPF"],
            ["LUN_RHC","MAR_RHC","MIE_RHC","JUE_RHC","VIE_RHC","SAB_RHC","DOM_RHC"],
            ["LUN_RC","MAR_RC","MIE_RC","JUE_RC","VIE_RC","SAB_RC","DOM_RC"],
            ["LUN_RPM","MAR_RPM","MIE_RPM","JUE_RPM","VIE_RPM","SAB_RPM","DOM_RPM"],
            ["LUN_ABS","MAR_ABS","MIE_ABS","JUE_ABS","VIE_ABS","SAB_ABS","DOM_ABS"],
            ["LUN_DG","MAR_DG","MIE_DG","JUE_DG","VIE_DG","SAB_DG","DOM_DG"],
            ["LUN_DP","MAR_DP","MIE_DP","JUE_DP","VIE_DP","SAB_DP","DOM_DP"],
            ["LUN_EXP","MAR_EXP","MIE_EXP","JUE_EXP","VIE_EXP","SAB_EXP","DOM_EXP"],
            ["LUN_GPV","MAR_GPV","MIE_GPV","JUE_GPV","VIE_GPV","SAB_GPV","DOM_GPV"],
            ["LUN_AC","MAR_AC","MIE_AC","JUE_AC","VIE_AC","SAB_AC","DOM_AC"],
            ["LUN_CA","MAR_CA","MIE_CA","JUE_CA","VIE_CA","SAB_CA","DOM_CA"],
        ]
        let rcm = [
            "RSEM_CPF",
            "RSEM_RHC",
            "RSEM_RC",
            "RSEM_RPM",
            "RSEM_ABS",
            "RSEM_DG",
            "RSEM_DP",
            "RSEM_EXP",
            "RSEM_GPV",
            "RSEM_AC",
            "RSEM_CA"
        ]//RSEM_CPF
        let rcmObj = { 
            "RSEM_CPF": 0,
            "RSEM_RHC": 0,
            "RSEM_RC": 0,
            "RSEM_RPM": 0,
            "RSEM_ABS": 0,
            "RSEM_DG": 0,
            "RSEM_DP": 0,
            "RSEM_EXP": 0,
            "RSEM_GPV": 0,
            "RSEM_AC": 0,
            "RSEM_CA": 0,
        }
        for (let idm = 0; idm < semPor.length; idm++) {
            const idm_ = semPor[idm];
            let calculeDivisor = 0
            let calculesuma = 0
            for (let dp = 0; dp < idm_.length; dp++) {
            const dp_ = idm_[dp];
            let numero_ = IndicadoresFaenaTabla[dp_]===''?0:Number(IndicadoresFaenaTabla[dp_])
                if(idm > 8){
                    calculeDivisor = 1
                }else{
                    if(numero_ > 0){
                        calculeDivisor += 1
                    }else{
                        calculeDivisor += 0
                    }
                }
                calculesuma += numero_
                // console.log(calculeDivisor,numero_,dp_);
            }
            let Prom_edio = await calculeDivisor===0?0:Number(Number(calculesuma/calculeDivisor).toFixed(2))
            
            rcmObj[rcm[idm]] = Prom_edio
        }
        setIndicadoresFaenaCAlculo(rcmObj);
        }
        Calculalo()
    // }, [IndicadoresFaenaTabla.LUN_CPF,IndicadoresFaenaTabla.MAR_CPF,IndicadoresFaenaTabla.MIE_CPF,IndicadoresFaenaTabla.JUE_CPF,IndicadoresFaenaTabla.VIE_CPF,IndicadoresFaenaTabla.SAB_CPF,IndicadoresFaenaTabla.DOM_CPF])
    }, [IndicadoresFaenaTabla])

    useEffect(() => {
        ObtenerTAblaActualizada()
      return () => {
        ObtenerTAblaActualizada()
      }
    }, [])

    
    
    return (
        <>
            <HeaderMenu Pag_Sel={'tablacrud'} />
            <div className='mb-5'>
            <div className="col-12 text-center">
                <div className="col-12">
                <div className="row">
                    <div className="col-12 text-center">
                    <div className="col-12">
                        <div className="row">
                        <div className="col-3">
                        </div>
                        <div className="col-6">
                            <h4 className="mt-3" style={{fontWeight:"bolder"}}><b>INDICADORES FAENA</b></h4>
                        </div>
                        <div className="col-3">
                            <img src={require("../../assets/logo_ariztia.png")} style={{ height: "100px" }} className="px-5 float-right" />
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-12">

                        <table className="table table-bordered">
                            <thead className="" style={{backgroundColor: 'red', fontSize:12, color: 'white'}}>
                            <tr>
                                <th style={{verticalAlign: 'middle'}}>Indicador</th>
                                <th style={{verticalAlign: 'middle'}}>Objetivo</th>
                                <th style={{verticalAlign: 'middle'}}>Resumen<br/>Semana<br/>Anterior</th>
                                <th style={{verticalAlign: 'middle'}}>Lunes</th>
                                <th style={{verticalAlign: 'middle'}}>Martes</th>
                                <th style={{verticalAlign: 'middle'}}>Miercoles</th>
                                <th style={{verticalAlign: 'middle'}}>Jueves</th>
                                <th style={{verticalAlign: 'middle'}}>Viernes</th>
                                <th style={{verticalAlign: 'middle'}}>Sabado</th>
                                <th style={{verticalAlign: 'middle'}}>Domingo</th>
                                <th style={{verticalAlign: 'middle'}}>Resumen<br/>Semanal</th>
                            </tr>
                            </thead>
                            <tbody>
                                {/* lineas de la tabla */}
                                <tr>
                                    <th style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>% CUMPLIMIENTO<br/>FAENA</th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <span className="indicador-arrow"><NorthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle',  padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_CPF']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_CPF}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>
                                    {String(IndicadoresFaenaCAlculo.RSEM_CPF)}%
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>% RENDIMIENTO<br/>HIGADO/CORAZON</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><NorthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_RHC} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_RHC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_RHC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_RHC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_RHC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_RHC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_RHC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_RHC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_RHC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_RHC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_RHC}%</th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>% RENDIMIENTO<br/>CONTRES</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><NorthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_RC} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_RC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_RC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_RC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_RC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_RC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_RC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_RC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_RC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_RC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_RC}%</th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>RINDE POLLO S/M<br/>S/ MARINAR</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><NorthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_RPM} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_RPM}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_RPM}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_RPM}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_RPM}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_RPM}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_RPM}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_RPM}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_RPM']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_RPM}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_RPM}%</th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>% ABSORCION</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><NorthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_ABS} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_ABS}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_ABS}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_ABS}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_ABS}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_ABS}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_ABS}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_ABS}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_ABS']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_ABS}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_ABS}%</th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>% DECOMISO<br/>GRANJA</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><SouthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_DG} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_DG}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_DG}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_DG}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_DG}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_DG}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_DG}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_DG}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_DG']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_DG}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_DG}%</th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>% DECOMISO<br/>PLANTA</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><SouthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_DP} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_DP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_DP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_DP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_DP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_DP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_DP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_DP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_DP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_DP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_DP}%</th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>% EXPURGO</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><SouthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_EXP} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_EXP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_EXP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_EXP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_EXP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_EXP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_EXP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_EXP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_EXP']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_EXP}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_EXP}%</th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>% GARRA A PESO<br/>VIVO</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><NorthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_GPV} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_GPV}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_GPV}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_GPV}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_GPV}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_GPV}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_GPV}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_GPV}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_GPV']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_GPV}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 25, padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_GPV}%</th>
                                </tr>
                                <tr>
                                    <th scope="row" style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>ACCIDENTES</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><SouthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_AC} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_AC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_AC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_AC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_AC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_AC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_AC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_AC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_AC']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_AC}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle' , padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_AC}dias</th>
                                </tr>
                                <tr>
                                    <th scope="row"  style={{verticalAlign: 'middle', fontSize: 12, fontWeight: 'normal'}}>CONSUMO DE<br/>AGUA (M3)</th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <span className="indicador-arrow"><SouthIcon color="error"/></span>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['OBJ_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.OBJ_CA} />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['RSA_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.RSA_CA}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['LUN_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.LUN_CA}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MAR_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MAR_CA}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['MIE_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.MIE_CA}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['JUE_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.JUE_CA}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['VIE_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.VIE_CA}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['SAB_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.SAB_CA}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>
                                    <input className="inp-edit-table" type="number" onChange={(e)=>setIndicadoresFaenaTabla({...IndicadoresFaenaTabla,['DOM_CA']:Number(e.target.value)})} value={IndicadoresFaenaTabla.DOM_CA}  />
                                    </th>
                                    <th scope="row" style={{verticalAlign: 'middle', padding:0, margin:0}}>{IndicadoresFaenaCAlculo.RSEM_CA}m3</th>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                    <div className='col-12'>
                        <span className='btn btn-sm btn-danger mr-2 float-left' onClick={()=>ClearTablaOPC()}>Limpiar Tabla</span>
                        <button className='btn btn-sm btn-success float-right' onClick={()=>SaveDataTable()}>Guardar Cambios</button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </>
    )
}
