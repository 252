import React, { useContext } from 'react'
import { LineFacture } from '../../components/charts/LineFacture.chart'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import LoginContext from '../../context/login_context/LoginContext';

export const FactureChart = () => {

  const { LoginState, SetLogin, CloseLogin, GetLoginSession } = useContext(LoginContext);
  console.log();

  return (
    <>
        <HeaderMenu Pag_Sel={'chartfactura'} />
        <LineFacture EMPRESA={LoginState.planta} />
    </>
  )
}
