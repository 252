import React, { useContext, useEffect, useState } from "react";
import { HeaderMenu } from "../../components/HeaderMenu.comp";
import LoginContext from "../../context/login_context/LoginContext";

//icons
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt"; //electric
import WaterDropIcon from "@mui/icons-material/WaterDrop"; //water
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat"; //temperatura
import SpeedIcon from "@mui/icons-material/Speed"; //velocidad
import TireRepairIcon from "@mui/icons-material/TireRepair"; //presion
import { GetListMEdidores } from "../../API/OUR_Lecturas/GetListMEdidores.api";
import { OUR_LineOneTemp } from "../../components/charts/OUR_LineOneTemp.chart";

//variables contantes
const Constantes = {};

export const OUR_graficTest = () => {
  const { LoginState, SetLogin, CloseLogin, GetLoginSession } = useContext(LoginContext);

  //mutadores de estado
  const [ListMedidores_, setListMedidores_] = useState([]);

  //logica, funciones y metodos de la apgina
  const GetListMedidores = async () => {
    let list_ = await GetListMEdidores({ empresa: LoginState.planta });
    // let listFil = await list_["m1"].filter((fl) => fl.ELEMENTO === "HIDRICO" && (FAENA_A.includes(fl.MEDIDOR)));
    // let fill_col_OneDay = await list_["m1"].filter((fl) => fl.ELEMENTO === "HIDRICO" && (FAENA_B.includes(fl.MEDIDOR)));

    let listFil = await list_.filter(fl => fl.TIPO === "PRESION" || fl.TIPO === "NIVEL")
    // let listFil_ = await list_.filter(fl => fl.ELEMENTO === "NIVEL")
    // let concatenar_ = await listFil.concat(listFil_)
    console.log(list_)
    setListMedidores_(listFil);
    // setListMedidores_oneday(fill_col_OneDay)
  };

  //iniciadores de logica primaria
  useEffect(() => {
    GetListMedidores();

    return () => {
      GetListMedidores();
    };
  }, []);

  return (
    <>
      <HeaderMenu Pag_Sel={"ourgrafictest"} />

      <span onClick={() => GetListMedidores()}>OUR_graficTest</span>
      <div className="container-fluid">
        <div className="row">
            {ListMedidores_.map((ulx, index) => (
                <div key={index} className="col-12">
                {ulx.TIPO}
                {ulx.MEDIDOR.map((med, index) => (
                    <div className="col-12" key={index}>
                    {/* <p key={index}>-{med.MEDIDOR}</p> */}
                        <OUR_LineOneTemp DATA={med} />
                    </div>
                ))}
                </div>
            ))}
        </div>
      </div>
      
    </>
  );
};
