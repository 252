import React, { useContext, useEffect, useState } from 'react'
import { ListMedidores } from '../API/ListMedidores.api';
import dayjs from 'dayjs/locale/es';
import LoginContext from '../context/login_context/LoginContext';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import { MakeAFileELectric } from '../API/MonitorFijo/MakeAFileELectric.api';

export const ModalElectricFile = ({setIsModalElectric}) => {

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // listado de medidores
    const [ListMedidores_, setListMedidores_] = useState([]);
    // medidor seleccionado
    const [SelMedidor, setSelMedidor] = useState('');
    //fecha inicio
    const [FechaInicio, setFechaInicio] = useState('');
    //fecha fin
    const [FechaFin, setFechaFin] = useState('');


    const GetListMedidores = async () => {
        let list_ = await ListMedidores({empresa:LoginState.planta})
        let listFil = await list_["m1"].filter(fl => fl.ELEMENTO === "ELECTRICO" && !String(fl.MEDIDOR).includes("EMPALME"))
        // console.log(listFil)
        setListMedidores_(listFil);
        return (<div>menu flotante</div>)
    }

    const MakeaFileNow = async () => {
        let data = await MakeAFileELectric({
            PLANTA:LoginState.planta,
            MEDIDOR:SelMedidor,
            FECHAINICIO:new Date(FechaInicio.$y+"-"+((FechaInicio.$M+1)<10?"0"+(FechaInicio.$M+1):(FechaInicio.$M+1))+"-"+(FechaInicio.$D<10?"0"+FechaInicio.$D:FechaInicio.$D)+"T00:00:00-04:00"),
            FECHAFIN:new Date(FechaFin.$y+"-"+((FechaFin.$M+1)<10?"0"+(FechaFin.$M+1):(FechaFin.$M+1))+"-"+(FechaFin.$D<10?"0"+FechaFin.$D:FechaFin.$D)+"T23:59:59-04:00")
        })
        if(data){
            setIsModalElectric(false)
        }else(
            setIsModalElectric(false)
        )
    }

    useEffect(() => {
        GetListMedidores()    
        return () => {
            GetListMedidores()
        }
    }, [])
    
    return (
        
        <div className='modal-electric'>
            <div className='container mt-5'>
                <div className='row bg-white py-3 modalslectricshini'>
                    <div className='col-12 text-center'>
                        <h5 className='mt-2'>Generar Archivo Electrico</h5>
                    </div>
                    <div className='col-12 col-md-4'>
                        <FormControl sx={{ m: 1}} fullWidth size="small">
                            <InputLabel id="demo-select-small-label">Medidor</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={SelMedidor}
                                label="Medidor"
                                onChange={(e) => setSelMedidor(e.target.value)}
                            >
                                <MenuItem value="">
                                <em>TODOS LOS MEDIDORES</em>
                                </MenuItem>
                                {
                                    ListMedidores_.map((medidor, index) => {
                                        return (
                                            <MenuItem key={index} value={medidor.MEDIDOR}>{medidor.MEDIDOR}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-12 col-md-4'>
                        <LocalizationProvider
                            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                            adapterLocale='es'
                            dateAdapter={AdapterDayjs}>
                            <DemoContainer sx={{overflow: ''}} components={['DatePicker']}>
                                <DatePicker
                                    format='DD/MM/YYYY'
                                    displayWeekNumber
                                    label="Fecha Inicio"
                                      value={FechaInicio}
                                      onChange={(newValue) => setFechaInicio(newValue)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                    <div className='col-12 col-md-4'>
                        <LocalizationProvider
                            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                            adapterLocale='es'
                            dateAdapter={AdapterDayjs}>
                            <DemoContainer sx={{overflow: ''}} components={['DatePicker']}>
                                <DatePicker
                                    displayWeekNumber
                                    format='DD/MM/YYYY'
                                    label="Fecha Fin"
                                      value={FechaFin}
                                      onChange={(newValue) => setFechaFin(newValue)}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                    <div className='col-12 text-right'>
                    <button onClick={()=>setIsModalElectric(false)} className='btn btn-sm btn-danger mr-2'>Cerrar</button>
                        <button
                            onClick={()=>{
                                MakeaFileNow()
                            }}
                            className='btn btn-sm btn-primary'>Generar Archivo</button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}
