import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const GetTotalizadorFromDB = ({MEDIDOR, SENSOR, EMPRESA}) => {
    // console.log({MEDIDOR, SENSOR, EMPRESA});
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'getlasttotalizador',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            MEDIDOR, SENSOR, EMPRESA
        })
        })
        let responsito = await query.json();
        // console.log(responsito.body)
        if(responsito.success){
          resolve(responsito.body)
        }else{
          resolve([])
        }
    })
}
