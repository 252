import React, { useContext, useEffect } from 'react'
import LoginContext from '../../context/login_context/LoginContext';
import { useNavigate } from 'react-router-dom';

export const RilesMonitor = () => {
  history.forward()
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  const navigate = useNavigate();

  useEffect(() => {
      const HandleSubmit = async () => {
          let valores = {
              user:'monitorriles@ariztia.com',
              password:'rilesmonitor01',
              showPassword: false,
              isSubmit: false,
              errorMessage: null
            }
          setTimeout(async() => {
              const isLogin = await SetLogin({valores})
              // console.log(isLogin)
              if(isLogin.success){
                navigate('../dashboard')//ingresar_transporte
              }else{
                HandleSubmit()
              }
          }, 7000);
        }
        HandleSubmit()
  }, [])
  
return (
  <>
      <div className='container'>
          <div className='row'>
              <div className='col-12 text-center'>
                  <img src={require("../../assets/logo_mtto.png")} className='imgMonitorFijoNologinriles' />
              </div>
          </div>
      </div>
  </>
)
}
