import React from 'react'
import { URL_SRV_ARIZTIA, URL_SRV_RAIZ } from '../../router/Url';

export const Make_report_temps = ({empresa}) => {
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'makereport_temps',{
            method: 'POST',
            headers: {
                'authorization': "paico2021",
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                empresa
            })
        })
            let responsito = await query.json();
            // console.log(responsito)
            if(responsito.success){
                resolve(responsito.body)
                let url = responsito.body;
                let a = document.createElement("a");
                a.href = URL_SRV_RAIZ+url+"?marco="+new Date().getTime();//to web add url_server+build/
                // console.log(a.href)
                // a.href = url;
                a.download = "Reporte_Temperaturas.xlsx";
                document.body.appendChild(a);
                a.click();
                a.remove();
                alert('En instantes se descargara su Archivo!!')
          
              }else{
                resolve(responsito)
                alert('ups!! hay un error vuelva a intentarlo.')
              }
        })
}
