import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const SaveNowDatosCaldera = ({USER,TIMESTAMP,CONS_GAS_NAT,CON_AGUA_CALD,TAGUALIM,DUREZA_AGUA,CONDUCT_AGUA_CALD,PRESION_CALDERA,CONSUMO_VAPOR}) => {
    // console.log(USER,TIMESTAMP,CONS_GAS_NAT,CON_AGUA_CALD,TAGUALIM,DUREZA_AGUA,CONDUCT_AGUA_CALD,PRESION_CALDERA,CONSUMO_VAPOR);
    return new Promise(async (resolve, reject) => {
		let query = await fetch(URL_SRV_ARIZTIA+ "savedatacalderanow", {
			method: "POST",
			headers: {
				authorization: "paico2021",
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(
				{USER,TIMESTAMP,CONS_GAS_NAT,CON_AGUA_CALD,TAGUALIM,DUREZA_AGUA,CONDUCT_AGUA_CALD,PRESION_CALDERA,CONSUMO_VAPOR}
			),
		});
		let responsito = await query.json();
		// console.log(responsito.body)
		if (responsito.success) {
			resolve(responsito.success);
		} else {
			resolve([]);
		}
	});
}
