import React from 'react'
import { URL_CTRL_ACCESS } from '../../router/Url';

export const GetUbicacionesRutas = ({empresa}) => {
    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_CTRL_ACCESS+'getubicacionesweb',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            EMPRESA: empresa
          })
        })
        let responsito = await query.json();
        // console.log(responsito.body)
        if(responsito.success){
          resolve(responsito.body)
        }else{
          resolve([])
        }
      })
}
