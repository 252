// export const URL_LOGIN = "https://apimedidores.apidev.info/signin/"
// export const URL_FORGET = "https://apimedidores.apidev.info/forgetpassword/"


/**
 * FROM LOCAL SERVER
 */
// export const URL_LOGIN =            "http://localhost:4088/signin_v2/"
// export const URL_SRV =              "http://localhost:4088/app-medidor/"
// export const URL_FORGET =           "http://localhost:4088/forgetpassword/"
// export const URL_GETPLANTA =        "http://localhost:4088/app-medidor/getlistaempresas"
// export const URL_CTRL_HIDRIC =      "http://localhost:4088/ctrl-hidrico/"
// export const URL_CTRL_TRASP =       "http://localhost:4088/ctrl-transporte/"
// export const URL_SRV_RAIZ =         "http://localhost:4088/"
// export const URL_SRV_ARIZTIA =      "http://localhost:4088/ariztia/"
// export const URL_BOUCHER_STORE =    "http://localhost:4088/boucher/"
// export const URL_CTRL_ACCESS =      "http://localhost:4088/access/"

/**
 * FROM REMOTE APISERVER
 */
export const URL_LOGIN = "https://apimedidores.apidev.info/signin_v2/"
export const URL_SRV = "https://apimedidores.apidev.info/app-medidor/"
export const URL_FORGET = "https://apimedidores.apidev.info/forgetpassword/"
export const URL_GETPLANTA = "https://apimedidores.apidev.info/app-medidor/getlistaempresas"
export const URL_CTRL_HIDRIC = "https://apimedidores.apidev.info/ctrl-hidrico/"
export const URL_CTRL_TRASP = "https://apimedidores.apidev.info/ctrl-transporte/"
export const URL_SRV_RAIZ = "https://apimedidores.apidev.info/"
export const URL_SRV_ARIZTIA = "https://apimedidores.apidev.info/ariztia/"
export const URL_BOUCHER_STORE = "https://apimedidores.apidev.info/boucher/"
export const URL_CTRL_ACCESS = "https://apimedidores.apidev.info/access/"

/**
 * FROM APISERVER OF OTHER ENTERPRICE
 */
export const URL_NESPRA_GETTOKEN = "https://data.nescloud.net/auth/validate/"
export const URL_NESPRA_MEDIDORES = "https://data.nescloud.net/networks/"
export const URL_NESPRA_DATAMED = "https://data.nescloud.net/sensordata"
export const TULSA_KING = "sk68TMu7ucrqAXAQUhtC3tS3DY_RexUur-IfdgKqiRQ"// "expiration_time": 1723908677
// export const URL_CTRL_HIDRIC = "https://apimedidores.apidev.info/ctrl-hidrico/"

// export const URL_CTRL_HIDRIC = "http://localhost:4088/ctrl-hidrico/"


/** VERSION */
export const VERSION = "v7.43.19"
/** HORAS DE DESCUENTO PARA HORARIO DE CHILE */
export const REST_HOUSR = 3 //horario de verano = 3, horario invierno = 4
// paico1714