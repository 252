import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import LoginContext from '../../context/login_context/LoginContext';
import { GetUbicacionesRutas } from '../../API/control_acceso/GetUbicaionesRutas.api';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { GetControlsHere } from '../../API/control_acceso/GetControlsHere.api';

//ICONS
import VillaIcon from '@mui/icons-material/Villa';

const RetornaHtml = ({data,ubicacion}) => {
    let flt = data.filter(filtr=>filtr.nombre===ubicacion)
    if(flt[0]?.data.length){
        // console.log(flt[0].data)
        return (
            <table className="table m-0 table-hover table-sm mt-2 table-dark table-bordered">
                <thead>
                    <tr>
                    <th scope="col" className='p-0 pl-2'>Operario</th>
                    <th scope="col" className='p-0 pl-2'>Control</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        flt[0].data.map(fltm=>(
                            <tr key={fltm.TIMESTAMP}>
                                <td className='p-0 pl-2'><small>{fltm.NOMBRE_OPERARIO}</small></td>
                                <td className='p-0 pl-2'><small>{new Date(fltm.TIMESTAMP).toLocaleString()}</small></td>
                            </tr>
                        ))
                    }
                    
                </tbody>
            </table>
        )
    }
    //  <p>{ubicacion}</p>
}

export const ControlAccesoData = () => {

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // console.log(LoginState.planta);

    //estados
    const [Areas, setAreas] = useState([]);
    const [AreasSelected, setAreasSelected] = useState([]);
    const [Rutas, setRutas] = useState([]);
    const [RutasListSelect, setRutasListSelect] = useState([]);
    const [RutasSelected, setRutasSelected] = useState('');
    const [Ubicaciones, setUbicaciones] = useState([]);
    const [UbicacionesList, setUbicacionesList] = useState([]);
    const [UbicacionesSelected, setUbicacionesSelected] = useState([]);
    const [Fechas, setFechas] = useState([]);
    const [FechasSelected, setFechasSelected] = useState([]);
    const [ControlList, setControlList] = useState([]);

    // FUNCIONES, METODOS Y LOGICA
    const GetControlAccessHere = async (ubicacionesList_,FechasSelected_) => {
        let controlL = await GetControlsHere({UBICACIONES: ubicacionesList_,FECHA: FechasSelected_})
        setControlList(controlL)
    }

    
    // consulta al servidor por las ubicaiones,rutas,areas y fechas
    useEffect(() => {
      const GetRutasYUnicacaiones = async () => {
        let DatosWev = await GetUbicacionesRutas({empresa:LoginState.planta})
        // console.log(DatosWev);
        setUbicaciones(DatosWev.ubicaciones)
        setRutas(DatosWev.rutas)
        setAreas(DatosWev.areas)
        setFechas(DatosWev.fechas)
      }
      GetRutasYUnicacaiones()
      return () => {
        GetRutasYUnicacaiones()
      }
    }, [])

    // filtra al seleccionar un area cargando las rutas que corresponden segun elarea seleccionada
    useEffect(() => {
        const FiltrarRutas = async () => {
            let filterRutas = await Rutas.filter(fltr => fltr.area === AreasSelected)
            setRutasListSelect(filterRutas)
            setUbicacionesList([])
            setFechasSelected('')
        }
        FiltrarRutas()
        return () => {
            
        }
    }, [AreasSelected])

    //filtra las ubicaiones por area y por fecha para mostrarlas y carga los controles de accesoque existan para los parametros
    useEffect(() => {
        const FiltrarUbicaciones = async () => {
            let FiltroUbicaciones = await Ubicaciones.filter(fltr => fltr.AREA === AreasSelected && fltr.RUTA === RutasSelected)
            await setUbicacionesList(FiltroUbicaciones);
            GetControlAccessHere(FiltroUbicaciones,FechasSelected)
            // console.log(FiltroUbicaciones);
        }
        
        if(RutasSelected !== '' && FechasSelected !== ''){
            FiltrarUbicaciones()
        }
        return () => {
        }
    }, [RutasSelected,FechasSelected])

    
    return (
        <>
            <HeaderMenu Pag_Sel={'accesos'} />  
            
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-12 col-md-4 my-2'>
                        <FormControl fullWidth>
							<InputLabel size='small' id="demo-simple-select-label">Seleccione Area</InputLabel>
							<Select
								size='small'
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Seleccione Area"
								value={AreasSelected}
								onChange={(e)=>setAreasSelected(e.target.value)}
							>
								{
									Areas.map(itm=><MenuItem key={itm._id} value={itm._id}>{itm._id}</MenuItem>)
								}
							</Select>
						</FormControl>
                    </div>
                    <div className='col-12 col-md-4 my-2'>
                        <FormControl fullWidth>
							<InputLabel size='small' id="demo-simple-select-label">Seleccione Ruta</InputLabel>
							<Select
								size='small'
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Seleccione Ruta"
								value={RutasSelected}
								onChange={(e)=>setRutasSelected(e.target.value)}
							>
								{
									RutasListSelect.map(itm=><MenuItem key={itm._id} value={itm._id}>{itm._id}</MenuItem>)
								}
							</Select>
						</FormControl>
                    </div>
                    <div className='col-12 col-md-4 my-2'>
                        <FormControl fullWidth>
							<InputLabel size='small' id="demo-simple-select-label">Seleccione Fecha</InputLabel>
							<Select
								size='small'
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Seleccione Fecha"
								value={FechasSelected}
								onChange={(e)=>setFechasSelected(e.target.value)}
							>
								{
									Fechas.map(itm=><MenuItem key={itm._id} value={itm._id}>{itm._id}</MenuItem>)
								}
							</Select>
						</FormControl>
                    </div>
                    
                    {
                        UbicacionesList?.map(mpl =>
                            <div key={mpl._id} className='col-12 col-md-6'>
                                <div className='row p-2'>
                                    <div className='col-12 card-ubicacion'>
                                        <small><b><VillaIcon color='disabled'/>{mpl.NOMBRE}</b></small><br/>
                                        <small><b>Area:</b> {mpl.AREA}</small>  <small><b>Empresa:</b> {mpl.EMPRESA}</small><br/>
                                        <small><b>Frecuencia:</b> cada {mpl.FRECUENCIA}hrs</small>  <small><b>Horario Sugerido:</b> {mpl.HORARIO}</small><br/>
                                        <RetornaHtml data={ControlList} ubicacion={mpl.NOMBRE} />
                                    </div>
                                    <div className='col-12'>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}
