import React, { useEffect, useLayoutEffect, useState } from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { REST_HOUSR } from '../../router/Url'
import { LecturaNespraOurRangeTotalizador } from '../../API/LecturaNespraOurRangeTotalizador.api'
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation'

import RotateLeftTwoToneIcon from '@mui/icons-material/RotateLeftTwoTone';
import { GetLecturas } from '../../API/OUR_Lecturas/GetLecturas.api'

//contantes
let hora = ((1*60*60*24*1000)-(1*60*60*1000))+(1*60*10*1000)//(1*60*60*24*1000)-(1*60*60*1000)//1*60*60*1000
let cambioHora_1 = new Date('2024-04-07').getTime();
let CambioHora_2 = new Date('2024-09-07').getTime();

export const OUR_LineOneTemp = ({DATA}) => {
  // console.log('LineFour')
  // console.log(DATA)
  let title = DATA.FUENTE
  let subTitle = DATA.TIPO
  let unidad_med = DATA.UNIDAD
  const [state, setstate] = useState();
  const [IsREnder, setIsREnder] = useState(false);
  const [IsRollBack, setIsRollBack] = useState(false);
  const [BKPData, setBKPData] = useState([]);
  const [MinMaxDataRange, setMinMaxDataRange] = useState([]);
  const [DateMin, setDateMin] = useState('');
  const [DateMmax, setDateMmax] = useState('');
  const [IsVisbleFilter, setIsVisbleFilter] = useState(false);
  const [IsVisbleCalculo, setIsVisbleCalculo] = useState(false);
  const [CalculoConsumoFiltrado, setCalculoConsumoFiltrado] = useState('');

  useLayoutEffect( ( ) => {
    setstate(options)
    setIsREnder(true)
  },[])

  let UpdateChartNow = async () => {
    setCalculoConsumoFiltrado('')
    // console.log(state.series[0].data)
    let OneWeek = 604800000;
    let OneDay = 86400000;
    let OneHour = 3600000;
    let OneMinit = 60

    let now = (new Date).getTime()//segundos now
    let pasado = now - (OneDay*2);
    
    let datas = await GetLecturas({medidor: DATA.MEDIDOR, sensor: DATA.SENSOR, planta: DATA.EMPRESA, ahora: now, pasado: pasado})
    // console.log(datas)
    let g = []
    // LOGICA PARA GENERAR DATOS EN CASO DE SER MEDIDOR DE NESPRA
    let arr_consumo = await datas.map(sss => {
      let {timestamp,valor} = sss
      return [(timestamp+OneHour),valor]
    })
    //backup de data
    setBKPData(arr_consumo)
    // define el rango minimo y maximo de seleccion de fecha 
    let MIN = new Date(arr_consumo[0][0]).toISOString().split('T')[0]
    let MAX = new Date(arr_consumo[arr_consumo.length-1][0]).toISOString().split('T')[0]
    setMinMaxDataRange([MIN, MAX])
    // console.log(new Date(arr_consumo[0][0]).toISOString());
    // console.log(new Date(arr_consumo[arr_consumo.length-1][0]).toISOString());
    g = await [{
      name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"❄":"❄"),
      data: arr_consumo
    }]

    await setstate({...state,["series"]:g})
    // await setIsVisbleFilter(true)
  }
  
  const FilterByDataRange = async () => {
    setIsRollBack(true)
    let g = []
    if(DateMin === '' || DateMmax === ''){
      alert('Primero debe seleccionar un rango de fechas.')
    }else{
      // console.log('FECHA MINIMA',DateMin, new Date(DateMin).getTime(),cambioHora_1);
      // console.log('FECHA MAXIMA',DateMmax, new Date(DateMmax).getTime(),CambioHora_2);
      let a_ = 0
      if(Number(new Date(DateMin).getTime())>Number(cambioHora_1)){
        a_ = new Date(DateMin+"T00:00:00").setHours(-4);
      }else{
        a_ = new Date(DateMin+"T00:00:00").setHours(-3);
      }

      let b_ = 0
      if(Number(new Date(DateMmax).getTime())>Number(CambioHora_2)){
        b_ = new Date(DateMmax+"T23:59:59").setHours(-3);
      }else{
        b_ = new Date(DateMmax+"T23:59:59").setHours(-4);
      }
      let a = new Date(a_).getTime()
      let b = new Date(b_).getTime()
      
      if(b < a)alert('La fecha de Inicio no puede ser mayor a la Fecha final.')
      else{
        let resultFill = await BKPData.filter(flt=>Number(flt[0])>=Number(a) && Number(flt[0])<=Number(b)+hora);
        // console.log(resultFill[resultFill.length-1][1],resultFill[0][1],'$',FormatNumberCL((resultFill[resultFill.length-1][1]-resultFill[0][1])),'KW/H');
        setCalculoConsumoFiltrado(FormatNumberCL((resultFill[resultFill.length-1][1]-resultFill[0][1])),'KW/H');
        setIsVisbleCalculo(true)
        g = await [{
          name: title+" "+(DATA.ELEMENTO==="TEMPERATURA"?"❄":"💧"),
          data: resultFill
        }]
        await setstate({...state,["series"]:g})
      }
    }

  }

  const RollBack_ = async () => {
    setCalculoConsumoFiltrado('')
    let g = await [{
      name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
      data: BKPData
    }]
    await setstate({...state,["series"]:g})
    setIsRollBack(false)
  }

  useEffect(() => {
    // console.log('carga los datos del grafico');
    setIsVisbleFilter(false)
    UpdateChartNow()
    setInterval(() => {
      UpdateChartNow()
    }, 1000*60*1);
  
    return () => {
     UpdateChartNow() 
    }
  }, [])

  useEffect(() => {
    if(RollBack_.length>1){
      IsRollBack(true)
    }
  }, [BKPData])
  
  

  const options = {
    chart: {
      type: 'line',
      zoomType: 'x',
      height: '400px',
      resetZoomButton: {
        position: {
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -30,
            text: "zooom"
        }
      }
    },
    title: {
        text: title,
        style: {
          // fontSize:'small',
          fontWeight: 'bold',
          fontFamily: 'monospace',
          color: 'black'
        }
    },
    subtitle: {
        text: 'Lectura Instantanea',
        style: {
          // fontSize:'small',
          fontWeight: 'lighter',
          fontFamily: 'monospace',
          color: 'gray'
        }
    },
    xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the year
            month: '%e. %b',
            year: '%b'
        },
        title: {
            text: ''
        }
    },
    legend: {
      align: 'left',
        verticalAlign: 'top',
        borderWidth: 0
    },
    yAxis: {
        title: {
          text: `Consumos ${title} por ${unidad_med}`
        },
        plotLines: [{
          color: 'orange',
          width: 1,
          value: DATA.MAX,
          animation: {
              duration: 1000,
              defer: 4000
          },label: {
            text: 'PRECAUCION',
            align: 'right',
            x: -130,
            style: {
              color: 'orange',
              fontWeight: 'bolder',
              fontFamily: 'monospace',
            }
          },
        },
        {
          color: 'red',
          width: 1,
          value: DATA.MEDIO,
          animation: {
              duration: 1000,
              defer: 4000
          },label: {
            text: 'ADVERTENCIA',
            align: 'right',
            x: -130,
            style: {
              // color:'red',
              fontWeight: 'bold',
              fontFamily: 'monospace',
            }
          },
        }],
        // min: 0
    },
    tooltip: {
        formatter: function() {
          // let d = new Date()
          let d_ = new Date(this.x)
          // console.log(d_)
          return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Consumo: <b>' + FormatNumberCL(this.y) + " " + unidad_med + '</b>';
        },
        // headerFormat: '<b>{series.name}</b><br>',
        // pointFormat: '{point.x}: {point.y:.2f} m³',
        // crosshairs: true,
        // shared: true
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          radius: 2
        }
      },
    },

    series: [{
      name: 'Flujometro',
      data:
      [
        [Date.UTC(2023, 0,  3), 0.39],
        [Date.UTC(2023, 0,  6), 0.39],
        [Date.UTC(2023, 0,  9), 0.39],
        [Date.UTC(2023, 0,  9), 0.99],
        [Date.UTC(2023, 0, 11), 0.19],
        [Date.UTC(2023, 0, 13), 0.99],
        [Date.UTC(2023, 0, 13), 0.39],
        [Date.UTC(2023, 0, 16), 0.39],
        [Date.UTC(2023, 0, 19), 0.39]
      ]
    }]
  }

  if(IsREnder){
    return (
      <>
        <HighchartsReact
          key={DATA._id}
          highcharts={Highcharts}
          options={state||[]}
        />
        {
          IsVisbleFilter?
          <div className='datemodalcum-chart-OUR'>
            {
              IsRollBack?<div className='div-dmf'>
                <RotateLeftTwoToneIcon onClick={()=>RollBack_()} fontSize='large' color='secondary' className='restablecer' />
              </div>:null
            }
            
            <div className='div-dmf'>
              <input
                type='date'
                title='FECHA INICIO'
                // value={DateMin}
                className='input-fecha-filter'
                onChange={(e)=>setDateMin(e.target.value)}
                min={MinMaxDataRange[0]}
                max={MinMaxDataRange[1]}
              />
              <span className='txt-input-fecha-filter'>Fecha Inicio</span>
            </div>
            <div className='div-dmf'>
              <input
                type='date'
                title='FECHA INICIO'
                // value={DateMmax}
                className='input-fecha-filter'
                onChange={(e)=>setDateMmax(e.target.value)}
                min={MinMaxDataRange[0]}
                max={MinMaxDataRange[1]}
              />
              <span className='txt-input-fecha-filter'>Fecha Fin</span>
            </div>
            <div className='div-dmf'>
              <button className='btn btn-info mt-1 pt-1' onClick={()=>FilterByDataRange()}>buscar</button>
            </div>
            {/* {
              CalculoConsumoFiltrado===''?null:
              <div className='' style={{position:'absolute', bottom: '-10px', paddingLeft: '25%'}}><b>El consumo es {CalculoConsumoFiltrado}KW/H</b></div>
            } */}
          </div>:null
        }
        
      </>
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>Cargando ..</h5>
          </div>
        </div>
      </div>
    )
  }
}

let a = [
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 1","MEDIDOR":"T_TUNEL_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 2 3","MEDIDOR":"T_TUNEL_2_3","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 4","MEDIDOR":"T_TUNEL_4","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 5","MEDIDOR":"T_TUNEL_5","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 6","MEDIDOR":"T_TUNEL_6","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 9","MEDIDOR":"T_TUNEL_9","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 10","MEDIDOR":"T_TUNEL_10","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 11","MEDIDOR":"T_TUNEL_11","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TUNEL 12","MEDIDOR":"T_TUNEL_12","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 208","MEDIDOR":"T_CAMARA_208","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SKAGINN","MEDIDOR":"T_SKAGINN","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"STARFROST 1","MEDIDOR":"T_STARFROST_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"STARFROST 2","MEDIDOR":"T_STARFROST_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO A B","MEDIDOR":"T_PASILLO_A_B","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO C D","MEDIDOR":"T_PASILLO_C_D","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 207","MEDIDOR":"T_CAMARA_207","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 202","MEDIDOR":"T_CAMARA_202","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 201 PONIENTE","MEDIDOR":"T_CAMARA_201_PONIENTE","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA 201 ORIENTE","MEDIDOR":"T_CAMARA_201_ORIENTE","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SALA CALIBRADO","MEDIDOR":"T_SALA_CALIBRADO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CAMARA BIVALENTE","MEDIDOR":"T_CAMARA_BIVALENTE","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SALA DROT POIN EMBOLSADO","MEDIDOR":"T_SALA_DROT_POIN_EMBOLSADO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SALA TROZADO DE PAVOS","MEDIDOR":"T_SALA_TROZADO_DE_PAVOS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO 1 CAMARA 304","MEDIDOR":"T_PASILLO_1_CAMARA_304","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO 2 CAMARA 304","MEDIDOR":"T_PASILLO_2_CAMARA_304","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PASILLO 3 CAMARA 304","MEDIDOR":"T_PASILLO_3_CAMARA_304","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"AGUA FRIA OSMOSIS","MEDIDOR":"T_AGUA_FRIA_OSMOSIS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"AGUA PLACA BANCO A","MEDIDOR":"T_AGUA_PLACA_BANCO_A","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"AGUA PLACA BANCO B","MEDIDOR":"T_AGUA_PLACA_BANCO_B","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"AGUA PLACA BANCO C","MEDIDOR":"T_AGUA_PLACA_BANCO_C","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"C","ELEMENTO":"TEMPERATURA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BAJA 1","MEDIDOR":"N_RECIRCULADOR_BAJA_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR ALTA 1","MEDIDOR":"N_RECIRCULADOR_ALTA_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BAJA 2","MEDIDOR":"N_RECIRCULADOR_BAJA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR  ALTA 2","MEDIDOR":"N_RECIRCULADOR__ALTA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BAJA 3","MEDIDOR":"N_RECIRCULADOR_BAJA_3","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR IQF","MEDIDOR":"N_RECIRCULADOR_IQF","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR SECUNDARIO","MEDIDOR":"N_RECIRCULADOR_SECUNDARIO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR TRAMPA","MEDIDOR":"N_RECIRCULADOR_TRAMPA","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BOOSTER SADEMA 1","MEDIDOR":"N_RECIRCULADOR_BOOSTER_SADEMA_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR BOOSTER SADEMA 2","MEDIDOR":"N_RECIRCULADOR_BOOSTER_SADEMA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"RECIRCULADOR IQF SADEMA 2","MEDIDOR":"N_RECIRCULADOR_IQF_SADEMA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE DE AGUA DE POZO","MEDIDOR":"N_ESTANQUE_DE_AGUA_DE_POZO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE ECUALIZADOR 1","MEDIDOR":"N_ESTANQUE_ECUALIZADOR_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE ECUALIZADOR 2","MEDIDOR":"N_ESTANQUE_ECUALIZADOR_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE RIL CRUDO","MEDIDOR":"N_ESTANQUE_RIL_CRUDO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE RIL FILTRADO","MEDIDOR":"N_ESTANQUE_RIL_FILTRADO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"%","ELEMENTO":"NIVEL","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CALDERA","MEDIDOR":"F_CALDERA","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CALDERA","MEDIDOR":"F_CALDERA","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE 25M","MEDIDOR":"F_ESTANQUE_25M","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ESTANQUE 25M","MEDIDOR":"F_ESTANQUE_25M","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO KUCMAQ","MEDIDOR":"F_PERACETICO_KUCMAQ","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO KUCMAQ","MEDIDOR":"F_PERACETICO_KUCMAQ","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO PROCESOS","MEDIDOR":"F_PERACETICO_PROCESOS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO PROCESOS","MEDIDOR":"F_PERACETICO_PROCESOS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO GARRAS","MEDIDOR":"F_PERACETICO_GARRAS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PERACETICO GARRAS","MEDIDOR":"F_PERACETICO_GARRAS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PLANTA DE TRATAMIENTO DAF","MEDIDOR":"F_PLANTA_DE_TRATAMIENTO_DAF","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PLANTA DE TRATAMIENTO DAF","MEDIDOR":"F_PLANTA_DE_TRATAMIENTO_DAF","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"MATADERO DE PAVO","MEDIDOR":"F_MATADERO_DE_PAVO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"MATADERO DE PAVO","MEDIDOR":"F_MATADERO_DE_PAVO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"MENUDENCIAS","MEDIDOR":"F_MENUDENCIAS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"MENUDENCIAS","MEDIDOR":"F_MENUDENCIAS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"BANCO DE AGUA","MEDIDOR":"F_BANCO_DE_AGUA","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"BANCO DE AGUA","MEDIDOR":"F_BANCO_DE_AGUA","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"EVISCERADO DE PAVOS","MEDIDOR":"F_EVISCERADO_DE_PAVOS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"EVISCERADO DE PAVOS","MEDIDOR":"F_EVISCERADO_DE_PAVOS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"APV","MEDIDOR":"F_APV","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"APV","MEDIDOR":"F_APV","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ANILLO PRESION","MEDIDOR":"F_ANILLO_PRESION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"ANILLO PRESION","MEDIDOR":"F_ANILLO_PRESION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PANTALON POLLO","MEDIDOR":"F_PANTALON_POLLO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PANTALON POLLO","MEDIDOR":"F_PANTALON_POLLO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PANTALON PAVO","MEDIDOR":"F_PANTALON_PAVO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"PANTALON PAVO","MEDIDOR":"F_PANTALON_PAVO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"BANDEJAS","MEDIDOR":"F_BANDEJAS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"BANDEJAS","MEDIDOR":"F_BANDEJAS","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"m3/h","ELEMENTO":"FLUJOMETRO","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"GENERADORES","MEDIDOR":"E_GENERADORES","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"GENERADORES","MEDIDOR":"E_GENERADORES","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CONDENSADORES","MEDIDOR":"E_CONDENSADORES","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CONDENSADORES","MEDIDOR":"E_CONDENSADORES","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 1 SUBESTACION","MEDIDOR":"E_TRAFO_1_SUBESTACION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 1 SUBESTACION","MEDIDOR":"E_TRAFO_1_SUBESTACION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 2 SUBESTACION","MEDIDOR":"E_TRAFO_2_SUBESTACION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 2 SUBESTACION","MEDIDOR":"E_TRAFO_2_SUBESTACION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 3 SUBESTACION","MEDIDOR":"E_TRAFO_3_SUBESTACION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 3 SUBESTACION","MEDIDOR":"E_TRAFO_3_SUBESTACION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 4 SUBESTACION","MEDIDOR":"E_TRAFO_4_SUBESTACION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 4 SUBESTACION","MEDIDOR":"E_TRAFO_4_SUBESTACION","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 5 SUBESTACION MATADERO","MEDIDOR":"E_TRAFO_5_SUBESTACION_MATADERO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 5 SUBESTACION MATADERO","MEDIDOR":"E_TRAFO_5_SUBESTACION_MATADERO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 6 PLANTA DE TRATAMIENTO","MEDIDOR":"E_TRAFO_6_PLANTA_DE_TRATAMIENTO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 6 PLANTA DE TRATAMIENTO","MEDIDOR":"E_TRAFO_6_PLANTA_DE_TRATAMIENTO","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 7 SUBESTACION SADEMA 2","MEDIDOR":"E_TRAFO_7_SUBESTACION_SADEMA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"kW","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"TRAFO 7 SUBESTACION SADEMA 2","MEDIDOR":"E_TRAFO_7_SUBESTACION_SADEMA_2","TIPO":"ACUMULATIVO","SENSOR":"m2","UNIDAD":"kWh","ELEMENTO":"ENERGIA","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"CLORO SALA DE BOMBAS","MEDIDOR":"A_CLORO_SALA_DE_BOMBAS","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 1","MEDIDOR":"A_SENSOR_DE_AMONIACO_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 2","MEDIDOR":"A_SENSOR_DE_AMONIACO_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 3","MEDIDOR":"A_SENSOR_DE_AMONIACO_3","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 4","MEDIDOR":"A_SENSOR_DE_AMONIACO_4","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 5","MEDIDOR":"A_SENSOR_DE_AMONIACO_5","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 6","MEDIDOR":"A_SENSOR_DE_AMONIACO_6","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 7","MEDIDOR":"A_SENSOR_DE_AMONIACO_7","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 8","MEDIDOR":"A_SENSOR_DE_AMONIACO_8","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 9","MEDIDOR":"A_SENSOR_DE_AMONIACO_9","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 10","MEDIDOR":"A_SENSOR_DE_AMONIACO_10","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 11","MEDIDOR":"A_SENSOR_DE_AMONIACO_11","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"SENSOR DE AMONIACO 12","MEDIDOR":"A_SENSOR_DE_AMONIACO_12","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"ppm","ELEMENTO":"A_PPM","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"DESCARGA SADEMA 1","MEDIDOR":"P_DESCARGA_SADEMA_1","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"DESCARGA SADEMA 2","MEDIDOR":"P_DESCARGA_SADEMA_2","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"LINEA DE ALIMENTACION DE AGUA POZO","MEDIDOR":"P_LINEA_DE_ALIMENTACION_DE_AGUA_POZO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"LINEA DE ALIMENTACION DE AGUA POTABLE","MEDIDOR":"P_LINEA_DE_ALIMENTACION_DE_AGUA_POTABLE","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"LINEA ANILLO PRESION","MEDIDOR":"P_LINEA_ANILLO_PRESION","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120},
  {"CONTRATO":"MARCUXO","FUENTE":"LINEA AIRE COMPRIMIDO","MEDIDOR":"P_LINEA_AIRE_COMPRIMIDO","TIPO":"INSTANTANEO","SENSOR":"m1","UNIDAD":"BAR","ELEMENTO":"PRESION","EMPRESA":"AGROINDUSTRIAL EL PAICO S.A.","STATE":true,"MEDIO":70,"MAX":120}
]