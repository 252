import { URL_SRV_ARIZTIA } from '../router/Url';

export const LecturaEsmartOurRangeTotalizador = (query_) => {//addsuscripcion
    // console.log("query",query_)

    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'getlecturaesmartour_range',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(query_)
        })
        let responsito = await query.json();
        // console.log(responsito.body)
        let clearData = await (responsito.body.Dataitem).map(ulm =>{
          let {caudal, timestamp} = ulm
          return {valor: Number(caudal), timestamp: timestamp}
        })
        if(responsito.success){
          resolve(clearData)
        }else{
          resolve([])
        }
      })
}
