import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import GaugeOne from '../../components/charts/GaugeOne.chart'
import LoginContext from '../../context/login_context/LoginContext'
import { LineFour } from '../../components/charts/LineFour.chart'
import { OUR_IndicadorAmoniaco } from '../../components/charts/OUR_IndicadorAmoniaco'

import * as htmlToImage from 'html-to-image';
import GaugeOneElectric from '../../components/charts/GaugeOneElectric.chart'
import { LineFive } from '../../components/charts/LineFive.chart'
import GaugeOneElectricFijo from '../../components/charts/GaugeOneElectricFijo.chart'
import { GetListMEdidores } from '../../API/OUR_Lecturas/GetListMEdidores.api'
import OUR_GaugeOneTest from '../../components/charts/OUR_GaugeOneTest.chart'
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';



//CONSTANTES

export const OUR_GaugeTest = () => {

    const [ListMedidores_, setListMedidores_] = useState([]);
    const [ShowmetheModal_, setShowmetheModal_] = useState(false);
    const [ShowmetheModal_ACUM, setShowmetheModal_ACUM] = useState(false);
    const [MedidorToModal, setMedidorToModal] = useState('');

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    // console.log(LoginState.planta)

    const getIMG = async (id) => {
        var node = document.getElementById(id);
        
    
        htmlToImage.toSvg(node)
        .then(function (dataUrl) {
          
            // console.log(dataUrl);
            let anchor = document.createElement("a")
            anchor.href = dataUrl
            anchor.download = "MyQRIsHere.png"
            document.body.appendChild(anchor)
            anchor.click()
            document.body.removeChild(anchor)
        
            // bringMeTheCode()
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
      }

    const GetListMedidores = async () => {
        let list_ = await GetListMEdidores({empresa:LoginState.planta})
        // let listFil = await list_["m1"].filter(fl => fl.ELEMENTO === ""PRESION"")
        // let listFil_ = await list_["ai"].filter(fl => fl.ELEMENTO === ""NIVEL"")
        // let concatenar_ = await listFil.concat(listFil_)
        // console.log(list_);
        setListMedidores_(list_);
        return (<div>menu flotante</div>)
    }

    const ShowModalData = async (data) => {
        // console.log(data);
        data["SENSOR"] = "m1"
        setMedidorToModal(data);
        setShowmetheModal_(true)
    }

    const ShowModalData_ACUM = async (data) => {
        // console.log(data);
        setMedidorToModal(data);
        setShowmetheModal_ACUM(true)
    }

    useEffect(() => {
        GetListMedidores()
        return () => {
            GetListMedidores()
        }
    }, [])

    return (
        <>
            <HeaderMenu Pag_Sel={'ourgaugetest'} />

            <div className='container'>
                <div className='row'>
                    {ListMedidores_.map((ulx, index) => (
                        <div key={index} className="col-4 text-center">
                        <span className='lead'>{ulx.TIPO}</span>
                        {ulx.MEDIDOR.map((med, index) => (
                            <div className="col-12" key={index}>
                                {
                                    ulx.TIPO==="AMONIACO"?<OUR_IndicadorAmoniaco DATA={med} />:<OUR_GaugeOneTest DATA={med} />
                                }
                                {/* {console.log(med)} */}
                            </div>
                        ))}
                        </div>
                    ))}
                </div>
            </div>
                {/* <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 text-center'>
                        <button className='btn w-25 btn-info btn-sm' onClick={()=>CloseMenuSettings(!SettingsMenu)}>Cerrar</button> 
                        </div>
                        <div className='col-12 py-2 mt-2 card-chart-line'>
                            <div className='row'>

                                {
                                    ListMedidores_.map(cl => (
                                    <div key={cl._id} id={cl._id} className='calcule-chart-space'
                                        style={{cursor: 'pointer'}}
                                    >
                                        <OUR_GaugeOneTest
                                            key={cl._id+"01"}
                                            DATA={cl}
                                            ShowModalData={ShowModalData}
                                            ShowModalData_ACUM={ShowModalData_ACUM}
                                            />
                                    </div>
                                    ))
                                }

                            </div>
                        </div>

                        
                    </div>
                </div> */}

                {
                    ShowmetheModal_?
                        <div className='modal_chdata'>
                            <button
                                onClick={()=>setShowmetheModal_(false)}
                                className='btn btn-danger btn-sm'
                                style={{position: 'absolute', top: '10px',left: '50%'}}
                                >Cerrar</button>
                            <div className='bg-info w-100 text-center'>
                                <LineFour DATA={MedidorToModal} />
                                {/* <LineFive DATA={MedidorToModal} /> */}
                            </div>
                        </div>
                    :null
                }

                {
                    ShowmetheModal_ACUM?
                        <div className='modal_chdata'>
                            <button
                                onClick={()=>setShowmetheModal_ACUM(false)}
                                className='btn btn-danger btn-sm'
                                style={{position: 'absolute', top: '10px',left: '50%', zIndex: 999}}
                                >Cerrar</button>
                            <div className='bg-info w-100 text-center'>
                                {/* <LineFour DATA={MedidorToModal} /> */}
                                <LineFive DATA={MedidorToModal} />
                            </div>
                        </div>
                    :null
                }
        </>
    )
}
