import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import LoginContext from '../../context/login_context/LoginContext';
import { GetElementosList } from '../../API/GetElementosList.api';
import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from '@mui/material';
import { ListMedidores } from '../../API/ListMedidores.api';
import { AddSuscripcion } from '../../API/AddSuscripcion.api';
import { QuitarSuscripcion } from '../../API/QuitarSuscripcion.api';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const Exportador = () => {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  // console.log(LoginState.rol)

  const [Intervalo_, setIntervalo_] = useState("Lunes");
  const [ListEmentos, setListEmentos] = useState([]);
  const [SelElemento, setSelElemento] = useState("ELECTRICO");
  const [Select_med_list, setSelect_med_list] = useState([]);
  const [Select_med_listFilter, setSelect_med_listFilter] = useState([]);
  const [isSelectedMedList, setisSelectedMedList] = useState([]);

  const AsyncGet_elementos = async () => {
    let list = await GetElementosList({empresa:LoginState.planta})
    setListEmentos(list)
  }

  const GetListOfMedidoresNespra = async () => {
    let mediList = await ListMedidores({empresa:LoginState.planta})
    // console.log(mediList)
    let parList = await mediList["m2"].map(adf =>{
      let {_id,MEDIDOR,TIPO,ELEMENTO} = adf
      return {_id,MEDIDOR,TIPO,ELEMENTO}
    })
    // console.log(parList)
    setSelect_med_list(parList)
  }

  const SuscribeteYdejaTuLike = async () => {
    let laCampanita = await AddSuscripcion({
      planta:LoginState.planta,
      intervalo: Intervalo_,
      elemento:SelElemento,
      medidores:isSelectedMedList,
      correo: LoginState.rol
    })
    if(laCampanita){
      setIntervalo_("Lunes")
      setSelElemento("ELECTRICO")
      setisSelectedMedList([])
    }
    alert(laCampanita.message)
  }

  const QuitTheSusciption = async () => {
    let NoTeVaigasChavo = await QuitarSuscripcion({correo: LoginState.rol})
    alert(NoTeVaigasChavo.message)
    // console.log(NoTeVaigasChavo)
  }

  const handleChange = (event) => {
    // console.log(event)
    const {
      target: { value },
    } = event;
    setisSelectedMedList(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    let filtro = Select_med_list.filter(fl=>fl.ELEMENTO===SelElemento)
    setSelect_med_listFilter(filtro)
    return () => {
    }
  }, [Select_med_list])
  
  useEffect(() => {
    AsyncGet_elementos()
    GetListOfMedidoresNespra()
    return () => {
      AsyncGet_elementos()
      GetListOfMedidoresNespra()
    }
  }, [])

  useEffect(() => {
    // console.log(Select_med_list)
    setisSelectedMedList([])
    if(SelElemento==="ELECTRICO"||SelElemento==="HIDRICO"){
      setSelect_med_listFilter(Select_med_list.filter(fl=>fl.ELEMENTO===SelElemento))
    }else{
      setSelect_med_listFilter(Select_med_list)
    }
  }, [SelElemento])
  
  return (
    <section>
			<HeaderMenu Pag_Sel={'exportar'}/>

      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-center asdfgh'>
            <label>Selecciona intervalo</label><br/>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e)=>setIntervalo_(e.target.value)}
                value={Intervalo_}
              >
                <FormControlLabel style={{fontSize: 'small'}}value="Lunes" control={<Radio size='small' />} label="Lunes" labelPlacement="top" />
                <FormControlLabel value="Martes" control={<Radio size='small' />} label="Martes" labelPlacement="top" />
                <FormControlLabel value="Miercoles" control={<Radio size='small' />} label="Miércoles" labelPlacement="top"  />
                <FormControlLabel value="Jueves" control={<Radio size='small' />} label="Jueves" labelPlacement="top" />
                <FormControlLabel value="Viernes" control={<Radio size='small' />} label="Viernes" labelPlacement="top" />
                <FormControlLabel value="Sabado" control={<Radio size='small' />} label="Sábado" labelPlacement="top" />
                <FormControlLabel value="Domingo" control={<Radio size='small' />} label="Domingo" labelPlacement="top" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='col-12 col-md-5 text-center asdfgh'>
            <label>Seleccione grupo</label><br/>
              <FormControl>
                <RadioGroup
                  aria-labelledby="rb-elemento"
                  name="rb-elemento"
                  onChange={(e)=>setSelElemento(e.target.value)}
                  value={SelElemento}
                >
                  {
                    ListEmentos.map(adi=><FormControlLabel key={adi} value={adi} control={<Radio size='small' />} label={adi} labelPlacement="end" />)
                  }
                  <FormControlLabel value={"SELECCIONAR"} control={<Radio size='small' />} label={"SELECCIONAR MEDIDOR"} labelPlacement="end" />
                </RadioGroup>
              </FormControl>
          </div>
          <div className='col-12 col-md-7 pt-3 asdfgh'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Seleccione Medidor(es)</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={isSelectedMedList}
                onChange={handleChange}
                input={<OutlinedInput label="Seleccione Medidor(es)" />}
                // renderValue={(selected) => selected.join(', ')}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Select_med_listFilter.map((name) => (
                  <MenuItem key={name.MEDIDOR} value={name.MEDIDOR}>
                    <Checkbox checked={isSelectedMedList.indexOf(name.MEDIDOR) > -1} />
                    <ListItemText primary={name.MEDIDOR} />
                  </MenuItem>
                  
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='col-12 text-center mt-3'>
            <div className='row'>
              <div className='col-12 col-md-6 mt-2'>
                <button className='btn btn-sm btn-danger ' onClick={()=>QuitTheSusciption()}>Eliminar suscripcion</button>
              </div>
              <div className='col-12 col-md-6 mt-2'>
                <button className='btn btn-success ' onClick={()=>SuscribeteYdejaTuLike()}>Suscribirse!!</button>
              </div>
            </div>
          </div>
          <div className='col-12 text-center mt-3 bg-warning mt-5'>
            <small className='text-danger'><b>El Reporte se enviará automáticamente a su correo en el día seleccionado a las 07:00 am, y recopilará la información correspondiente a una semana desde el día en que se genere el reporte.</b></small>
          </div>
        </div>
      </div>
      {/* <button onClick={()=>console.log(isSelectedMedList)}>click</button> */}
    </section>
  )
}
