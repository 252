import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { UpdateDataLectura } from '../../API/UpdateDataLectura.api'
import { GetTotalizadorFromDB } from '../../API/POST/GetTotalizadorFromDB.api'
import { FormatNumberCL } from '../../mutations/FormatNumeral.Mutation'
import { GetLecturaActual } from '../../API/OUR_Lecturas/GetLecturaActual.api'
import { GetLecturaActual_M2 } from '../../API/OUR_Lecturas/GetLecturaActual_M2.api'

let factor = 1.7

export const ShowAcumulador = ({medidor_,valor, ShowModalData_ACUM, DATA_, OPTION_}) => {
  // console.log('DATA',OPTION_.series[0].data[0]);
  if(medidor_!=="EMPALME 1" && medidor_!=="EMPALME 2" && medidor_!=="SUMA TRAFOS" && medidor_!=="V_EMPALME_1"){
    return (
      <>
        <small className='medida-float-fijo' ><b>{FormatNumberCL(Number(factor*OPTION_.series[0].data[0]).toFixed(0))} A</b></small><br/>
        <small className='medida-float-fijo' onClick={()=>ShowModalData_ACUM(DATA_)}><b>{valor}</b></small>
      </>
    )
  }else null
}

function GaugeOneElectric({DATA, ShowModalData, ShowModalData_ACUM}) {
  // console.log(DATA)
  let max = DATA?.MAX
  let a = max / 5
  let b = (a*3).toFixed(0)
  let c = (a*2).toFixed(0)
  let d = c/2
  let v1 = 0
  let v2 = b
  let m1 = b
  let m2 = DATA?.MEDIO
  let r1 = DATA?.MEDIO
  let r2 = max
  // console.log("rangos",v1,v2,m1,m2,r1,r2)
  const options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '230px',//'100%',
    },
    title: {
      text: DATA.MEDIDOR,
      style: {
        fontWeight: 'lighter',
        fontFamily: 'monospace',
        color: 'red'
      }
    },

    pane: {
        startAngle: -125,
        endAngle: 125,
        background: null,
        center: ['50%', '60%'],//['50%', '75%'],
        size: '90%'
    },

    // the value axis
    yAxis: {
        min: 0,
        max: DATA?.MAX,//valor maximo del grafico
        tickPixelInterval: 50,//separador de cuadros en angulo
        tickPosition: 'inside',
        tickColor: 'white',//color separador de cuadros en angulo
        tickLength: 15,//tamaño linea separadora de cuadros en angulo
        tickWidth: 1,//ancho de linea separadora de cuadros en angulo
        minorTickInterval: null,
        // minorTickColor: 'white',
        // minorTickLength:5,
        labels: {
            distance: 11,
            style: {
                fontSize: '12px',
            }
        },
        title: {
          text: ""
        },
        lineWidth: 0,// grosor linea de borde angulo
        plotBands: [{
            from: v1,
            to: v2,
            color: '#55BF3B', // green
            thickness: 30,
          }, {
              from: m1,
              to: m2,
              color: '#DDDF0D', // yellow
              thickness: 30
          }, {
              from: r1,
              to: r2,
              color: 'red', // red
              thickness: 30
          }
        ]
    },

    series: [{
        name: 'Consumo',
        data: [155],
        tooltip: {
            valueSuffix: ' --'//unidad de medica precargada
        },
        dataLabels: {
            format: '{y} --',//unidad de medica precargada
            borderWidth: 0,//borde del numero indicador
            color: (
                Highcharts.defaultOptions.title &&
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
            ) || '#000000',
            style: {
                fontSize: '16px'
            }
        },
        dial: {
            radius: '90%',//largo del indicacor central
            backgroundColor: 'black',//color del indicador
            baseWidth: 10,
            baseLength: "0%",
            rearLength: '0%'
        },
        pivot: {
            backgroundColor: 'black',
            radius: 5
        }

    }],
    responsive: {
      rules: [{
          condition: {
              // maxWidth: 500
              minHeight: 238
          },
          chartOptions: {
              legend: {
                  align: 'center',
                  verticalAlign: 'bottom',
                  layout: 'horizontal'
              },
              yAxis: {
                  labels: {
                      align: 'left',
                      x: 0,
                      y: -5
                  },
                  title: {
                      text: null
                  }
              },
              subtitle: {
                  text: null
              },
              credits: {
                  enabled: false
              }
          }
      }]
  }
  
  }
  const [state, setstate] = useState(options);
  const [MaxValue, setMaxValue] = useState(0);
  const [IsREnder, setIsREnder] = useState(false);
  const [LoadText, setLoadText] = useState("Cargando ...");
  const [ValorAcumulador, setValorAcumulador] = useState("");
  const [LastFecha, setLastFecha] = useState("");


 useLayoutEffect( ( ) => {
  // console.log('===========>',Highcharts)
    highchartsMore(Highcharts);
    // solidGauge(Highcharts);
    // console.log("este es el maximo",DATA.MAX)
    // setstate(options)
    return () => {
      highchartsMore(Highcharts);
    };
  },[])

  const UpgradeDataChart = async () => {//actualiza el grafico
    let datas = null
    let control = new Date()
    if(DATA){
      if(DATA.CONTRATO === "MARCUXO"){
        let datas_ = await GetLecturaActual({DATA})
        let coreccion = datas_.map((item)=>{
          let {valor,timestamp} = item
          return {TimeStamp:timestamp, [DATA.SENSOR]:valor}
        })
        datas = coreccion
        // console.log(coreccion)
      }else{
        datas = await UpdateDataLectura({SENSOR:DATA.SENSOR, N_MED:DATA.N_MED,DATA})
        // console.log(datas)
      }

      // console.log(control.toUTCString())
      //1717166083000
      //1717165800588
      let timestp = String(datas[0].TimeStamp).length===10?Number(datas[0].TimeStamp+'000'):datas[0].TimeStamp
      setLastFecha(new Date(timestp).toLocaleString())
      if(datas?.error){
        setLoadText(datas.msg)
      }else{
        let FormatFecha = new Date(control.setUTCSeconds(Math.floor(datas[0].TimeStamp/1000000)))
        // console.log(FormatFecha.toISOString())
        const options = {
        chart: {
          type: 'gauge',
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: '230px',//'100%'
          events: {
            click: function(e) {
              ShowModalData(DATA)
            }
          },
        },
        title: {
          text: DATA.MEDIDOR,
          style: {
            fontSize:'small',
            fontWeight: 'bolder',
            fontFamily: 'monospace',
            color: 'black'
          }
        },
        subtitle:{
          text: DATA.TIPO,
          style: {
            fontSize:'small',
            fontWeight: 'lighter',
            fontFamily: 'monospace',
            color: 'gray'
          }
        },
    
        pane: {
            startAngle: -125,
            endAngle: 125,
            background: null,
            center: ['50%', '60%'],//['50%', '75%'],
            size: '90%'
        },
    
        // the value axis
        yAxis: {
          min: 0,
          max: DATA.MAX,//valor maximo del grafico
          tickPixelInterval: 50,//separador de cuadros en angulo
          tickPosition: 'inside',
          tickColor: 'white',//color separador de cuadros en angulo
          tickLength: 15,//tamaño linea separadora de cuadros en angulo
          tickWidth: 1,//ancho de linea separadora de cuadros en angulo
          minorTickInterval: null,
          // minorTickColor: 'white',
          // minorTickLength:5,
          labels: {
              distance: 11,
              style: {
                  fontSize: '12px',
              }
          },
          title: {
            text: DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧",//💦💨texto dentro del grafico gauge
            y: 55,
            // x: 120,
            style: {
              // marginTop: '25px',
              paddingTop:'50px',
              // position: 'relative'
            }
          },
          lineWidth: 0,// grosor linea de borde angulo
          plotBands: [{
            from: v1,
            to: v2,
            color: '#55BF3B', // green
            thickness: 30,
            }, {
              from: m1,
              to: m2,
              color: '#DDDF0D', // yellow
              thickness: 30
            }, {
              from: r1,
              to: r2,
              color: 'red', // red
              thickness: 30
            }
          ]
        },
        plotBackgroundColor: {
          radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
          stops: [
             [0, '#003399'],
             [1, '#3366AA']
          ]
      },
    
        series: [{
          name: 'Consumo',
          data: [datas[0][DATA.SENSOR]],//[Math.floor(Math.random() * 180)],-1*(datas[0][DATA.SENSOR])
          tooltip: {
              valueSuffix: ` ${DATA.UNIDAD}`
          },
          dataLabels: {
              format: `{y} ${DATA.UNIDAD}`,//' m³/h',
              borderWidth: 0,//borde del numero indicador
              color: (
                  Highcharts.defaultOptions.title &&
                  Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color
              ) || '#333333',
              style: {
                  fontSize: '16px'
              }
          },
          dial: {
              radius: '90%',//largo del indicacor central
              backgroundColor: 'black',//color del indicador
              baseWidth: 10,
              baseLength: "0%",
              rearLength: '0%'
          },
          pivot: {
              backgroundColor: 'black',
              radius: 5
          }
  
        }],
        responsive: {
          rules: [{
              condition: {
                  // maxWidth: 500
                  minHeight: 238
              },
              chartOptions: {
                  legend: {
                      align: 'center',
                      verticalAlign: 'bottom',
                      layout: 'horizontal'
                  },
                  yAxis: {
                      labels: {
                          align: 'left',
                          x: 0,
                          y: -5
                      },
                      title: {
                          text: null
                      }
                  },
                  subtitle: {
                      text: null
                  },
                  credits: {
                      enabled: false
                  }
              }
          }]
      }
      
      }
     
      await setstate(options)
      // await setstate({...state,["series"]:the_series,["yAxis"]:yAxis})
      await setIsREnder(true)
      }
      
    }
  }

  const GetTotalizador = async () => {
    let totalizador = null
    if(DATA.MEDIDOR!=="EMPALME 1" && DATA.MEDIDOR!=="EMPALME 2" && DATA.MEDIDOR!=="SUMA TRAFOS" && DATA.MEDIDOR!=="V_EMPALME_1"){
      if(DATA.CONTRATO === "MARCUXO"){
        totalizador = await GetLecturaActual_M2({DATA})
      }else{
        totalizador = await GetTotalizadorFromDB({MEDIDOR: DATA.MEDIDOR, SENSOR: 'm2', EMPRESA:  DATA.EMPRESA})
        // console.log("en uso",totalizador)
      }

      // console.log(totalizador);
      if(totalizador===null)setValorAcumulador('-- -- KW/H')
      else setValorAcumulador(FormatNumberCL(totalizador.totalizador)+' KW/H');
    }

  }

  //setInterval
  useEffect(() => {
    UpgradeDataChart()
    GetTotalizador()
    let intervalo = setInterval(() => {
      UpgradeDataChart()
      GetTotalizador()      
    }, 1000*60*15);
    return () => {
      clearInterval(intervalo)
      UpgradeDataChart()
    };
  }, [])

  if(IsREnder){
    return (
      <div className="text-center p-0 m-0">
        <HighchartsReact
          key={DATA._id}
          highcharts={Highcharts}
          options={state||[]}
        />
        <ShowAcumulador medidor_={DATA.MEDIDOR} valor={ValorAcumulador} ShowModalData_ACUM={ShowModalData_ACUM} DATA_={DATA} OPTION_={state}/>
      </div>        
    )
  }else{
    return (
      <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
        <div className='row' style={{position: 'relative'}}>
          <div className='col text-center' style={{height: '230px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h5 className='loadding'>{LoadText}</h5>
          </div>
        </div>
      </div>
    )
  }
    

  
}

export default GaugeOneElectric