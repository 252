import React, { useContext, useEffect, useState } from "react";
import { HeaderMenu } from "../../components/HeaderMenu.comp";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { GetDataToFactureSelect } from "../../API/IngresoFactura/GetDataToFactureSelect.api";
import LoginContext from "../../context/login_context/LoginContext";
import { GetFactureToShowNow } from "../../API/IngresoFactura/GetFactureToShowNow.api";
import { FormatNumberCL } from "../../mutations/FormatNumeral.Mutation";

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';

export const FactureFileView = () => {
  const { LoginState, SetLogin, CloseLogin, GetLoginSession } = useContext(LoginContext);
  // console.log(LoginState.planta);

  const [Year, setYear] = useState([]);
  const [Month, setMonth] = useState([]);
  const [Tipe, setTipe] = useState([]);
  const [SelTipe, setSelTipe] = useState('');
  const [SelYear, setSelYear] = useState('');
  const [SelMonth, setSelMonth] = useState('');
  const [FacturasBulk, setFacturasBulk] = useState([]);
  const [SumeValues, setSumeValues] = useState(0);

  const FindFacturesNow = async () => {
    let fecha = SelMonth!==''?SelYear+'-'+SelMonth:SelYear
    let fact = await GetFactureToShowNow({EMPRESA:LoginState.planta, TIPECONSUME:SelTipe, FECHA:fecha})
    if(fact.length){
      let sume = 0
      for (let fc = 0; fc < fact.length; fc++) {
        const fc_ = fact[fc];
        sume +=fc_.VALOR;        
      }
      setSumeValues(sume)
    }
    setFacturasBulk(fact);
  }

  useEffect(() => {
    const ObtainData = async () => {
      const meses_str = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SEPTIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];
      let data = await GetDataToFactureSelect({EMPRESA:LoginState.planta, TIPO:SelTipe, ANNIO:SelYear})
      
      // console.log(data);
      if(data?.ANNIO && data?.MES && data?.TIPO){
        let meses = await data.MES.map(ms=> {
          let data = {"MES_N":ms,"MES_t":meses_str[Number(ms)-1]}
          return data
        })
        
        setYear(data?.ANNIO)
        setMonth(meses)
        setTipe(data?.TIPO)
      }
    }
    ObtainData()
    return () => {
      
    }
  }, [SelTipe, SelYear])
  
  return (
    <>
      <HeaderMenu Pag_Sel={"viewfactura"} />
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-md-4">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Tipo de Consumo</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tipo de Consumo"
                value={SelTipe}
                onChange={(e)=>setSelTipe(e.target.value)}
              >
                {
                  Tipe.map((tp,index)=><MenuItem key={index} style={{ fontFamily: "monospace" }} value={tp}>{tp}</MenuItem>)
                }
                
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-md-3">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Año</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Año"
                value={SelYear}
                onChange={(e)=>setSelYear(e.target.value)}
              >
                {
                  Year.map((yyyy, index)=><MenuItem key={index} style={{ fontFamily: "monospace" }} value={yyyy}>{yyyy}</MenuItem>)
                }
                
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-md-3">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Mes</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Mes"
                value={SelMonth}
                onChange={(e)=>setSelMonth(e.target.value)}
              >

                {
                  Month.map((mth, index)=><MenuItem key={index} style={{ fontFamily: "monospace" }} value={mth.MES_N}>{mth.MES_t}</MenuItem>)
                }
                
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-md-2">
            <button className="btn btn-info" onClick={()=>FindFacturesNow()}>Buscar</button>
          </div>

          {/* TABLA DE FACTURAS */}
          <div className="col-12 mt-5">
            <table className="table table-stripe table-hover table-sm" style={{fontSize: 'small'}}>
              <thead>
                <tr className="text-center">
                  <th scope="col">#</th>
                  <th scope="col">TIPO CONSUMO</th>
                  <th scope="col">FECHA</th>
                  <th scope="col">MONTO FACTURADO</th>
                  <th scope="col">FACTURA</th>
                </tr>
              </thead>
              <tbody>
              {
                FacturasBulk.map((adf,index)=>
                  <tr key={index} className="text-center">
                    <th scope="row">1</th>
                    <td>{adf.TIPECONSUME}</td>
                    <td>{adf.FECHA}</td>
                    <td>$ { FormatNumberCL(adf.VALOR)}</td>
                    <td><a target="_blank" href={'https://apimedidores.apidev.info/files/FACTURAS/'+adf.IMAGEN} ><InsertDriveFileTwoToneIcon color="secondary"/></a></td>
                  </tr>
                )
              }
                <tr className="text-center">
                  <th scope="row"></th>
                  <td></td>
                  <td>Total</td>
                  <td>$ {FormatNumberCL(SumeValues)}</td>
                  <td></td>
                </tr>
                
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </>
  );
};
