import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import LoginContext from '../../context/login_context/LoginContext';
import { CheckForUpdates } from '../../API/CheckForUpdates.api';
import { ActualizarMonitorFijo } from '../../API/MonitorFijo/ActualizarMonitorFijo.api';

export const ActualizadorMonitorFijo = () => {
    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
    const [user, setuser] = useState('');
    const [Update_old, setUpdate_old] = useState(null);
    // console.log(LoginState)

    const ObtenerUltimActualizacion = async () => {
      let chUpd = await CheckForUpdates({APP:"SSGGMONITOR"})
      setUpdate_old(chUpd)
    }

    const GeneraActualizacionPagina = async () => {
      // APP:"SSGGMONITOR"
      let chUpd_ = await ActualizarMonitorFijo({APP:"SSGGMONITOR"})
      ObtenerUltimActualizacion()
    }

    useEffect(() => {
      ObtenerUltimActualizacion()
    
      return () => {
        
      }
    }, [])

  if(LoginState.rol==="murrutiam@ariztia.com"){
    return (
      <>
      <HeaderMenu Pag_Sel={'actualizalo'} />
      <div className='container'>
        <div className='row'>
        <div className='col-12 mb-5'>
          <span className='display-4'>actualizador</span>
        </div>
          {
            Update_old?
              <div className='col'>
                <span className='lead'>Fecha Antigua Actualizacion: {new Date(Update_old.FECHA_OLD).toISOString()} V{Update_old.VERSION_OLD}</span><br/>
                <span className='lead'>Fecha Nueva Actualizacion: {new Date(Update_old.FECHA_NEW).toISOString()} V{Update_old.VERSION_NEW}</span><br/>
                <br/>
                <button className='btn btn-info btn-sm' onClick={()=>GeneraActualizacionPagina()}>Actualizar</button>
              </div>:null
          }
        </div>
      </div>
      </>
    )
  }else{
    return (
      <>
        <span className='lead'>Modulo en Creacion</span>
      </>
    )
  }
}
