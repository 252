import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import LoginContext from '../../context/login_context/LoginContext';
import { GetHeadersChart } from '../../API/GetHeadersChart.api';
import { GetDataToChartOfTemp } from '../../API/POST/GetDataToChartOfTemp';
import { LineThree } from '../../components/charts/LineThree.chart';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import RefreshIcon from '@mui/icons-material/Refresh';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import './index.css'
import { WithDateGetMachines } from '../../API/POST/WithDateGetMachines.api';
import { GetTheProducts_temp } from '../../API/POST/GetTheProducts_temp.api';

import { BsArrowsFullscreen } from "react-icons/bs";

const animatedComponents = makeAnimated();

const options = [
	{ value: 'chocolate', label: 'Chocolate' },
	{ value: 'strawberry', label: 'Strawberry' },
	{ value: 'vanilla', label: 'Vanilla' }
]

export const CtrlTemp = () => {
	/** context */
	const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
	// console.log(LoginState.planta);
	/** mutadores */
	const [Data, setData] = useState([]);
	const [SelectArr, setSelectArr] = useState([]);
	const [MaqSelect, setMaqSelect] = useState('');
	const [ProdSelect, setProdSelect] = useState('');
	const [FechaInicio, setFechaInicio] = useState('');
	const [FechaFinal, setFechaFinal] = useState('');

	const [MachinesList, setMachinesList] = useState([]);
	const [ProductsList, setProductsList] = useState([]);

	const [ProductsListMultiple, setProductsListMultiple] = useState([]);
	const [OptiosOfMultiple, setOptiosOfMultiple] = useState([]);

	/** metodos */

	/** obtiene los datos para graficar */
	const GetDataToChart = async (maqchine_evil) => {
		// console.log('active');
		let dataChart = await GetDataToChartOfTemp({FechaInicio, FechaFinal, empresa: LoginState.planta, MaqSelect, ProdSelect, ProductsListMultiple})
		setData(dataChart)
		// console.log(dataChart);
	}

	/** obtiene las maquinas encontradas basadas en el rango de fechas y la empresa */
	const GetMachinesWithDatesRange = async () => {
		let machines = await WithDateGetMachines({FechaInicio, FechaFinal, empresa: LoginState.planta})
		setMachinesList(machines);
	}

	/** basandose en la maquina seleccionada y los rangos de fechas devuelve los productos encontrados */
	const GetTheProducts= async () => {
		let products_list = await GetTheProducts_temp({FechaInicio, FechaFinal, empresa: LoginState.planta, MaqSelect})
		setProductsList(products_list);
		let multiList = await products_list.map(mlt =>{
			let {_id} = mlt
			return {value:_id, label: _id}
		})
		setOptiosOfMultiple(multiList);
	}

	const ChangedReactSelect = ( newValue, actionMeta ) => {
		// console.log(newValue)
		setProductsListMultiple(newValue)
	}

	const launchFullScreen = async (id) => {
		let container = document.getElementById(id)
		// console.log("full screen")
		if(container.requestFullScreen) {
			container.requestFullScreen();
		} else if(container.mozRequestFullScreen) {
			container.mozRequestFullScreen();
		} else if(container.webkitRequestFullScreen) {
			container.webkitRequestFullScreen();
		}
	}

	/** iniciadores y actuadores */
	// useEffect(() => {
	// 	GetCabeceras(MaqSelect)
	// 	let interval = setInterval(() => {
	// 		GetCabeceras(MaqSelect);			
	// 	}, 5000);
	// 	return () => {
	// 		clearInterval(interval)
	// 	}
	// }, [MaqSelect])

	useEffect(() => {
		if(FechaInicio === '' || FechaFinal === ''){
			// console.log('las fechas estan vacias');
			return
		}else{
			GetMachinesWithDatesRange()
		}
		if(MaqSelect !== ''){
			GetTheProducts()
		}
	return () => {
		// GetMachinesWithDatesRange()
	}
	}, [FechaInicio, FechaFinal, MaqSelect])

	useEffect(() => {
		setData([])
		setProductsListMultiple([])
	return () => {
		// GetMachinesWithDatesRange()
	}
	}, [MaqSelect])


	useEffect(() => {
		// console.log('ProductsListMultiple',ProductsListMultiple.length);
		if(ProductsListMultiple.length === 0){
			setData([])
		} else {
			GetDataToChart()
		}
	return () => {
		// GetMachinesWithDatesRange()
	}
	}, [ProdSelect, ProductsListMultiple])
	
	return (
		<>
			<HeaderMenu Pag_Sel={'ctrl_temp'} />
			
			<div className='container-fluid'>
				<div className='row'>
					{/* <div className='col-0 hidden'>
						<button className='btn btn-outline-info' onClick={()=>GetCabeceras(MaqSelect)}><RefreshIcon /></button>
					</div> */}
					<div className='col-12 col-md-4'>
						<span className='fotmar-inp-date-label'>Fecha Inicio</span>
						<input
							type='date'
							className='fotmar-inp-date'
							max={new Date().toISOString().split('T')[0]}
							value={FechaInicio}
							onChange={(e)=>setFechaInicio(e.target.value)}
						/>
					</div>
					<div className='col-12 col-md-4'>
						<span className='fotmar-inp-date-label'>Fecha Final</span>
						<input
							type='date'
							className='fotmar-inp-date'
							max={new Date().toISOString().split('T')[0]}
							value={FechaFinal}
							onChange={(e)=>setFechaFinal(e.target.value)}
						/>
					</div>
					<div className='col-12 col-md-4' style={{paddingTop: 20}}>
						<FormControl fullWidth>
							<InputLabel size='small' id="demo-simple-select-label">Seleccione Maquina</InputLabel>
							<Select
								size='small'
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Seleccione Maquina"
								value={MaqSelect}
								onChange={(e)=>setMaqSelect(e.target.value)}
							>
								{
									MachinesList.map(itm=><MenuItem key={itm.codigo} value={itm._id}>{itm._id}</MenuItem>)
								}
							</Select>
						</FormControl>
					</div>
					{/* <div className='col-12 col-md-4 mt-2'>
						<FormControl fullWidth>
							<InputLabel size='small' id="demo-simple-select-label">Seleccione Producto</InputLabel>
							<Select
								size='small'
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Seleccione Maquina"
								value={ProdSelect}
								onChange={(e)=>setProdSelect(e.target.value)}
							>
								{
									ProductsList.map(itm=><MenuItem key={itm._id} value={itm._id}>{itm._id}</MenuItem>)
								}
							</Select>
						</FormControl>
					</div> */}
					<div className='col-12 mt-1'>
						<span className='fotmar-inp-date-label-s'>Seleccione Producto(s)</span>
						<ReactSelect
							placeholder={'Seleccione Producto(s)'}
							value={ProductsListMultiple}
							closeMenuOnSelect={false}
							components={animatedComponents}
							// defaultValue={[colourOptions[4], colourOptions[5]]}
							isMulti
							options={OptiosOfMultiple}
							onChange={ChangedReactSelect}
						/>
					</div>
					<button className='btn btn-activo-temp' style={{}} onClick={()=>launchFullScreen('fulllpage_01')}><BsArrowsFullscreen /></button>
					<div className='col-12' style={{backgroundColor: '#fff'}} id='fulllpage_01'>
						<LineThree DATA={Data} maquina={MaqSelect} />
					</div>
				</div>
			</div>
		</>
	)
}
