import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import { OUR_BarCharTwo } from '../../components/charts/OUR_BarCharTwo.chart'
import { GetListMEdidores } from '../../API/OUR_Lecturas/GetListMEdidores.api';
import LoginContext from '../../context/login_context/LoginContext';

export const OUR_BarChar_SickChiken = () => {

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

    const [ListMedidores_, setListMedidores_] = useState([]);
    const [IsRender, setIsRender] = useState(false);

    const GetListMedidores = async () => {
        let list_ = await GetListMEdidores({empresa:LoginState.planta})
        let listFil = await list_.filter(fl => fl.TIPO === "POLLOENFERMO")
        // let listFil_ = await list_["ai"].filter(fl => fl.ELEMENTO === "HIDRICO")
        // let concatenar_ = await listFil.concat(listFil_)
        // console.log(listFil[0].MEDIDOR);
        await setListMedidores_(listFil[0].MEDIDOR);
        await setIsRender(true)
    }

    useEffect(() => {
        GetListMedidores()
        return () => {
            GetListMedidores()
        }
    }, [])

    if(!IsRender){
        return (
            <>
                <HeaderMenu Pag_Sel={"polloenfermo"} />
                <div>Cargando los datos..</div>
            </>
          )
    }else{
        return (
          <>
              <HeaderMenu Pag_Sel={"polloenfermo"} />
              {/* <div>OUR_BarChar_SickChiken</div> */}
              <OUR_BarCharTwo DATA={ListMedidores_} />
          </>
        )
    }
}
