import React, { useEffect, useLayoutEffect, useState } from 'react'

import solidGauge from 'highcharts/modules/solid-gauge'
import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const options = {
  chart: {
    type: 'spline',
    zoomType: 'x',
    height: '40%',
    resetZoomButton: {
      position: {
          // align: 'right', // by default
          // verticalAlign: 'top', // by default
          x: 0,
          y: 0,
          text: "zooom"
      }
    }
  },
  title: {
      text: null,
      style: {
        // fontSize:'small',
        fontWeight: 'bold',
        fontFamily: 'monospace',
        color: 'black'
      }
  },
  // subtitle: {
  //     text: "subtitulo",
  //     style: {
  //       // fontSize:'small',
  //       fontWeight: 'lighter',
  //       fontFamily: 'monospace',
  //       color: 'black'
  //     }
  // },
  xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { // don't display the year
          month: '%e. %b',
          year: '%b'
      },
      title: {
          text: ''
      }
  },
  legend: {
    align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 0
  },
  yAxis: {
      title: {
          text: `Temperatura °C`
      },
      // min: 0 /** comentar esto si se requiere que el grafico muestre los valores negativos */
  },
  tooltip: {
      formatter: function() {
        // let d = new Date()
        let d_ = new Date(this.x)
        // console.log(this.x)
        return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Temperatura: <b>' + this.y + " " + "°C" + '</b>';
      },
      // headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: '{point.x}: {point.y:.2f} m³',
      // crosshairs: true,
      // shared: true
  },
  plotOptions: {
    series: {
      marker: {
        enabled: true,
        radius: 2.5
      }
    }
  },

  series: 
  [
    {
    name: 'Flujometro_1',
    data:
        [
          // [1692806400000, -0.19],
          [1692810000000, -0.19],
          [1692813600000, 2.99],
          [1692817200000, 2.99],
          [1692820800000, -0.19],
          // [1692824400000, -0.19]
        ]
    },{
      name: 'Flujometro_2',
      data:
          [
            [1692806400000, 0.49],
            [1692810000000, 0.49],
            [1692813600000, -1.09],
            [1692817200000, -1.09],
            [1692820800000, 0.49],
            [1692824400000, 0.49]
          ]
      }
  ]
}

export const LineThree = ({DATA,maquina}) => {
  // console.log(DATA)
  // let title = `GRAFICO ${DATA.ELEMENTO} ${DATA.CHART}`
  // let subTitle = DATA.ELEMENTO==="ELECTRICO"?"KW/HORA":"M3/HORA"
  // let unidad_med = DATA.ELEMENTO==="ELECTRICO"?"KW":"M3"
  const [state, setstate] = useState(options)
  const [IsREnder, setIsREnder] = useState(false)
  // // console.log(subTitle)

  // let UpdateChartNow = async () => {
  //   // console.log(state.series[0].data)
  //   let datas = await UpdateDataColumnCalculated({CHART:DATA.CHART, ELEMENTO:DATA.ELEMENTO, N_MED:DATA.MEDIDOR})
  //   // console.log(datas)
  //   let g = []

  //   for (let dataK = 0; dataK < datas.length; dataK++) {
  //     // console.log("entro en for")
  //     const dataK_ = datas[dataK];
  //     let a_d = dataK_.data.map(aa_a => {
  //       // console.log(aa_a[0])
  //       let p1 = new Date(aa_a[0]).toISOString().split("T")
  //       let p2 = p1[0].split("-")
  //       let p3 = p1[1].split(":")[0]
  //       // console.log(Date.UTC(Number(p2[0]),Number(p2[1])+1,Number(p2[2]),Number(p3),0), Number(m2))
  //       return [
  //         Date.UTC(Number(p2[0]),//yyyy
  //         Number(p2[1])-1,//mm
  //         Number(p3)-REST_HOUSR<0?Number(p2[2])-1:Number(p2[2]),//dd
  //         Number(p3)-REST_HOUSR<0?(24-(REST_HOUSR-Number(p3))):Number(p3)-REST_HOUSR,//hh
  //         0//min
  //         ),
  //         aa_a[1]]
  //     })
  //     // console.log(a_d)
  //     g.push({
  //       name:dataK_.name,
  //       data: a_d
  //     })
  //   }

  //   // LOGICA PARA GENERAR DATOS EN CASO DE SER MEDIDOR DE NESPRA
  //   // if(DATA.CONTRATO === "NESPRA"){
  //   //   let aa = await datas.map(itm =>{
  //   //     let {m2,yymmddhh} = itm
  //   //     let p_ = String(yymmddhh).match(/.{1,2}/g)
  //   //     let q = [
  //   //       Number("20"+p_[0]), 
  //   //       Number(p_[1])-1,
  //   //       // Number(p_[2]),
  //   //       Number(p_[3])-4<0?Number(p_[2])-1:Number(p_[2]),
  //   //       Number(p_[3])-4<0?(24-(4-Number(p_[3]))):Number(p_[3])-4,
  //   //       0
  //   //     ]
  //   //     // console.log(q[0],q[1],q[2],q[3],q[4],m2)
  //   //     return [Date.UTC(q[0],q[1],q[2],q[3],q[4]),m2]
  //   //   })
  //   //   let arr_consumo = []
  //   //   for (let a_a = 1; a_a < aa.length; a_a++) {
  //   //     const a_a_ = aa[a_a];
  //   //     const a_a_a = aa[a_a-1];
  //   //     let Resultado_a = Number(a_a_[1]-a_a_a[1]).toFixed(2)
  //   //     // console.log(Resultado_a)
  //   //     arr_consumo.push([a_a_[0],Number(Resultado_a)])
  //   //     // console.log(a_a_a,a_a_)
  //   //   }
  //   //   // console.log(arr_consumo)
  //   //   // console.log(aa)
      
  //   //   g = [{
  //   //       name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
  //   //       data: arr_consumo
  //   //     }]

  //   // }
  //   // if(DATA.CONTRATO === "E-SMART"){
  //     // let a_d = datas.map(aa_a => {
  //     //   let {timestamp, m2} = aa_a
  //     //   let p1 = new Date(timestamp).toISOString().split("T")
  //     //   let p2 = p1[0].split("-")
  //     //   let p3 = p1[1].split(":")[0]
  //     //   // console.log(Date.UTC(Number(p2[0]),Number(p2[1])+1,Number(p2[2]),Number(p3),0), Number(m2))
  //     //   return [
  //     //     Date.UTC(Number(p2[0]),//yyyy
  //     //     Number(p2[1])-1,//mm
  //     //     Number(p3)-4<0?Number(p2[2])-1:Number(p2[2]),//dd
  //     //     Number(p3)-4<0?(24-(4-Number(p3))):Number(p3)-4,//hh
  //     //     0//min
  //     //     ),
  //     //     Number(m2)]
  //     //   // return [timestamp,Number(m2)]
  //     // })
  //     // console.log(a_d)
  //     // for (let gup = 0; gup < datas.length; gup++) {
  //     //   const gup_ = datas[gup];
  //     //   g.push({
  //     //     name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
  //     //     data: a_d
  //     //   })
  //     // }
  //     // g = [{
  //     //     name: title+" "+(DATA.ELEMENTO==="ELECTRICO"?"⚡":"💧"),
  //     //     data: a_d
  //     //   }]
  //   // }//*fin if

    useEffect(() => {
      setstate({...state,["series"]:DATA})
      return () => {
        
      }
    }, [DATA])
    
    // setstate({...state,["series"]:DATA})
    // setstate({
    //   chart: {
    //     type: 'spline',
    //     zoomType: 'x',
    //     height: '400px',
    //     resetZoomButton: {
    //       position: {
    //           // align: 'right', // by default
    //           // verticalAlign: 'top', // by default
    //           x: 0,
    //           y: -30,
    //           text: "zooom"
    //       }
    //     }
    //   },
    //   title: {
    //       text: title,
    //       style: {
    //         // fontSize:'small',
    //         fontWeight: 'bold',
    //         fontFamily: 'monospace',
    //         color: 'black'
    //       }
    //   },
    //   subtitle: {
    //       text: subTitle,
    //       style: {
    //         // fontSize:'small',
    //         fontWeight: 'lighter',
    //         fontFamily: 'monospace',
    //         color: 'black'
    //       }
    //   },
    //   xAxis: {
    //       type: 'datetime',
    //       dateTimeLabelFormats: { // don't display the year
    //           month: '%e. %b',
    //           year: '%b'
    //       },
    //       title: {
    //           text: ''
    //       }
    //   },
    //   legend: {
    //     align: 'left',
    //       verticalAlign: 'top',
    //       borderWidth: 0
    //   },
    //   yAxis: {
    //       title: {
    //           text: `Consumos ${subTitle}`
    //       },
    //       min: 0
    //   },
    //   tooltip: {
    //       formatter: function() {
    //         // let d = new Date()
    //         let d_ = new Date(this.x)
    //         // console.log(this.x)
    //         return this.series.name + '<br/>Fecha: <b>' + d_.toISOString().split('T')[0] + '<span> </span>' + d_.toISOString().split('T')[1].substring(0,5) + '</b><br/>Consumo: <b>' + this.y + " " + unidad_med + '</b>';
    //       },
    //       // headerFormat: '<b>{series.name}</b><br>',
    //       // pointFormat: '{point.x}: {point.y:.2f} m³',
    //       // crosshairs: true,
    //       // shared: true
    //   },
    //   plotOptions: {
    //     series: {
    //       marker: {
    //         enabled: true,
    //         radius: 2.5
    //       }
    //     }
    //   },
    
    //   series: g??[
    //     {
    //     name: 'Flujometro_1',
    //     data:
    //         [
    //           [1692806400000, 0.39],
    //           [1692810000000, 0.39],
    //           [1692813600000, 0.99],
    //           [1692817200000, 0.99],
    //           [1692820800000, 0.39],
    //           [1692824400000, 0.39]
    //         ]
    //     },{
    //       name: 'Flujometro_2',
    //       data:
    //           [
    //             [1692806400000, 0.39],
    //             [1692810000000, 0.39],
    //             [1692813600000, 0.99],
    //             [1692817200000, 0.99],
    //             [1692820800000, 0.39],
    //             [1692824400000, 0.39]
    //           ]
    //       }
    //   ]
    // })
    // setIsREnder(true)
  // }

  // useEffect(() => {
  //   UpdateChartNow()
  //   setInterval(() => {
  //     UpdateChartNow()
  //   }, 1000*60*30);
  
  //   return () => {
  //    UpdateChartNow() 
  //   }
  // }, [])
  

  // if(IsREnder){
    return (
        <>
            <div className='container-fluid mt-5'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <span className='h3'>{maquina}</span>
                    </div>
                    <div className='col-12 p-0 m-0'>
                        <HighchartsReact
                            
                            key={'01111114'}
                            highcharts={Highcharts}
                            options={state||[]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
  // }else{
  //   return (
  //     <div className='container' style={{backgroundColor: '#FBCACA', borderRadius: '9px'}} key={new Date()}>
  //       <div className='row' style={{position: 'relative'}}>
  //         <div className='col text-center' style={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  //           <h5 className='loadding'>Cargando ..</h5>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
}
