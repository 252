import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'
import { GetUbicacionesRutas } from '../../API/control_acceso/GetUbicaionesRutas.api';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import LoginContext from '../../context/login_context/LoginContext';

export const AddUbications = () => {

    const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

    const [Areas, setAreas] = useState([]);
    const [AreasSelected, setAreasSelected] = useState([]);
    const [Rutas, setRutas] = useState([]);
    const [RutasListSelect, setRutasListSelect] = useState([]);
    const [RutasSelected, setRutasSelected] = useState('');

    useEffect(() => {
        const GetRutasYUnicacaiones = async () => {
          let DatosWev = await GetUbicacionesRutas({empresa:LoginState.planta})
          // console.log(DatosWev);
        //   setUbicaciones(DatosWev.ubicaciones)
          setRutas(DatosWev.rutas)
          setAreas(DatosWev.areas)
        //   setFechas(DatosWev.fechas)
        }
        GetRutasYUnicacaiones()
        return () => {
          GetRutasYUnicacaiones()
        }
      }, [])
  
      // filtra al seleccionar un area cargando las rutas que corresponden segun elarea seleccionada
      useEffect(() => {
          const FiltrarRutas = async () => {
              let filterRutas = await Rutas.filter(fltr => fltr.area === AreasSelected)
              setRutasListSelect(filterRutas)
            //   setUbicacionesList([])
            //   setFechasSelected('')
          }
          FiltrarRutas()
          return () => {
              
          }
      }, [AreasSelected])

  return (
    <>
        <HeaderMenu Pag_Sel={'addubi'} />  
        <div>AddUbications</div>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-md-4 my-2'>
                        <FormControl fullWidth>
							<InputLabel size='small' id="demo-simple-select-label">Seleccione Area</InputLabel>
							<Select
								size='small'
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label="Seleccione Area"
								value={AreasSelected}
								onChange={(e)=>setAreasSelected(e.target.value)}
							>
								{
									Areas.map(itm=><MenuItem key={itm._id} value={itm._id}>{itm._id}</MenuItem>)
								}
							</Select>
						</FormControl>
                </div>
                <div className='col-12 col-md-4 my-2'>
                    <FormControl fullWidth>
                        <InputLabel size='small' id="demo-simple-select-label">Seleccione Ruta</InputLabel>
                        <Select
                            size='small'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Seleccione Ruta"
                            value={RutasSelected}
                            onChange={(e)=>setRutasSelected(e.target.value)}
                        >
                            {
                                RutasListSelect.map(itm=><MenuItem key={itm._id} value={itm._id}>{itm._id}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>
                
            </div>
        </div>
    </>
  )
}
