import { URL_SRV_ARIZTIA } from '../router/Url';

export const LecturaNespraOurRangeTotalizador = ({medidor, sensor, planta, ahora, pasado}) => {//addsuscripcion
    // console.log({medidor, sensor, planta, ahora, pasado})

    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'getlecturanespraour_range',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            medidor, sensor, planta, ahora, pasado
          })
        })
        let responsito = await query.json();
        // console.log(responsito.body.Dataitem)
        let clearData = await (responsito.body.Dataitem).map(ulm =>{
          let {totalizador, timestamp} = ulm
          return {valor: totalizador, timestamp: timestamp}
        })
        if(responsito.success){
          resolve(clearData)
        }else{
          resolve([])
        }
      })
}
