import React, { useEffect, useState } from 'react'
//ICONS
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';
import { GetLecturaActual } from '../../API/OUR_Lecturas/GetLecturaActual.api';

export const OUR_IndicadorAmoniaco = ({DATA}) => {
    // console.log(DATA)
    const [DataAmon, setDataAmon] = useState({valor:0,timestamp:0})

    const GetDataToNH3 = async () => {
        let lact = await GetLecturaActual({DATA})
        setDataAmon(lact[0])
    }
    // console.log(DataAmon)
    useEffect(() => {
        GetDataToNH3()
      return () => {
        GetDataToNH3()
      }
    }, [])
    
    return (
        <div className='bb-card-alert-amon'>
            <small>{DATA.FUENTE}</small><br/>
            {/* <small>{DATA.MAX}{DATA.UNIDAD}</small><br/> */}
            <small>Lectura Actual: {DataAmon?.valor}</small><br/>
            {
                DataAmon?.valor>14?<span className='alert-amon-cir-active'><WarningIcon color='error' /></span>
                : <span className='alert-amon-cir-disable'><WarningAmberIcon color='success' /></span>
            }
           
            
        </div>
    )
}
