import React, { useEffect } from 'react'
import { HeaderMenu } from '../../components/HeaderMenu.comp'

// MEDIDORES ZONA SUCIA Y ZONA LIMPIA
const ZonaSucia =
    [
        "BANCOS DE AGUA",
        "ESTANQUE 25.000",
        "PANTALON NORTE"
    ]
const ZonaLimpia = 
    [
        "ANILLO PRESION",
        "APV",
        "BANDEJAS",
        "PANTALON SUR"
    ]

export const SSGGMonitorPersonal = () => {

    
  return (
    <>
        <HeaderMenu Pag_Sel={'ssggpersonal'} />
        <div>SSGGMonitorPersonal</div>
    </>
  )
}
