import React from 'react'
import { URL_CTRL_HIDRIC } from '../router/Url';

export const GetElementosList = ({empresa}) => {
    // console.log(empresa)
  return new Promise(async (resolve, reject) => {
      let query = await fetch(URL_CTRL_HIDRIC+'getelementos',{
        method: 'POST',
        headers: {
          'authorization': "paico2021",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          empresa: empresa
        })
      })
      let responsito = await query.json();
      // console.log(responsito.ELEMENTOS)
      if(responsito.ELEMENTOS.length){
        resolve(responsito.ELEMENTOS)
      }else{
        resolve([])
      }
    })
}
