import { URL_SRV_ARIZTIA } from '../router/Url';

export const LecturaNespraOur = ({planta, medidor, sensor}) => {//addsuscripcion
    // console.log(planta, medidor, sensor)

    return new Promise(async (resolve, reject) => {
        let query = await fetch(URL_SRV_ARIZTIA+'getlecturanespraour',{
          method: 'POST',
          headers: {
            'authorization': "paico2021",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            planta, medidor, sensor
          })
        })
        let responsito = await query.json();
        // console.log("response de nespra",responsito)
        if(responsito.success){
          resolve([{"TimeStamp":responsito.body.Dataitem.timestamp, "m1":responsito.body.Dataitem.caudal}])
        }else{
          resolve([])
        }
      })
}
