import { URL_SRV_ARIZTIA } from "../../router/Url";

export const GetDataToChartOfTemp = ({FechaInicio, FechaFinal, empresa, MaqSelect, ProdSelect, ProductsListMultiple}) => {
  // console.log(ProductsListMultiple);

  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_ARIZTIA+'getdatatochartoftemp',{
      method: 'POST',
      headers: {
        'authorization': "paico2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "INICIO": FechaInicio,
        "FIN": FechaFinal,
        "EMPRESA": empresa,
        "MAQUINA": MaqSelect,
        "PRODUCTO": ProdSelect,
        "PRODUCTOS": ProductsListMultiple
      })
    })
    let responsito = await query.json();
    // console.log(responsito.body)
    if(responsito.success){
      resolve(responsito.body)
    }else{
      resolve([])
    }
  })
}
